import React from 'react';

export type OSLayout = {
    navCollapsed: boolean;
    setNavCollapsed: (_: boolean) => void;
};

const OSLayoutContext = React.createContext<OSLayout>({
    navCollapsed: true,
    setNavCollapsed: (_: boolean) => {},
});

type TAppConfigProvider = {
    children?: React.ReactNode;
};

export const OSLayoutProvider = ({ children }: TAppConfigProvider) => {
    const [navCollapsed, setNavCollapsed] = React.useState(true);

    return (
        <OSLayoutContext.Provider
            value={{
                navCollapsed,
                setNavCollapsed,
            }}
        >
            {children}
        </OSLayoutContext.Provider>
    );
};

export function useOSLayout() {
    const context = React.useContext(OSLayoutContext);
    return context;
}
