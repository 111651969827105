import colorFns from 'colorFns';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import CloseIcon from '../../images/icons/close.svg?react';
import PlusIcon from '../../images/icons/plus.svg?react';
import { useEvent } from '../../providers/event';
import { collaboratorIsPendingAdd, useEventCollaborators } from '../../providers/event-collaborators';
import { PopoverContainer, Row, StyledPopover } from '../../ui';
import Avatar from '../ui/Avatar';
import SmallCircleButton from '../ui/SmallCircleButton';
import CollaboratorsFlyout from './CollaboratorsFlyout';
import PermissionPill from './PermissionPill';
import RemoveCollaboratorModal from './RemoveCollaboratorModal';

const StyledAvatar = styled(Avatar)<{ pending?: boolean; nostacking?: boolean; rightPadding?: boolean }>`
    margin-right: ${({ rightPadding }) => `${rightPadding ? 12 : -12}px`};
    border: 2px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    ${({ pending }) => pending && 'filter: grayscale(100%);'}
    ${({ nostacking }) => nostacking && 'filter: grayscale(0);'}
`;

const MemberContainer = styled(Row)`
    padding: 10px;
    pointer-events: all;
    align-items: center;
    gap: 10px;
`;

const CollaboratorInfo = styled.div`
    user-select: none;
`;

const CloseButton = styled.button`
    padding: 0;
    color: inherit;
    border: inherit;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 2px;
`;

const StyledCloseIcon = styled(CloseIcon)`
    width: 20px;
    height: 20px;
    color: ${colorFns.primaryAction};
    cursor: pointer;

    stroke: ${colorFns.primaryAction};
    stroke-width: 2px;
`;

const PlusButton = styled(SmallCircleButton)`
    margin-right: 12px;
`;

type AvatarProps = {
    collaborator: Pick<
        BizlyAPI.EventCollaboratorOrPending,
        'firstName' | 'lastName' | 'email' | 'imageUrl' | 'id' | 'editor'
    >;
};

const CollaboratorAvatarWithPopover = ({ collaborator }: AvatarProps) => {
    const [hoverAnchorEl, setHoverAnchorEl] = React.useState<Nullable<Element>>(null);
    const [removeCollaboratorsModalOpen, setRemoveCollaboratorsModalOpen] = React.useState(false);

    return (
        <>
            <div
                onMouseEnter={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    setHoverAnchorEl(event.currentTarget);
                }}
                onMouseLeave={() => setHoverAnchorEl(null)}
            >
                <StyledAvatar
                    pending={collaboratorIsPendingAdd(collaborator)}
                    user={{ ...collaborator, imageUrl: collaborator.imageUrl ?? '' }}
                />

                <StyledPopover
                    open={!!hoverAnchorEl}
                    anchorEl={hoverAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MemberContainer>
                        <CollaboratorInfo>
                            {collaborator.firstName
                                ? collaborator.firstName + ' ' + collaborator.lastName
                                : collaborator.email}
                        </CollaboratorInfo>
                        <PermissionPill value={collaborator.editor ? 'edit' : 'view'} />
                        {!collaboratorIsPendingAdd(collaborator) && (
                            <CloseButton onClick={() => setRemoveCollaboratorsModalOpen(true)}>
                                <StyledCloseIcon />
                            </CloseButton>
                        )}
                    </MemberContainer>
                </StyledPopover>
            </div>
            <RemoveCollaboratorModal
                open={removeCollaboratorsModalOpen}
                closeModal={() => setRemoveCollaboratorsModalOpen(false)}
                collaborator={collaborator}
            />
        </>
    );
};

const CollaboratorsHeaderSection = () => {
    const [collaboratorsHoverAnchor, setCollaboratorsHoverAnchor] = React.useState<Nullable<Element>>(null);
    const [collaboratorsFlyoutAnchor, setCollaboratorsFlyoutAnchor] = React.useState<Nullable<Element>>(null);
    const [plannerHoverAnchor, setPlannerHoverAnchor] = React.useState<Nullable<Element>>(null);

    const { event } = useEvent();
    const planner = event.plannedBy;

    const { collaborators } = useEventCollaborators();

    return (
        <>
            {event.editable && (
                <PlusButton
                    onClick={event => {
                        setCollaboratorsFlyoutAnchor(event.currentTarget);
                        setCollaboratorsHoverAnchor(null);
                    }}
                    onMouseEnter={event => setCollaboratorsHoverAnchor(event.currentTarget)}
                    onMouseLeave={() => setCollaboratorsHoverAnchor(null)}
                >
                    <PlusIcon />
                </PlusButton>
            )}
            <StyledPopover
                open={!!collaboratorsHoverAnchor}
                anchorEl={collaboratorsHoverAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <PopoverContainer>
                    {i18n.meetingDashboard.headerSection.collaborators.addCollaborators}
                </PopoverContainer>
            </StyledPopover>

            <CollaboratorsFlyout
                anchor={collaboratorsFlyoutAnchor}
                onClose={() => setCollaboratorsFlyoutAnchor(null)}
            />

            {collaborators.map(collaborator => (
                <CollaboratorAvatarWithPopover key={collaborator.email} collaborator={collaborator} />
            ))}

            <StyledAvatar
                nostacking
                rightPadding
                user={planner}
                onMouseEnter={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
                    setPlannerHoverAnchor(event.currentTarget)
                }
                onMouseLeave={() => setPlannerHoverAnchor(null)}
            />

            <StyledPopover
                open={!!plannerHoverAnchor}
                anchorEl={plannerHoverAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <PopoverContainer>
                    {planner.firstName + ' ' + planner.lastName} ({i18n.approvals.meetingOwner})
                </PopoverContainer>
            </StyledPopover>
        </>
    );
};

export default CollaboratorsHeaderSection;
