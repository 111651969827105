export const REGISTRATION_PAGE: Bizly.ParcelType = 'registration_page';
export const INVITE: Bizly.ParcelType = 'invite';
export const NOTE: Bizly.ParcelType = 'note';
export const SURVEY: Bizly.ParcelType = 'survey';
export const HOTEL_CONF: Bizly.ParcelType = 'hotel_conf';

export const copyRegistrationSlugToClipboard = async ({ parcel, eventId }: any) => {
    const slug = !Array.isArray(parcel?.traits) && parcel?.traits?.public?.registrationPage?.slug;
    const url = new URL(window.location.origin);
    url.pathname = `/events/${eventId}/register/${slug}`;

    await navigator.clipboard.writeText(url.href);
};
