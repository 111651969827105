import Popover from '@material-ui/core/Popover';
import {
    getBookingsForEvent,
    setBookingContractUrl,
    setBookingEstimates,
    setBookingFinalSpend,
    setBookingInvoiceUrl,
} from 'api';
import { useBudgetRequired } from 'components/EventCreateModal/utils';
import BudgetForm from 'components/FinalSpendTracking/BudgetForm';
import EstimateForm, { TEstimateForm } from 'components/FinalSpendTracking/EstimateForm';
import FinalSpendForm, { TFinalSpendForm } from 'components/FinalSpendTracking/FinalSpendForm';
import { Tab, Tabs } from 'components/Tabs';
import { H2Headline } from 'components/ui/Headline';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { hasAcceptedProposal, hasBookedVenue, LoadCurrentInquiry, useCurrentInquiry } from 'stores/current-inquiry';
import styled from 'styled-components';
import { i18n } from 'translation';
import { uploadFile } from '../../cloudinary';
import { EventContext } from '../../providers/event';
import { useUser } from '../../providers/user';
import { Row } from '../../ui';
import TextButton from '../ui/Button/TextButton';

const BUDGET = 'budget';
const ESTIMATE = 'estimate';
const FINAL_SPEND = 'final spend';

const Container = styled.div`
    width: 650px;
    padding: 32px;
    flex: 1;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    height: 100%;
    box-sizing: border-box;
    position: relative;
`;

const TABS_CONTAINER_STYLES = {
    marginBottom: 32,
    width: '100%',
};

const TAB_STYLES = {
    padding: 0,
    marginRight: 8,
    marginLeft: 8,
};

const Content = styled.div`
    max-height: 479px;
    min-height: 20px;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
`;

const RowRight = styled(Row)`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
`;

type FlyoutProps = {
    finalSpendAnchor: Element;
    setFinalSpendAnchor: (anchor: any) => void;
    showFinalSpend?: boolean;
};

const Flyout = ({ finalSpendAnchor, setFinalSpendAnchor, showFinalSpend }: FlyoutProps) => {
    const { event, onEventChange } = useContext(EventContext);
    const currentInquiry = useCurrentInquiry();
    const { user } = useUser();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(true);
    const [booking, setBooking] = useState<Bizly.Booking>();

    const [estimateForm, setEstimateForm] = useState<TEstimateForm>();
    const [finalSpendForm, setFinalSpendForm] = useState<TFinalSpendForm>();

    const [budget, setBudget] = useState<string>();

    const teamCompliance = user.team?.compliance || {};
    const [activeTab, setActiveTab] = useState<string>(BUDGET);

    const _hasBookedVenue = !!currentInquiry.venues && hasBookedVenue(currentInquiry.venues);

    const budgetRequired = useBudgetRequired();

    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (showFinalSpend) {
            setActiveTab(FINAL_SPEND);
        }
    }, [showFinalSpend]);

    useEffect(() => {
        getBookingsForEvent(event.id)
            .then(({ bookings = [] }: { bookings: Bizly.Booking[] }) => {
                // Prevent updating state after the popup is closed
                if (mounted.current) {
                    // TODO: This works while we support only single booking (orders) per event. Need to update logic to handle multiple possible bookings when that becomes supported
                    const firstBooking = bookings[0] || {};
                    setBooking(firstBooking);

                    setEstimateForm({
                        ...firstBooking.estimates,
                        existingDocs: {
                            contract1: firstBooking.contractUrl,
                            contract2: firstBooking.contract2Url,
                        },
                    });

                    if (firstBooking.finalSpend)
                        setFinalSpendForm({
                            ...firstBooking.finalSpend,
                            existingDocs: { invoice1: firstBooking.invoiceUrl, invoice2: firstBooking.invoice2Url },
                        });
                    setLoading(false);
                }
            })
            .catch(() => setLoading(false));
        setBudget(event.budget);
    }, [event]);

    const getExistingContracts = () => ({
        contract1: booking?.contractUrl,
        contract2: booking?.contract2Url,
    });

    const getExistingInvoices = () => ({
        invoice1: booking?.invoiceUrl,
        invoice2: booking?.invoice2Url,
    });

    const handleClose = () => setFinalSpendAnchor(null);

    const handleSubmitBudget = async (budget: string) => {
        if (!budget) return;
        setLoading(true);

        try {
            onEventChange({ ...event, budget });
            setBudget(budget);
            activeTab === BUDGET &&
                enqueueSnackbar(i18n.meetingDashboard.headerSection.spendTracking.budgetSaved, {
                    variant: 'info',
                });
            handleClose();
        } catch (err) {
            setLoading(false);
            enqueueSnackbar(i18n.error.submission, {
                variant: 'error',
            });
        }
    };

    const handleSubmitContracts = async (stagedDocs: { contract1?: File; contract2?: File }) => {
        if (!booking || !estimateForm) return;

        const { contractUrl: existingContract1, contract2Url: existingContract2 } = booking;
        const { contract1, contract2 } = stagedDocs;
        if (stagedDocs?.contract1?.name || stagedDocs?.contract2?.name || existingContract1 || existingContract2) {
            if (!estimateForm.total) {
                return enqueueSnackbar(i18n.venue.proposal.estimateTotalRequired, {
                    variant: 'error',
                });
            }
        }
        let stagedUpload1, stagedUpload2;
        try {
            setLoading(true);
            // TODO: Setting a contract and setting estimate are two separate endpoints; look into making a unified one for less calls
            if (contract1 && contract1.name) {
                const { url: firstUrl } = await uploadFile(contract1);
                stagedUpload1 = firstUrl;
            }

            if (contract2 && contract2.name) {
                const { url: secondUrl } = await uploadFile(contract2);
                stagedUpload2 = secondUrl;
            }

            if (stagedUpload1 || stagedUpload2) {
                const firstContract = stagedUpload1 || existingContract1;
                const secondContract = stagedUpload2 || null;
                const contractUrls = {
                    contractUrl: firstContract || secondContract,
                    contract2Url: firstContract ? secondContract : null,
                };

                const { booking: bookedContract } = await setBookingContractUrl(booking.id, contractUrls);

                setBooking(bookedContract);
            }

            const { booking: updatedBooking } = await setBookingEstimates(booking.id, estimateForm);

            setBooking({ ...booking, ...updatedBooking });

            activeTab === ESTIMATE &&
                enqueueSnackbar(i18n.meetingDashboard.headerSection.spendTracking.estimatesSaved, {
                    variant: 'info',
                });
            setLoading(false);
            handleClose();
        } catch (err) {
            setLoading(false);
            enqueueSnackbar(i18n.error.submission, {
                variant: 'error',
            });
        }
    };

    const handleSubmitInvoices = async (stagedDocs: { invoice1?: File; invoice2?: File }) => {
        if (!booking || !finalSpendForm) return;

        const { invoice1, invoice2 } = stagedDocs;
        const { id: bookingId, invoiceUrl: existingInvoice1, invoice2Url: existingInvoice2 } = booking;
        if (stagedDocs?.invoice1?.name || stagedDocs?.invoice2?.name || existingInvoice1 || existingInvoice2) {
            if (!finalSpendForm.total) {
                return enqueueSnackbar(i18n.venue.proposal.finalSpendRequired, {
                    variant: 'error',
                });
            }
        }

        let stagedUpload1, stagedUpload2;
        try {
            // TODO: Setting an invoice and setting final spend are two separate endpoints; look into making a unified one for less calls
            if (invoice1 && invoice1.name) {
                const { url: firstUrl } = await uploadFile(invoice1);
                stagedUpload1 = firstUrl;
            }

            if (invoice2 && invoice2.name) {
                const { url: secondUrl } = await uploadFile(invoice2);
                stagedUpload2 = secondUrl;
            }

            if (stagedUpload1 || stagedUpload2) {
                const firstInvoice = stagedUpload1 || existingInvoice1;
                const secondInvoice = stagedUpload2 || null;
                const invoiceUrls = {
                    invoiceUrl: firstInvoice || secondInvoice,
                    invoice2Url: firstInvoice ? secondInvoice : null,
                };

                const { booking: updatedBookingInvoice } = await setBookingInvoiceUrl(bookingId, invoiceUrls);
                setBooking(updatedBookingInvoice);
            }

            const { booking: updatedBookingTotals } = await setBookingFinalSpend(bookingId, finalSpendForm);
            setBooking(updatedBookingTotals);
            activeTab === FINAL_SPEND &&
                enqueueSnackbar(i18n.meetingDashboard.headerSection.spendTracking.invoicesSaved, {
                    variant: 'info',
                });
            // check for a budget value so that the debounce on updateEvent doesn't cause it to be overwritten
            onEventChange({ ...event, finalSpendTracked: true, ...(budget ? { budget } : {}) });
            handleClose();
        } catch (err) {
            enqueueSnackbar(i18n.error.submission, {
                variant: 'error',
            });
        }
    };

    return (
        <Popover
            anchorEl={finalSpendAnchor}
            open={!!finalSpendAnchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={() => setFinalSpendAnchor(null)}
        >
            <LoadCurrentInquiry />
            <Container>
                <H2Headline>{i18n.meetingDashboard.headerSection.spendTracking.spendTracking}</H2Headline>

                {!loading && (
                    <Tabs value={activeTab} style={TABS_CONTAINER_STYLES}>
                        <Tab
                            label={i18n.meetingDashboard.headerSection.spendTracking.budget}
                            value={BUDGET}
                            onClick={() => setActiveTab(BUDGET)}
                            style={{ ...TAB_STYLES, marginLeft: 0 }}
                        />
                        <Tab
                            label={i18n.meetingDashboard.headerSection.spendTracking.estimate}
                            value={ESTIMATE}
                            onClick={() => setActiveTab(ESTIMATE)}
                            style={TAB_STYLES}
                        />
                        <Tab
                            label={i18n.meetingDashboard.headerSection.spendTracking.finalSpend}
                            value={FINAL_SPEND}
                            onClick={() => setActiveTab(FINAL_SPEND)}
                            style={TAB_STYLES}
                        />
                    </Tabs>
                )}

                {activeTab === BUDGET && !loading && (
                    <>
                        <Content>
                            <BudgetForm
                                value={budget || ''}
                                onChange={setBudget}
                                hideHeader
                                disabled={!event.editable || budgetRequired}
                            />
                        </Content>
                        <RowRight itemSpacing="smallish">
                            <TextButton onClick={handleClose} secondary>
                                {i18n.button.cancel}
                            </TextButton>
                            {event.editable && (
                                <TextButton onClick={() => handleSubmitBudget(budget || '')} disabled={!budget}>
                                    {i18n.button.save}
                                </TextButton>
                            )}
                        </RowRight>
                    </>
                )}
                {activeTab === ESTIMATE && !loading && (
                    <>
                        <Content>
                            <EstimateForm
                                canUpdate={
                                    (currentInquiry.venues && hasAcceptedProposal(currentInquiry.venues)) || false
                                }
                                onFormUpdate={setEstimateForm}
                                formValues={{
                                    ...estimateForm,
                                    currency: event.currency?.code || 'USD',
                                    existingDocs: getExistingContracts(),
                                }}
                                teamCompliance={teamCompliance}
                                disabled={!event.editable}
                                hideHeader
                            />
                        </Content>
                        <RowRight itemSpacing="smallish">
                            <TextButton onClick={handleClose} secondary>
                                {i18n.button.cancel}
                            </TextButton>
                            {event.editable && currentInquiry.venues && hasAcceptedProposal(currentInquiry.venues) && (
                                <TextButton
                                    onClick={() => handleSubmitContracts(estimateForm?.stagedDocs || {})}
                                    disabled={
                                        !estimateForm?.existingDocs?.contract1 &&
                                        !(
                                            estimateForm?.stagedDocs?.contract1?.name ||
                                            estimateForm?.stagedDocs?.contract2?.name
                                        )
                                    }
                                >
                                    {i18n.button.save}
                                </TextButton>
                            )}
                        </RowRight>
                    </>
                )}
                {activeTab === FINAL_SPEND && !loading && booking && (
                    <>
                        <Content>
                            <FinalSpendForm
                                booking={booking}
                                formValues={{
                                    ...finalSpendForm,
                                    currency: event.currency?.code || 'USD',
                                    existingDocs: getExistingInvoices(),
                                }}
                                onFormUpdate={setFinalSpendForm}
                                teamCompliance={teamCompliance}
                                hideHeader
                                disabled={!event.editable}
                                hasBookedVenue={_hasBookedVenue}
                            />
                        </Content>
                        <RowRight itemSpacing="smallish">
                            <TextButton onClick={handleClose} secondary>
                                {i18n.button.cancel}
                            </TextButton>

                            {event.editable && _hasBookedVenue && (
                                <TextButton onClick={() => handleSubmitInvoices(finalSpendForm?.stagedDocs || {})}>
                                    {i18n.button.save}
                                </TextButton>
                            )}
                        </RowRight>
                    </>
                )}
            </Container>
        </Popover>
    );
};

export default Flyout;
