import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TimeZonePicker from 'components/Form/TimeZonePicker';
import HowToPopper from 'components/HowToPopper/HowToPopper';
import { Headline } from 'components/ui/Headline';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { AlignedRow, Copy, Row } from 'ui';
import { tzMoment } from 'utils/moment';
import DashboardCard from './DashboardCard';

const Container = styled(DashboardCard)`
    position: relative;
`;

const InlineCopy = styled(Copy)`
    margin-right: 8px;
    display: inline-block;
`;

const ItemLabel = styled(Copy)`
    font-size: 13px;
    font-weight: 500;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
`;
const ItemContent = styled(AlignedRow)`
    font-size: 15px;
    font-weight: normal;
    margin-top: 8px;
`;

type TDetailItem = {
    label: string;
    value: ReactNode;
};

const DetailItem = ({ label, value }: TDetailItem) => (
    <div>
        <ItemLabel>{label}</ItemLabel>
        <ItemContent>{value}</ItemContent>
    </div>
);

type TEventDetailsProps = {
    cventName: string;
};

const EMPTY_VALUE = 'N/A';

export default function EventDetails({ cventName }: TEventDetailsProps) {
    const { user } = useUser();
    const { event, template } = useEvent();

    return (
        <Container itemSpacing="default" frameless>
            <Box display={'flex'} alignItems={'center'}>
                <Headline thin>{i18n.meetingsPage.meetingDetails}</Headline>
                <HowToPopper sectionId="eventDetails" />
            </Box>
            <Row>
                <DetailItem label={i18n.meetingsPage.meetingName} value={event.name || EMPTY_VALUE} />
            </Row>

            <Row>
                <DetailItem label={i18n.meetingsPage.cventEvent} value={cventName} />
            </Row>

            <Row>
                <DetailItem
                    label={i18n.meetingsPage.eventDescription}
                    value={<div dangerouslySetInnerHTML={{ __html: event.description || EMPTY_VALUE }} />}
                />
            </Row>

            <Row>
                <DetailItem label={i18n.homepage.createMeetingModal.location} value={event.location ?? EMPTY_VALUE} />
            </Row>

            <Row>
                <Grid container>
                    <Grid item xs={6}>
                        <DetailItem
                            label={i18n.meetingsPage.startDate}
                            value={event.startsAt ? tzMoment(event.startsAt).format('ll') : EMPTY_VALUE}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DetailItem
                            label={i18n.meetingsPage.startTime}
                            value={
                                event.startsAt ? (
                                    <>
                                        <InlineCopy>{tzMoment(event.startsAt).format('LT')}</InlineCopy>
                                        <TimeZonePicker
                                            date={event.startsAt || new Date().toISOString()}
                                            value={event.timeZone}
                                            overrideFormStyles
                                            readonly={!event.editable}
                                        />
                                    </>
                                ) : (
                                    EMPTY_VALUE
                                )
                            }
                        />
                    </Grid>
                </Grid>
            </Row>

            <Row>
                <Grid container>
                    <Grid item xs={6}>
                        <DetailItem
                            label={i18n.meetingsPage.endDate}
                            value={event.endsAt ? tzMoment(event.endsAt).format('ll') : EMPTY_VALUE}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DetailItem
                            label={i18n.meetingsPage.endTime}
                            value={
                                event.endsAt ? (
                                    <>
                                        <InlineCopy>{tzMoment(event.endsAt).format('LT')}</InlineCopy>
                                        <TimeZonePicker
                                            date={event.endsAt || new Date().toISOString()}
                                            value={event.timeZone}
                                            overrideFormStyles
                                            readonly={!event.editable}
                                        />
                                    </>
                                ) : (
                                    EMPTY_VALUE
                                )
                            }
                        />
                    </Grid>
                </Grid>
            </Row>

            <Row itemSpacing="larger">
                <DetailItem
                    label={
                        event.budget
                            ? i18n.meetingsPage.totalBudget
                            : event.budgetPerPerson
                              ? i18n.meetingsPage.perPersonBudget
                              : i18n.meetingsPage.budget
                    }
                    value={event.budget ?? event.budgetPerPerson ?? EMPTY_VALUE}
                />
                <DetailItem
                    label={
                        user?.team?.meetingCostCenterFieldLabel
                            ? user.team.meetingCostCenterFieldLabel
                            : i18n.meetingsPage.costCenter
                    }
                    value={event.costCenter ?? EMPTY_VALUE}
                />
            </Row>

            <Row>
                <DetailItem label={i18n.meetingsPage.internalReference} value={String(event.cventId ?? EMPTY_VALUE)} />
            </Row>

            <Row>
                <DetailItem label={i18n.meetingsPage.department} value={String(event.department ?? EMPTY_VALUE)} />
            </Row>

            <Row>
                <DetailItem label={i18n.meetingsPage.meetingType} value={event.type ?? EMPTY_VALUE} />
            </Row>

            <Row>
                <DetailItem label={i18n.meetingsPage.template} value={template.name ?? i18n.meetingsPage.noTemplate} />
            </Row>

            <Row>
                <DetailItem
                    label={i18n.meetingsPage.templateDescription}
                    value={<div dangerouslySetInnerHTML={{ __html: template.shortDescription ?? EMPTY_VALUE }} />}
                />
            </Row>
        </Container>
    );
}
