import { Grid } from '@material-ui/core';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import { loadParcels } from 'api';
import { ParcelMatchboxThumb } from 'components/Communication/CommunicationParcel';
import { Headline } from 'components/ui/Headline';
import { useAsync } from 'hooks/useAsync';
import { uniqueId } from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { AlignedRow, Column, Link } from 'ui';
import { padArray } from '../../util';

const CardColumn = styled(Column)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
`;

const SectionTitle = styled(Headline)`
    margin-top: 16px;
    margin-bottom: 16px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.totalAttendee)};
`;

const StyledLink = styled(Link)`
    font-size: 15px;
`;

const EmptyParcelMatchbox = styled.div`
    width: 100%;
    height: 100%;
    min-height: 143px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.imagePlaceholderBackground)};
    border: dashed 1px ${({ theme: { getColor, EColors } }) => getColor(EColors.preferredRank40)};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
`;

const StyledDraftIcon = styled(DraftsOutlinedIcon)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
`;

const ITEM_COUNT = 4;

type TCommunicationSectionProps = {
    editable: boolean;
};

export default function CommunicationSection({ editable }: TCommunicationSectionProps) {
    const { eventId } = useParams<{ eventId: string }>();

    const { data: parcels = [] } = useAsync<Bizly.Parcel[]>(useCallback(() => loadParcels(eventId), [eventId]));
    const hasParcels = parcels?.length > 0;

    return (
        <CardColumn>
            <AlignedRow justifyContent="space-between">
                <SectionTitle thin>{i18n.meetingDashboard.communicationSection.communication}</SectionTitle>
                <StyledLink href={`/event/${eventId}/communication`}>
                    {editable ? (hasParcels ? i18n.button.edit : i18n.button.create) : i18n.button.view}
                </StyledLink>
            </AlignedRow>

            <Grid container spacing={2}>
                {padArray(parcels, ITEM_COUNT)
                    .slice(0, ITEM_COUNT)
                    .map(parcel => (
                        <Grid item xs={6} key={parcel?.id || uniqueId()}>
                            {parcel ? (
                                <ParcelMatchboxThumb parcel={parcel} eventId={eventId} />
                            ) : (
                                <EmptyParcelMatchbox>
                                    <StyledDraftIcon />
                                </EmptyParcelMatchbox>
                            )}
                        </Grid>
                    ))}
            </Grid>
        </CardColumn>
    );
}
