// This file will serve as an example for a patten we will have to follow moving forwards with some API responses.
// It will be necessary to hardcode an entire example of an API "options-like" object response, so that we can key the
// object as we see fit and make changes to it to work better with the front-end.
// This will require coordination between the front and backend changes that implies an anti-pattern awareness of the
// backend by the front end. However, it will be very useful for a few use-cases, notably translations where the API
// contains English strings.

import { EColors } from 'theme';
import { i18n } from 'translation';

export type VenueTypesDictAPI = {
    [key: number]: { numId: number; id: BizlyAPI.Venue.Types; label: string; pinColor: EColors };
};

export const venueTypesAPIDict = (): VenueTypesDictAPI => ({
    10: {
        // numID 10, or corporate office, is overwritten with the team name on the UI for users
        numId: 10,
        id: 'corporate office',
        label: i18n.venue.types.corporateOffice,
        pinColor: EColors.venuePinOffice,
    },
    1: {
        numId: 1,
        id: 'hotel',
        label: i18n.venue.types.hotel,
        pinColor: EColors.venuePinHotel,
    },
    2: {
        numId: 2,
        id: 'restaurant',
        label: i18n.venue.types.restaurant,
        pinColor: EColors.venuePinDining,
    },
    3: {
        numId: 3,
        id: 'activity',
        label: i18n.venue.types.activity,
        pinColor: EColors.venuePinActivityUnique,
    },
    7: {
        numId: 7,
        id: 'conference center',
        label: i18n.venue.types.conferenceCenter,
        pinColor: EColors.venuePinEventMeetingConference,
    },
    11: {
        numId: 11,
        id: 'bookable office',
        label: i18n.venue.types.bookableOffice,
        pinColor: EColors.venuePinOffice,
    },
    20: {
        numId: 20,
        id: 'bar',
        label: i18n.venue.types.bar,
        pinColor: EColors.venuePinDining,
    },
    30: {
        numId: 30,
        id: 'brewery',
        label: i18n.venue.types.brewery,
        pinColor: EColors.venuePinDining,
    },
    40: {
        numId: 40,
        id: 'winery',
        label: i18n.venue.types.winery,
        pinColor: EColors.venuePinDining,
    },
    50: {
        numId: 50,
        id: 'museum',
        label: i18n.venue.types.museum,
        pinColor: EColors.venuePinMuseumTheater,
    },
    60: {
        numId: 60,
        id: 'theater',
        label: i18n.venue.types.theater,
        pinColor: EColors.venuePinMuseumTheater,
    },
    70: {
        numId: 70,
        id: 'unique venue',
        label: i18n.venue.types.uniqueVenue,
        pinColor: EColors.venuePinActivityUnique,
    },
    80: {
        numId: 80,
        id: 'event venue',
        label: i18n.venue.types.eventVenue,
        pinColor: EColors.venuePinEventMeetingConference,
    },
    90: {
        numId: 90,
        id: 'professional workspace',
        label: i18n.venue.types.professionalWorkspace,
        pinColor: EColors.venuePinWorkspace,
    },
    100: {
        numId: 100,
        id: 'coworking space',
        label: i18n.venue.types.coworkingSpace,
        pinColor: EColors.venuePinWorkspace,
    },
});
