import React, { useState } from 'react';
import { units, useMeasurementUnits } from 'stores/measurement-units';
import styled from 'styled-components';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { tzMoment } from 'utils/moment';
import { sqftToSqm } from 'utils/units';
import { Column, Row, SmallerCopy, SpacedRow, Spacer } from '../../ui';
import { formatCurrency, formatPercentage } from '../../util';
import Accordion from '../ui/Accordion';

const SummaryContainer = styled('div')`
    border-radius: 8px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    display: flex;
    gap: 1em;

    .bold {
        font-weight: 500;
    }
`;

const SectionHeader = styled('div')`
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lighterGrey)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    font-size: 1.125em;
    font-weight: 500;
    padding-bottom: 0.375em;
`;

const DayCardSection = styled(Row)<{ hasBorder?: boolean }>`
    justify-content: space-between;
    padding: 2em;
    box-sizing: border-box;
    gap: 2em;
    align-items: stretch;

    ${({ theme: { getColor, EColors }, hasBorder }) =>
        hasBorder &&
        `
    border-top: 1px solid ${getColor(EColors.lighterGrey)}
    `}
`;

const DaySummary = styled(Column)`
    min-width: 50%;
    padding-bottom: 0.5em;
`;

const SummaryItemRow = styled(SpacedRow)`
    font-size: 0.9375em;
    font-weight: 400;
    padding: 0.375em 0;

    > .label {
        flex: 0.5;
        font-weight: 500;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    }

    > .value {
        flex: 0.5;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
    }
`;

const RoomImageContainer = styled('div')`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const RoomImage = styled('div')<{ src: string }>`
    width: auto;
    height: 100%;
    flex-grow: 1;
    background-image: url(${props => props.src || 'none'});
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
`;

const NoSpaceImage = styled.div`
    width: 314px;
    height: 217px;
    border-radius: 8px;

    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.placeholderBackground)};

    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
        content: 'No Image Available';
        font-size: 15px;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
    }
`;

type DayDetailPropsType = {
    dayIndex: number;
    details: Bizly.VenueDay;
    venueProposal: Bizly.VenueProposal;
};

const DayDetail = ({ dayIndex, details, venueProposal }: DayDetailPropsType) => {
    const [expanded, setExpanded] = useState(false);

    const currencyCode = venueProposal?.currency?.code;

    const { area: areaUnit } = useMeasurementUnits();

    const renderGuestRooms = (guestRoom?: Bizly.VenueDayGR) =>
        guestRoom && (
            <DayCardSection>
                <DaySummary>
                    <SectionHeader>{i18n.proposalForm.sections.guestRooms}</SectionHeader>
                    <Spacer xsmall />
                    <SummaryItemRow>
                        <div className="label">{i18n.venue.inquiry.guestRoomsPerNight}:</div>
                        <div className="value">{guestRoom.quantity}</div>
                    </SummaryItemRow>
                    <SummaryItemRow>
                        <div className="label">{i18n.venue.inquiry.roomName}:</div>
                        <div className="value">{guestRoom.name}</div>
                    </SummaryItemRow>
                    <SummaryItemRow>
                        <div className="label">{i18n.venue.inquiry.roomRate}:</div>
                        <div className="value">{formatCurrency(guestRoom.rate, currencyCode)}</div>
                    </SummaryItemRow>
                    <SummaryItemRow>
                        <div className="label">{i18n.venue.inquiry.occupancyTax}:</div>
                        <div className="value">{Number(venueProposal.occupancyTax)}%</div>
                    </SummaryItemRow>
                    <SummaryItemRow>
                        <div className="label">{i18n.venue.inquiry.resortFee}:</div>
                        <div className="value">{formatCurrency(venueProposal.resortFee, currencyCode)}</div>
                    </SummaryItemRow>
                    <SummaryItemRow>
                        <div className="label">{i18n.venue.inquiry.roomRentalTotal}:</div>
                        <div className="label">{formatCurrency(guestRoom.subTotal, currencyCode)}</div>
                    </SummaryItemRow>

                    <Spacer xsmall />
                    {venueProposal.commissionable && (
                        <SmallerCopy $color={EColors.grey}>{i18n.venue.inquiry.guestRoomsCommissionable}</SmallerCopy>
                    )}
                    <SmallerCopy $color={EColors.grey}>{i18n.venue.inquiry.amountDescription}</SmallerCopy>
                </DaySummary>
                <RoomImageContainer>
                    {guestRoom.imageUrl ? <RoomImage src={guestRoom.imageUrl} /> : <NoSpaceImage />}
                </RoomImageContainer>
            </DayCardSection>
        );

    const renderEventSpaces = (eventSpaces: Bizly.EventSpace[], hasBorder: boolean) =>
        eventSpaces.map(
            ({ id, fbMinimum, rate, imageUrl: eventSpaceImageUrl, venueSpace, startsAt, endsAt, guests }) => {
                const startTime = tzMoment(startsAt);
                const startTimeDisplay = startTime ? startTime.format('LT') : undefined;
                const endTime = tzMoment(endsAt);
                const endTimeDisplay = endTime ? endTime.format('LT') : undefined;
                const timesDisplay = [startTimeDisplay, endTimeDisplay].join(' - ');

                return (
                    <DayCardSection key={id} hasBorder>
                        <DaySummary>
                            <SectionHeader>{i18n.venue.inquiry.meetingSpace}</SectionHeader>
                            <Spacer xsmall />
                            <SummaryItemRow>
                                <div className="label">{i18n.communication.attendees}:</div>
                                <div className="value">{guests}</div>
                            </SummaryItemRow>
                            <SummaryItemRow>
                                <div className="label">{i18n.common.time}:</div>
                                <div className="value">{timesDisplay}</div>
                            </SummaryItemRow>
                            <SummaryItemRow>
                                <div className="label">{i18n.venue.inquiry.roomName}:</div>
                                <div className="value">{venueSpace.name}</div>
                            </SummaryItemRow>
                            <SummaryItemRow>
                                <div className="label">{i18n.venue.inquiry.roomSizeWithUnit(areaUnit)}:</div>
                                <div className="value">
                                    {areaUnit === units.sqm ? sqftToSqm(venueSpace.size) : venueSpace.size}
                                </div>
                            </SummaryItemRow>
                            <SummaryItemRow>
                                <div className="label">{i18n.venue.inquiry.roomMaxCapacity}:</div>
                                <div className="value">{venueSpace.maxCapacity?.toLocaleString()}</div>
                            </SummaryItemRow>
                            <SummaryItemRow>
                                <div className="label">{i18n.venue.inquiry.roomRate}:</div>
                                <div className="value">{formatCurrency(rate, currencyCode)}</div>
                            </SummaryItemRow>
                            <SummaryItemRow>
                                <div className="label">{i18n.venue.inquiry.serviceCharge}:</div>
                                <div className="value">{formatPercentage(venueProposal.serviceCharge)}</div>
                            </SummaryItemRow>
                            <SummaryItemRow>
                                <div className="label">{i18n.venue.inquiry.salesTax}:</div>
                                <div className="value">{formatPercentage(venueProposal.salesTax)}</div>
                            </SummaryItemRow>
                            <SummaryItemRow>
                                <div className="label">{i18n.venue.inquiry.gratuity}:</div>
                                <div className="value">{formatPercentage(venueProposal.gratuity)}</div>
                            </SummaryItemRow>
                            <SummaryItemRow>
                                <div className="label">{i18n.venue.inquiry.fbMin}:</div>
                                <div className="value">{formatCurrency(fbMinimum, currencyCode)}</div>
                            </SummaryItemRow>
                            <SummaryItemRow>
                                <div className="label">{i18n.venue.inquiry.meetingSpaceTotal}:</div>
                                <div className="label">{formatCurrency(fbMinimum + rate, currencyCode)}</div>
                            </SummaryItemRow>

                            <SmallerCopy $color={EColors.grey}>{i18n.venue.inquiry.amountDescription}</SmallerCopy>
                        </DaySummary>
                        <RoomImageContainer>
                            {eventSpaceImageUrl ? <RoomImage src={eventSpaceImageUrl} /> : <NoSpaceImage />}
                        </RoomImageContainer>
                    </DayCardSection>
                );
            }
        );

    return (
        <Accordion
            identity="Playbooks"
            expanded={expanded}
            summaryColor="lighterGrey"
            summaryContent={
                <SummaryContainer>
                    <div className="bold">{i18n.common.dayIndex(dayIndex)}</div>
                    <div>{details.day && tzMoment(details.day).format('dddd, LL')}</div>
                </SummaryContainer>
            }
            detailContent={
                <Column fillWidth>
                    {renderGuestRooms(details.guestRooms[0])}
                    {renderEventSpaces(details.eventSpaces, !!details.guestRooms[0])}
                </Column>
            }
            handleChange={() => {
                setExpanded(!expanded);
            }}
            hasBorder={false}
            noDetailPadding
            elevation={0}
        />
    );
};

export default DayDetail;
