import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Box, InputAdornment, Tooltip, styled as muiStyled } from '@mui/material';
import Autocomplete, { AutocompleteRenderOptionState, AutocompleteValue } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import SearchIcon from '@mui/icons-material/Search';
import { findPlaces, getVenueByGooglePlaceId } from 'api';
import { QueryParamEnum } from 'constants/queryParams';
import BuildingSVG from 'images/icons/building-icon.svg?react';
import LocationPinSvg from 'images/icons/location_pin.svg?react';
import { withIconStyles } from 'shared';
import { i18n } from 'translation';
import { Copy } from 'ui';
import CheckMarkSvg from '../../../images/icons/check-mark.svg?react';
import { Button } from '../Button/Button';

const LocationPinIcon = styled(withIconStyles(LocationPinSvg))`
    min-width: 21px;
    max-width: 21px;
    padding-right: 1px;
`;

const BuildingIcon = styled(withIconStyles(BuildingSVG))`
    width: 16px;
    height: 16px;
`;

const CheckMarkIcon = styled(CheckMarkSvg)`
    margin-left: 12px;
`;

const SuggestionText = styled(Copy).attrs({ regular: true })`
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
`;

const Input = muiStyled(TextField)(({ theme }) => ({
    paddingTop: '1rem',
    '.MuiOutlinedInput-notchedOutline': {
        borderRadius: theme.spacing(0.5),
    },
}));

const desiredTypes = [
    'lodging',
    'art_gallery',
    'bar',
    'bowling_alley',
    'casino',
    'museum',
    'night_club',
    'restaurant',
    'spa',
    'stadium',
];

type TOption = {
    description: string;
    placeId: string;
    noIcon?: boolean;
    types?: string[];
};

type TPlaceInput<TInputAsSuggestion extends boolean | undefined> = {
    locationBias?: Bizly.Location;
    clearable?: boolean;
    onRemove?: () => void;
    directVenueLinking?: boolean;
    locationValue?: string;
    placeId?: string;
    disabled?: boolean;
    placeholder?: string;
    required?: boolean;
} & (TInputAsSuggestion extends true
    ? {
          inputAsSuggestion: true;
          onChange: (city: string, googlePlaceId?: string | undefined) => void;
      }
    : {
          inputAsSuggestion?: false | undefined;
          onChange: (city: string, googlePlaceId: string) => void;
      });

const GooglePlacesAutocomplete = (props: TPlaceInput<true> | TPlaceInput<false>) => {
    const {
        locationBias,
        onChange,
        clearable,
        onRemove,
        directVenueLinking,
        inputAsSuggestion,
        locationValue,
        placeId,
        disabled,
        placeholder,
        required,
    } = props;

    const navigate = useNavigate();

    const [inputQuery, setInputQuery] = useState<string>('');
    const [options, setOptions] = useState<TOption[]>([]);
    const [loading, setLoading] = useState(false);
    const [noOptionsText, setNoOptionsText] = useState(i18n.venue.noLocationsForQuery(inputQuery ?? ''));

    const inputOption = useMemo(
        () => ({
            description: locationValue ?? '',
            placeId: placeId ?? '',
            noIcon: true,
            types: undefined,
        }),
        [locationValue, placeId]
    );

    const resetOptions = () => {
        setLoading(false);
        setOptions([]);
    };

    // Debounce the API call to avoid excessive requests
    const debouncedFetch = useMemo(
        () =>
            debounce(async (query: string) => {
                setNoOptionsText(i18n.venue.noLocationsForQuery(query || ''));
                if (query.length > 0) {
                    setLoading(true);
                    try {
                        const response = await findPlaces(query, locationBias?.lat, locationBias?.lng);
                        if (response.status === 'OK') {
                            setOptions([...response.predictions]);
                        } else {
                            resetOptions();
                        }
                    } catch {
                        setNoOptionsText(i18n.error.default);
                    } finally {
                        setLoading(false);
                    }
                } else {
                    resetOptions();
                }
            }, 300),
        [locationBias]
    );

    useEffect(() => {
        // Call the debounced function whenever the input value changes
        debouncedFetch(inputQuery);

        // Cleanup function to cancel any pending debounced calls on component unmount
        return () => {
            debouncedFetch.cancel();
        };
    }, [inputQuery, debouncedFetch]);

    const handleSelection = (city: string, placeId: string) => {
        setInputQuery(city);
        onChange?.(city, placeId);
    };

    function handleClear() {
        resetOptions();
        if (clearable) onRemove?.();
    }

    const onInputChange = (_: React.SyntheticEvent, newInputQuery: string) => {
        setInputQuery(newInputQuery);
        if (!newInputQuery) {
            handleClear();
        }
    };

    const onSelectionChange = (e: React.SyntheticEvent, val: AutocompleteValue<TOption, false, false, true>) => {
        if (!e || !val || typeof val === 'string') {
            handleClear();
            return;
        }
        handleSelection(val.description, val.placeId);
    };

    async function openVenueDetailDialog(option: TOption) {
        try {
            const result = await getVenueByGooglePlaceId(option.placeId);
            if (result.success) {
                const params = new URLSearchParams(location.search);
                params.set(QueryParamEnum.VENUE_ID, result.venue.id.toString());
                navigate({
                    pathname: location.pathname,
                    search: params.toString(),
                });
            }
        } catch (error) {
            console.error('Error fetching venue details:', error);
        }
    }

    const renderOption = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: TOption,
        state: AutocompleteRenderOptionState
    ) => {
        const hasDesiredType = option?.types?.some((type: string) => desiredTypes.includes(type));
        const hasEstablishmentType = directVenueLinking && option?.types?.includes('establishment') && hasDesiredType;
        return (
            <li {...props} key={option.placeId}>
                <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
                    <Box display="flex" alignItems="center" flex={1}>
                        {!option.noIcon && <LocationPinIcon />}
                        {<SuggestionText>{option.description}</SuggestionText>}
                        {hasEstablishmentType && (
                            <Tooltip title={i18n.venue.goToVenuePage} placement="top" arrow>
                                <Box>
                                    <Button
                                        sx={{ borderRadius: 4, ml: 1 }}
                                        onClick={e => {
                                            e.stopPropagation();
                                            openVenueDetailDialog(option);
                                        }}
                                    >
                                        <BuildingIcon />
                                    </Button>
                                </Box>
                            </Tooltip>
                        )}
                    </Box>
                    {state.selected && <CheckMarkIcon />}
                </Box>
            </li>
        );
    };

    return (
        <Autocomplete
            freeSolo
            disabled={disabled}
            loading={loading}
            disableClearable={!clearable}
            options={options.length > 0 ? [...(inputAsSuggestion ? [inputOption] : []), ...options] : []}
            getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
            renderOption={renderOption}
            noOptionsText={noOptionsText}
            onInputChange={onInputChange}
            value={inputOption}
            filterOptions={x => x}
            renderInput={params => (
                <Input
                    {...params}
                    placeholder={placeholder ?? `${i18n.homepage.createMeetingModal.find} (required)`}
                    variant="outlined"
                    fullWidth
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            onChange={onSelectionChange}
        />
    );
};

export default GooglePlacesAutocomplete;
