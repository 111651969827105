import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Link, styled as muiStyled, styled, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridRowParams, GridSortModel } from '@mui/x-data-grid-pro';
import ActionItemsModal from 'components/ActionItems/ActionItemsModal';
import { Button } from 'components/Ui-V2/Button/Button';
import EmptyVisual from 'components/Ui-V2/EmptyVisual';
import { useEventsQuery } from 'hooks/queries/useEventsQuery';
import { useBudgetApprovalRequests } from 'hooks/queries/useQueries';
import useShowModal from 'hooks/useShowModal';
import EmptyStateImage from 'images/empty-pages-assets/empty_events.svg';
import StatusPill from 'pages/Meetings/StatusPill';
import { useUser } from 'providers/user';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useEventsFiltersStore from 'stores/events/eventsStore';
import { i18n } from 'translation';
import { fullTimestamp, shortTimestamp } from 'utils/date_util';
import { tzMoment, userTimeZone } from 'utils/moment';
import { EventTableToolbar } from './EventTableToolbar';
const BorderlessCard = muiStyled(Box)<{ flex?: boolean }>(({ flex }) => ({
    position: 'relative',
    flex: flex ? 1 : 'unset',
    boxSizing: 'border-box',
}));

const StyledDataGridPro = muiStyled(DataGridPro)<{ isEmpty: boolean }>(({ theme, isEmpty }) => ({
    flexGrow: 1,

    '.MuiDataGrid-main': {
        display: isEmpty ? 'none' : 'block',
    },

    '.MuiDataGrid-footerContainer': {
        display: isEmpty ? 'none' : 'flex',
    },

    '&.MuiDataGrid-root': {
        border: 'none',
        maxHeight: '100%',
    },
    '& .MuiToggleButtonGroup-root': {
        margin: theme.spacing(0.5, 0),
    },
    '& .MuiDataGrid-columnHeader': {
        borderRight: 'none',
        backgroundColor: theme.palette.grey[100],
    },
    '& .MuiDataGrid-overlayWrapper': {
        height: 'auto',
    },
    '& .MuiDataGrid-main': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
    },
    '& .MuiDataGrid-footerContainer': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        borderTopWidth: 0,
    },
    '& .MuiDataGrid-row': {
        '--rowBorderColor': 'transparent',
        cursor: 'pointer',
    },
    '& .MuiDataGrid-columnHeader:focus': {
        outline: 'none',
    },
}));

const StyledEmptyVisualContainer = styled(Box)({
    marginTop: '10lvh',
});

type EmptyStateText = {
    title: string;
    description: string;
};

type EventTableProps = {
    createEvent?: () => void;
};

export const EventTable = ({ createEvent }: EventTableProps) => (
    <BorderlessCard flex={true}>
        <EventTableContent createEvent={createEvent} />
    </BorderlessCard>
);

export type TabValue = 'all' | 'upcoming' | 'createdByOwner' | 'actionItems';

const STATUS_ORDER: { [key in BizlyAPI.MeetingStatusCode]: number } = {
    inquiry_response: 1,
    new: 2,
    inquiry_draft: 3,
    inquiry_submitted: 4,
    inquiry_sent: 5,
    proposal_accepted: 6,
    booked: 7,
    booking_over: 8,
    booking_tracked: 9,
    cancelled: 10,
};

export type TabConfigItem = {
    icon: React.ReactNode;
    label: string;
};

const EventTableContent = ({ createEvent }: EventTableProps) => {
    const { user } = useUser();
    const navigate = useNavigate();
    const { filters } = useEventsFiltersStore();

    const [tab, setTab] = useState<TabValue>('all');
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'startsAt', sort: 'desc' }]);
    const [selectedItem, setSelectedItem] = useState<BizlyAPI.BudgetApproval>();

    const { data: eventsData, isLoading: isLoadingEvents } = useEventsQuery(filters);
    const { budgetApprovalRequests, isLoading: isLoadingBudgetApprovalRequests } = useBudgetApprovalRequests(false);
    const { modalShown, showModal, hideModal } = useShowModal();

    const isAdmin = user.id === user?.team?.budgetApprover?.id;

    const viewEvent = useCallback(
        (eventId: number) => {
            if (user.featureFlags?.createMeetingsFlow) {
                return navigate(`/events/${eventId}`);
            }
            return navigate(`/event/${eventId}`);
        },
        [navigate, user.featureFlags?.createMeetingsFlow]
    );

    const filteredEvents = useMemo(() => {
        if (!eventsData) return [];

        switch (tab) {
            case 'all':
                return eventsData;
            case 'upcoming':
                return eventsData.filter(event => {
                    const now = tzMoment().tz(userTimeZone);
                    const meetingStartTime = tzMoment(event.startsAt, event.timeZone).tz(userTimeZone);
                    return meetingStartTime.isAfter(now);
                });
            case 'createdByOwner':
                return eventsData.filter(event => user.email === event.plannedBy.email);
            case 'actionItems':
                return [];
            default:
                return [];
        }
    }, [eventsData, tab, user.email]);

    const eventsTableColumns: GridColDef<Bizly.Event>[] = useMemo(
        () => [
            { field: 'name', headerName: i18n.meetingSearch.meetingName, flex: 2 },
            {
                field: 'startsAt',
                headerName: i18n.meetingSearch.date,
                flex: 1,
                type: 'string',
                valueFormatter: params => {
                    return params ? tzMoment(params, 'UTC').tz(userTimeZone).format('LL') : '';
                },
                sortComparator: (v1, v2) => {
                    if (!v1) {
                        return -1;
                    }
                    if (!v2) {
                        return 1;
                    }
                    return tzMoment(v1).isBefore(tzMoment(v2)) ? -1 : 1;
                },
            },
            {
                field: 'plannedBy',
                headerName: i18n.meetingSearch.owner,
                flex: 1,
                display: 'flex',
                renderCell: ({ value }) => {
                    return (
                        <Box display="flex" alignItems="center" gap={1}>
                            {value?.imageUrl.includes('default-avatar') ? (
                                <Avatar>
                                    <PersonIcon />
                                </Avatar>
                            ) : (
                                <Avatar src={value?.imageUrl} />
                            )}
                            <Typography>{[value?.firstName, value?.lastName].join(' ')}</Typography>
                        </Box>
                    );
                },
                sortComparator: (v1, v2) => {
                    const name1 = [v1.firstName, v1.lastName].join(' ');
                    const name2 = [v2.firstName, v2.lastName].join(' ');
                    return name1 < name2 ? -1 : 1;
                },
            },
            {
                field: 'status',
                headerName: i18n.meetingSearch.status,
                width: 120,
                flex: 1,
                renderCell: ({ row }) => {
                    return (
                        <Box display="inline-block">
                            <StatusPill
                                squared
                                meeting={row}
                                tooltip={
                                    row.cancelledAt
                                        ? i18n.meetingsPage.cancelledOn(fullTimestamp(row.cancelledAt, row.timeZone))
                                        : ''
                                }
                            />
                        </Box>
                    );
                },
                sortComparator: (v1: BizlyAPI.MeetingStatus, v2: BizlyAPI.MeetingStatus) => {
                    return STATUS_ORDER[v1.code] - STATUS_ORDER[v2.code];
                },
            },
            {
                field: 'updatedAt',
                headerName: i18n.meetingSearch.activity,
                flex: 1,
                type: 'string',
                valueFormatter: params => {
                    return params ? shortTimestamp(params) : '';
                },
                sortComparator: (v1, v2) => (tzMoment(v1).isBefore(tzMoment(v2)) ? -1 : 1),
            },
        ],
        []
    );

    const actionItemsTableColumns: GridColDef<BizlyAPI.BudgetApproval>[] = [
        {
            field: 'eventName',
            headerName: i18n.homepage.actionItems.headCells.meetingName,
            flex: 2,
            valueGetter: (_: string, row: BizlyAPI.BudgetApproval) => row.event.name,
        },
        {
            field: 'eventDate',
            flex: 1,
            headerName: i18n.homepage.actionItems.headCells.date,
            valueGetter: (_: string, row: BizlyAPI.BudgetApproval) => tzMoment(row.event.startsAt).format('LL'),
        },
        {
            field: 'requestedBy',
            headerName: i18n.homepage.actionItems.headCells.owner,
            flex: 1,
            display: 'flex',
            renderCell: ({ value }) => {
                return (
                    <Box display="flex" alignItems="center" gap={1}>
                        {value?.imageUrl.includes('default-avatar') ? (
                            <Avatar>
                                <PersonIcon />
                            </Avatar>
                        ) : (
                            <Avatar src={value?.imageUrl} />
                        )}
                        <Typography>{[value?.firstName, value?.lastName].join(' ')}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'approvalRequestDetails',
            headerName: i18n.homepage.actionItems.headCells.approvalRequestDetails,
            flex: 2,
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <Link
                        onClick={() => {
                            showModal();
                            setSelectedItem(row);
                        }}
                    >
                        {i18n.homepage.actionItems.reviewBudgetApprovalRequest}
                    </Link>
                );
            },
        },
        {
            field: 'proposal',
            headerName: i18n.homepage.actionItems.headCells.proposalExpiryDate,
            flex: 1,
            valueGetter: (value: { onHoldUntil: string }) => shortTimestamp(value.onHoldUntil),
        },
    ];

    const handleRowClick = (params: GridRowParams<Bizly.Event>) => {
        viewEvent(params.row.id);
    };

    const rows = tab === 'actionItems' ? budgetApprovalRequests : filteredEvents;
    const isLoading = tab === 'actionItems' ? isLoadingBudgetApprovalRequests : isLoadingEvents;
    const isRowsEmpty = rows.length === 0;

    const emptyStateTexts: Record<'admin' | 'user', Record<TabValue, EmptyStateText>> = {
        admin: {
            all: { title: 'Nothing planned yet', description: 'Any event you or your team creates will show here.' },
            upcoming: { title: 'No Upcoming Event', description: 'Any upcoming event will show here.' },
            createdByOwner: {
                title: 'Host Your Next Event',
                description: `You've not created any event yet. Let's change that!`,
            },
            actionItems: {
                title: 'Nothing planned yet',
                description: 'Any event you or your team creates will show here.',
            },
        },
        user: {
            all: { title: 'Nothing planned yet', description: 'Any event you create will show here.' },
            upcoming: { title: 'No Upcoming Event', description: 'Your upcoming event will show here' },
            createdByOwner: {
                title: 'Host Your Next Event',
                description: `You've not created any event yet. Let's change that!`,
            },
            actionItems: { title: 'Nothing planned yet', description: 'Any event you create will show here.' },
        },
    };

    const getEmptyStateText = (tab: TabValue): EmptyStateText => {
        return isAdmin ? emptyStateTexts.admin[tab] : emptyStateTexts.user[tab];
    };

    const { title, description } = useMemo(() => getEmptyStateText(tab), [tab, isAdmin]);

    return (
        <Box>
            <StyledDataGridPro
                isEmpty={isRowsEmpty}
                pagination
                autoHeight
                disableColumnMenu
                disableColumnResize
                rows={rows}
                columns={tab === 'actionItems' ? actionItemsTableColumns : eventsTableColumns}
                loading={isLoading}
                onRowClick={tab === 'actionItems' ? undefined : handleRowClick}
                sortModel={sortModel}
                onSortModelChange={(model: GridSortModel) => setSortModel(model)}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                    },
                }}
                slots={{
                    toolbar: () => (
                        <EventTableToolbar
                            tab={tab}
                            onTabChange={setTab}
                            showActionItems={isAdmin}
                            hasActionItems={budgetApprovalRequests?.length > 0}
                        />
                    ),
                }}
            />

            {isRowsEmpty && !isLoading && (
                <StyledEmptyVisualContainer>
                    <EmptyVisual
                        image={<img src={EmptyStateImage} alt="No events" />}
                        title={title}
                        description={description}
                        actionButton={
                            <Button startIcon={<AddIcon />} onClick={createEvent}>
                                {i18n.homepage.eventButtons.newEvent}
                            </Button>
                        }
                    />
                </StyledEmptyVisualContainer>
            )}

            {modalShown && selectedItem && (
                <ActionItemsModal
                    actionTableItem={selectedItem}
                    onClose={hideModal}
                    setActionTableItems={updatedItems => {

                    }}
                />
            )}
        </Box>
    );
};
