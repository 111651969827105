import Popover from '@material-ui/core/Popover';
import colorFns from 'colorFns';
import DeleteIconSVG from 'images/icons/trash-can.svg?react';
import { Component } from 'react';
import { withIconStyles, withInteractibleIconStyles } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';
import { addDocument, loadDocuments, removeDocument } from '../api';
import DocumentIconSVG from '../images/icons/document.svg?react';
import { EventContext } from '../providers/event';
import { AlignedRow, Column, Copy, ExternalLink, Spacer } from '../ui';
import { CloudinaryUploader } from './CloudinaryUploader';
import { Spinner } from './Spinner';

const DocumentIcon = styled(withIconStyles(DocumentIconSVG))`
    min-width: 28px;
`;

const DeleteIcon = styled(withInteractibleIconStyles(DeleteIconSVG))`
    min-width: 28px;
    margin-left: auto;
`;

const Pop = styled(Column)`
    min-height: 400px;
    width: 300px;
    font-size: 14px;
    font-weight: 600;
`;

const PopHeader = styled.div`
    padding: 24px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.strongAccentedBackground)};

    h1 {
        font-size: 18px;
        margin-top: 0;
    }
`;

const PopContent = styled(Column)`
    padding: 24px;
    flex-grow: 1;
`;

const GrowingColumn = styled(Column)`
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
`;

const GrowingColumnList = styled(GrowingColumn)`
    & > *:not(:last-child) {
        border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    }
`;

const TruncatedCopy = styled(ExternalLink)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Document = styled(AlignedRow)`
    width: 95%;
    cursor: pointer;
    padding: 12px;
    color: ${colorFns.primaryActionHover};
    &:hover,
    &:focus {
        color: ${colorFns.primaryActionHover};
    }
`;

const ZeroState = styled(Copy)`
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
`;

const ErrorDescription = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.warningText)};
`;

export class DocumentsPanel extends Component<
    {
        id: Bizly.Event['id'];
        editable?: boolean;
        anchor: Element | ((element: Element) => Element) | null | undefined;
        onClose: () => void;
    },
    any
> {
    static contextType = EventContext;

    state = {
        documents: null,
        error: null,
        pending: false,
    };

    componentDidMount() {
        this.load();
    }

    async load() {
        const { id: eventId } = this.props;

        this.setState({
            pending: true,
        });

        let documents;
        try {
            documents = await loadDocuments(eventId);
        } catch (e) {
            console.error(`Could not fetch documents for meeting ${eventId}`);
        }

        this.setState({
            documents,
            pending: false,
        });
    }

    handleStart() {
        this.setState({
            error: null,
        });
    }

    async handleSuccess(document: any) {
        const { id: eventId } = this.props;
        await addDocument(eventId, document);
        this.load();
    }

    handleError(error: any) {
        this.setState({
            error,
        });
    }

    async handleRemove(document: Bizly.EventResource) {
        const { id: eventId } = this.props;
        await removeDocument(eventId, document.id);
        this.load();
    }

    render() {
        const { anchor, onClose } = this.props;
        const { documents } = this.state;
        const { error, pending } = this.state;
        const { event } = this.context;

        return (
            <Popover
                anchorEl={anchor}
                open={!!anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={onClose}
            >
                <Pop>
                    <PopHeader>
                        <h1>{i18n.meetingDashboard.headerSection.documents.yourMeetingFiles}</h1>
                        {i18n.meetingDashboard.headerSection.documents.subheading}
                    </PopHeader>
                    {documents && (
                        <PopContent>
                            {pending ? (
                                <GrowingColumn>
                                    <Spinner />
                                </GrowingColumn>
                            ) : (documents as any).length > 0 ? (
                                <GrowingColumnList>
                                    {(documents as any)?.map((document: any) => (
                                        <Document>
                                            <DocumentIcon />
                                            <TruncatedCopy key={document.id} href={document.url} openInNewTab>
                                                {document.title}
                                            </TruncatedCopy>
                                            <DeleteIcon onClick={() => this.handleRemove(document)} />
                                        </Document>
                                    ))}
                                </GrowingColumnList>
                            ) : (
                                <ZeroState>{i18n.meetingDashboard.headerSection.documents.emptyFiles}</ZeroState>
                            )}
                            <Spacer />

                            {event.editable && (
                                <CloudinaryUploader
                                    ctaLabel={i18n.button.upload}
                                    onUploadStart={() => this.handleStart()}
                                    onUploadSuccess={(document: any) => this.handleSuccess(document)}
                                    onUploadError={(e: any) => this.handleError(e)}
                                />
                            )}
                            {error && (
                                <>
                                    <Spacer />
                                    <ErrorDescription>{error}</ErrorDescription>
                                </>
                            )}
                        </PopContent>
                    )}
                </Pop>
            </Popover>
        );
    }
}
