import EventSettingsHeaderSection from 'components/Events/EventSettingsHeaderSection';
import BackArrowIcon from 'images/icons/back_arrow.svg?react';
import CalculatorSvg from 'images/icons/calculator.svg?react';
import DocumentIconSVG from 'images/icons/document.svg?react';
import { useAppConfigContext } from 'providers/app-config';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { parse } from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { Location, Route, Routes, useLocation } from 'react-router-dom';
import { Fill, Slot } from 'react-slot-fill';
import { withInteractibleIconStyles } from 'shared';
import { LoadCurrentInquiry, hasBookedVenue, useCurrentInquiry } from 'stores/current-inquiry';
import styled from 'styled-components';
import { AlignedRow, Spacer } from 'ui';
import { getHeaderHeight } from 'utils/header';
import CollaboratorsHeaderSection from './Collaborators/CollaboratorsHeaderSection';
import { DocumentsPanel } from './DocumentsPanel';
import { showFinalSpendReminders } from './FinalSpendTracking';
import FinalSpendBanner from './FinalSpendTracking/Banner';
import Flyout from './FinalSpendTracking/Flyout';
import Button, { ButtonProps } from './ui/Button';
import { H3Headline } from './ui/Headline';
import HoverableIcon from './ui/HoverableIcon';

const DocumentIcon = withInteractibleIconStyles(DocumentIconSVG);

const Header = styled.header<{ transparent?: boolean; pageOffset: number; maxSearchWidth: number }>`
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    top: 0;
    z-index: 1000;
    width: calc(100vw - ${({ pageOffset }) => pageOffset}px);
    box-sizing: border-box;

    transition: 0.3s all ease-in-out;

    ${({ transparent, theme: { getColor, EColors } }) =>
        transparent
            ? `
    height: ${getHeaderHeight(transparent)}px;
    background: transparent;
    border-bottom: 1px solid transparent;`
            : `
    height: ${getHeaderHeight(transparent)}px;
    background: ${getColor(EColors.pureWhite)};
    border-bottom: 1px solid ${getColor(EColors.grey)};
  `}

    & > *:first-child {
        flex: 1 0 0;
        min-width: 0px;
        padding-right: 32px;
        padding-left: 36px;
        box-sizing: border-box;
    }

    & > *:nth-child(2) {
        flex: ${({ maxSearchWidth }) => maxSearchWidth}00 0 0; /* This allows the middle to grow before everything else */
        min-width: 324px;
        max-width: ${({ maxSearchWidth }) => maxSearchWidth}px;
    }

    & > *:last-child {
        padding-left: 32px;
        padding-right: 36px;
        box-sizing: border-box;
    }
    /* If the middle exists, size the right as same as left, if not, don't grow right */
    & > *:last-child:nth-child(2) {
        flex: 0 0 auto;
        width: auto;
        min-width: unset;
        max-width: unset;
    }
    & > *:last-child:nth-child(3) {
        flex: 1 0 0;
        min-width: 0px;
    }
`;

const HeaderShadow = styled(Header)`
    position: relative;
    width: 1px;
    visibility: hidden;
`;

const ItemsRight = styled(AlignedRow)`
    justify-content: flex-end;
`;
const ItemsLeft = styled(AlignedRow)`
    justify-content: flex-start;
`;

const BackButtonWrapper = styled(Button)`
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
`;

export const BackButton = ({ onClick, inverted }: { onClick: ButtonProps['onClick']; inverted?: boolean }) => {
    return (
        <BackButtonWrapper
            aria-label="navigate-back"
            onClick={onClick}
            secondary={inverted}
            noBorder
            width={30}
            height={30}
        >
            <BackArrowIcon />
        </BackButtonWrapper>
    );
};

// TODO: this function has some brittle assumptions. rethink.
// this component is used for dashboard and subroutes for events, and we want to display additional
// information if on subroutes. we assume here that if the last piece of the current url is a number
// (the id of the event) then we are on a subroute, i.e. https://beta.bizly.com/event/11122/communication
const subroute = (location: Location) => {
    const lastPiece = location.pathname.split('/')[3] || '';
    if (isNaN(Number(lastPiece))) {
        return lastPiece.replace('-', ' ');
    }
};

export const getLast = (location: Location) =>
    subroute(location) ? location.pathname.substr(0, location.pathname.lastIndexOf('/')) : '/';

const EventTitleStatic = styled(H3Headline)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
    flex: 0 0 auto;
    text-transform: capitalize;
    font-size: 1.5rem;
`;

const CalculatorIcon = styled(withInteractibleIconStyles(CalculatorSvg))`
    margin-left: 6px;
    height: 29px;
    width: 29px;
`;

const EventLeftContent = () => {
    const { event } = useEvent();
    const EventTitleText = event.name || 'Untitled Meeting';

    return <EventTitleStatic>{EventTitleText}</EventTitleStatic>;
};

const EventRightContent = () => {
    const location = useLocation();
    const [documentsAnchor, setDocumentsAnchor] = useState<Nullable<Element>>(null);
    const [finalSpendAnchor, setFinalSpendAnchor] = useState<Nullable<Element>>(null);
    const [showFinalSpend, setShowFinalSpend] = useState(false);
    const finalSpendAnchorRef = useRef(null);

    const { event } = useEvent();

    const currentInquiry = useCurrentInquiry();

    useEffect(() => {
        const { trackspending } = parse(location.search, { parseBooleans: true });
        if (trackspending) {
            setFinalSpendAnchor(finalSpendAnchorRef.current);
            setShowFinalSpend(true);
        }
    }, [location.search]);

    return (
        <>
            <LoadCurrentInquiry />
            <ItemsRight>
                <CollaboratorsHeaderSection />

                <EventSettingsHeaderSection />

                <HoverableIcon hoverText="Documents">
                    <DocumentIcon
                        onClick={(e: React.MouseEvent) => {
                            if (e.target instanceof Element) {
                                setDocumentsAnchor(documentsAnchor ? null : e.target);
                            }
                        }}
                    />
                </HoverableIcon>
                {documentsAnchor && (
                    <DocumentsPanel
                        id={event.id}
                        editable={event.editable}
                        anchor={documentsAnchor}
                        onClose={() => setDocumentsAnchor(null)}
                    />
                )}

                <HoverableIcon hoverText="Final Spend Tracker">
                    <CalculatorIcon
                        ref={finalSpendAnchorRef}
                        onClick={(e: React.MouseEvent) => {
                            if (e.target instanceof Element) {
                                setFinalSpendAnchor(finalSpendAnchor ? null : e.target);
                            }
                        }}
                    />
                </HoverableIcon>
                {finalSpendAnchor && (
                    <Flyout
                        finalSpendAnchor={finalSpendAnchor}
                        setFinalSpendAnchor={setFinalSpendAnchor}
                        showFinalSpend={showFinalSpend}
                    />
                )}

                <Spacer />
            </ItemsRight>

            {showFinalSpendReminders(event) && currentInquiry?.venues && hasBookedVenue(currentInquiry?.venues) && (
                <EventHeaderBannerFill>
                    <FinalSpendBanner openFlyout={() => setFinalSpendAnchor(finalSpendAnchorRef.current)} />
                </EventHeaderBannerFill>
            )}
        </>
    );
};

const Left = ItemsLeft;
const Right = ItemsRight;

const EventHeaderLeftSlotName = 'Event.Header.Left.Slot';
const EventHeaderLeftSlot = () => <Slot name={EventHeaderLeftSlotName} />;

export const EventHeaderLeftFill = ({ children }: { children: React.ReactNode }) => (
    <Fill name={EventHeaderLeftSlotName}>{children}</Fill>
);

const EventHeaderRightSlotName = 'Event.Header.Right.Slot';
const EventHeaderIconsSlot = () => <Slot name={EventHeaderRightSlotName} />;

export const EventHeaderIconsFill = ({ children }: { children: React.ReactNode }) => (
    <Fill name={EventHeaderRightSlotName}>{children}</Fill>
);

const EventHeaderBannerName = 'Event.Header.Banner.Slot';
const EventHeaderBannerSlot = () => <Slot name={EventHeaderBannerName} />;

const EventHeaderBannerFill = ({ children }: { children: React.ReactNode }) => (
    <Fill name={EventHeaderBannerName}>{children}</Fill>
);

const EventHeader = ({
    loading,
    pageOffset = 0,
    maxSearchWidth = 440,
    isCreateMeeting,
}: {
    loading: boolean;
    pageOffset?: number;
    maxSearchWidth?: number;
    isCreateMeeting?: boolean;
}) => {
    const { toggles } = useUser();
    const { transparentHeader } = useAppConfigContext();
    const location = useLocation();

    const isNewVenuePage = location.pathname.includes('/venue/listing/') && toggles.gate.useNewVenuePage;

    return isNewVenuePage ? null : (
        <>
            <Header transparent={transparentHeader} pageOffset={pageOffset} maxSearchWidth={maxSearchWidth}>
                <Left>
                    <Routes>
                        {isCreateMeeting && <Route path={`venue`} element={<EventHeaderLeftSlot />} />}
                        <Route path="*" element={<EventLeftContent />} />
                    </Routes>
                </Left>

                <Right>
                    <Routes>
                        <Route path={`venue/proposal/:proposalId`} element={<EventHeaderIconsSlot />} />
                        <Route path={`venue/inquiries/:id`} element={<EventHeaderIconsSlot />} />
                        <Route path={`venue/*`} element={<EventHeaderIconsSlot />} />
                        <Route path="*" element={!loading ? <EventRightContent /> : null} />
                    </Routes>
                </Right>
            </Header>

            <HeaderShadow pageOffset={pageOffset} maxSearchWidth={maxSearchWidth} />
            <EventHeaderBannerSlot />
        </>
    );
};

export default EventHeader;
