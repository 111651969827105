import PlaybookBasicDetails from 'components/Playbook/PlaybookBasicDetails';
import { SpinnerOverlay } from 'components/Spinner';
import { H3Headline } from 'components/ui/Headline';
import isEqual from 'lodash/isEqual';
import PlaybookCollaboration from 'pages/Playbook/components/PlaybookCollaboration';
import PlaybookInquiry from 'pages/Playbook/components/PlaybookInquiry';
import PlaybookInteralDetails from 'pages/Playbook/components/PlaybookInternalDetails';
import PlaybookParcels from 'pages/Playbook/components/PlaybookParcels';
import PlaybookVenueFilters from 'pages/Playbook/components/PlaybookVenueFilters';
import React from 'react';
import {
    LoadPlaybookCollaborators,
    LoadPlaybookResources,
    selectCollaborators,
    selectPlaybook,
    selectResources,
    usePlaybooks,
} from 'stores/playbooks';
import { i18n } from 'translation';
import { Column, Spacer } from 'ui';
import PlaybookAgenda from './PlaybookAgenda';

export default function PlaybookDetails({ playbookId }: { playbookId: string | number }) {
    const playbook = usePlaybooks(selectPlaybook(playbookId));
    const collaborators = usePlaybooks(selectCollaborators(playbookId));
    const resources = usePlaybooks(selectResources(playbookId));

    const hasCollaboration = (collaborators && collaborators.length > 0) || (resources && resources.length > 0);
    const hasFilters = !isEqual(playbook?.venueSearchParameters, {
        radius: 24141,
        radiusKm: 24,
    });

    if (!playbook) return <SpinnerOverlay />;

    return (
        <Column itemSpacing="large">
            <PlaybookBasicDetails playbook={playbook} />
            <PlaybookAgenda playbookId={playbookId} />
            <LoadPlaybookCollaborators id={playbookId} />
            <LoadPlaybookResources id={playbookId} />

            <PlaybookInteralDetails playbook={playbook} />
            {hasFilters && (
                <Column>
                    <H3Headline>{i18n.playbookWizard.preview.venueSearchFilters}</H3Headline>
                    <Spacer default />
                    <PlaybookVenueFilters playbook={playbook} />
                </Column>
            )}
            <Column itemSpacing="xsmall">
                <H3Headline>{i18n.playbookWizard.preview.inquiry}</H3Headline>
                <PlaybookInquiry playbookId={playbookId} />
            </Column>
            <Column itemSpacing="xsmall">
                <H3Headline>{i18n.playbookWizard.preview.communications}</H3Headline>
                <PlaybookParcels playbookId={playbookId} showCreateButton={false} />
            </Column>
            {hasCollaboration && (
                <Column itemSpacing="large">
                    <H3Headline>{i18n.playbookWizard.preview.collaboration}</H3Headline>
                    <PlaybookCollaboration playbookId={playbookId} />
                </Column>
            )}
        </Column>
    );
}
