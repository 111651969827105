import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useSnackbar } from 'notistack';
import {
    copyRegistrationSlugToClipboard,
    HOTEL_CONF,
    INVITE,
    NOTE,
    REGISTRATION_PAGE,
    SURVEY,
} from 'pages/EditParcel/utils';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import EnvelopeIconSVG from '../../images/icons/envelope.svg?react';
import EventRegistrationIconSVG from '../../images/icons/event-registration.svg?react';
import NoteIconSVG from '../../images/icons/note.svg?react';
import { Column, Copy, Matchbox, Row, SpacedColumn } from '../../ui';
import { parcelType } from '../../util';

const EnvelopeIcon = styled(EnvelopeIconSVG).attrs(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.pureWhite),
}))``;
const EventRegistrationIcon = styled(EventRegistrationIconSVG).attrs(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.pureWhite),
}))``;
const NoteIcon = styled(NoteIconSVG).attrs(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.pureWhite),
}))``;

const Status = styled.span<{ sent?: boolean }>(({ sent, theme: { getColor, EColors } }) => ({
    fontSize: '12px',
    marginTop: '8px',
    color: sent ? 'inherit' : getColor(EColors.warningText),
}));

const OverflowText = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ParcelMatchbox = ({
    onCopy,
    onDelete,
    editable,
    parcel,
    readonly,
    editParcelLink,
    eventId,
}: {
    parcel: Bizly.Parcel;
    onCopy: (parcel: Bizly.Parcel) => void;
    onDelete: (parcel: Bizly.Parcel) => void;
    editable: boolean;
    playbookId?: number | string;
    readonly?: boolean;
    editParcelLink: string;
    eventId?: number;
}) => {
    const [menuAnchor, setMenuAnchor] = React.useState(null);
    const handleMenuClose = () => setMenuAnchor(null);
    const { enqueueSnackbar } = useSnackbar();

    const currentParcelType = parcelType(parcel);
    const isPublished = !Array.isArray(parcel?.traits) && parcel?.traits?.public?.registrationPage?.published;
    const isSubmitted =
        ((currentParcelType === INVITE ||
            currentParcelType === NOTE ||
            currentParcelType === SURVEY ||
            currentParcelType === HOTEL_CONF) &&
            parcel.sent) ||
        (currentParcelType === REGISTRATION_PAGE && isPublished) ||
        false;

    return (
        <Matchbox>
            <Link to={editParcelLink}>
                <MatchboxTop submitted={isSubmitted}>
                    {/* Thumb icon */}
                    <>
                        {(currentParcelType === INVITE || currentParcelType === HOTEL_CONF) && <EnvelopeIcon />}
                        {currentParcelType === REGISTRATION_PAGE && <EventRegistrationIcon />}
                        {(currentParcelType === NOTE || currentParcelType === SURVEY) && <NoteIcon />}
                    </>

                    {/* Do not show subject for hotel confirmation emails */}
                    {currentParcelType !== HOTEL_CONF && <OverflowText>{parcel.subject}</OverflowText>}
                </MatchboxTop>
            </Link>
            <MatchboxBottom>
                <BottomSpacedRow>
                    <Column>
                        {currentParcelType === REGISTRATION_PAGE ? (
                            // Hides recipients for registration
                            <Copy small>&nbsp;</Copy>
                        ) : (
                            <span>{i18n.communication.recipientCount(parcel.recipients?.length)}</span>
                        )}
                        {readonly ? (
                            <Copy small>&nbsp;</Copy>
                        ) : (
                            <Status sent={!!parcel.sent || !!isPublished}>
                                {isSubmitted
                                    ? currentParcelType === REGISTRATION_PAGE
                                        ? i18n.communication.launched
                                        : i18n.communication.sent
                                    : parcel.sendAt
                                      ? i18n.communication.scheduled
                                      : i18n.communication.draft}
                            </Status>
                        )}
                    </Column>
                    {editable && !readonly && (
                        <>
                            <MoreHorizIcon
                                style={{ cursor: 'pointer' }}
                                onClick={e => setMenuAnchor(menuAnchor ? null : (e.target as any))}
                            />
                            <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleMenuClose}>
                                <Link to={editParcelLink}>
                                    <MenuItem onClick={() => handleMenuClose()}>
                                        {parcel.sent || isPublished ? i18n.button.view : i18n.button.edit}
                                    </MenuItem>
                                </Link>
                                {currentParcelType === REGISTRATION_PAGE && isPublished && (
                                    <MenuItem
                                        onClick={async () => {
                                            handleMenuClose();
                                            await copyRegistrationSlugToClipboard({
                                                parcel,
                                                eventId,
                                            });
                                            enqueueSnackbar(i18n.communication.copiedURL, {
                                                variant: 'success',
                                            });
                                        }}
                                    >
                                        {i18n.button.copyUrl}
                                    </MenuItem>
                                )}
                                {(currentParcelType === INVITE ||
                                    currentParcelType === NOTE ||
                                    currentParcelType === SURVEY) && (
                                    <MenuItem
                                        onClick={() => {
                                            handleMenuClose();
                                            onCopy(parcel);
                                        }}
                                    >
                                        {i18n.button.copy}
                                    </MenuItem>
                                )}
                                {!parcel.sent && !isPublished && (
                                    <MenuItem
                                        onClick={() => {
                                            handleMenuClose();
                                            onDelete(parcel);
                                        }}
                                    >
                                        {i18n.button.delete}
                                    </MenuItem>
                                )}
                            </Menu>
                        </>
                    )}
                </BottomSpacedRow>
            </MatchboxBottom>
        </Matchbox>
    );
};

const MatchboxTop = styled(SpacedColumn)<{ thumb?: boolean; submitted: boolean }>`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    ${props =>
        props.thumb
            ? `
        padding: 25px;
    `
            : `
        padding: 12px;
        height: 126px;
    `}
    border-radius: 4px 4px 0 0;
    background-color: ${({ submitted, theme: { getColor, EColors } }) =>
        submitted ? getColor(EColors.parcelInviteSent) : getColor(EColors.parcelInvite)};

    transition: background-color 0.15s ease-in-out;

    ${Matchbox}:hover & {
        background-color: ${({ submitted, theme: { getColor, EColors } }) =>
            submitted ? getColor(EColors.parcelInviteSentHover) : getColor(EColors.parcelInviteHover)};
    }
`;

const MatchboxBottom = styled(SpacedColumn)<{ thumb?: boolean }>`
    ${props =>
        props.thumb
            ? `
        padding: 12px 0;
    `
            : `
        padding: 12px;
    `}
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    border-radius: 0 0 4px 4px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const BottomSpacedRow = styled(Row)`
    justify-content: space-between;
    align-items: center;
`;

export default ParcelMatchbox;

const MatchboxThumb = styled(Matchbox).attrs({ flat: true })``;

const ThumbType = styled.span`
    line-height: 15px;
    font-size: 15px;
    margin-top: 12px;
`;
const ThumbStatus = styled.span<{ sent?: boolean; center: boolean }>`
    font-size: 15px;
    line-height: 15px;
    ${({ sent, theme: { getColor, EColors } }) =>
        sent ? `color: ${getColor(EColors.snackbarSuccess)};` : 'color: inherit;'}
    text-align: ${({ center }) => (center ? 'center' : 'left')};
`;

export const ParcelMatchboxThumb = ({
    parcel,
    eventId,
    className,
}: {
    parcel: Bizly.Parcel;
    eventId?: string;
    className?: string;
}) => {
    const LinkOrFragment = eventId ? Link : React.Fragment;
    const currentParcelType = parcelType(parcel);
    //TODO: Does this get used in a place where we need to adapt this link off for Playbooks?
    const isPublished = !Array.isArray(parcel?.traits) && parcel?.traits?.public?.registrationPage?.published;
    const isSubmitted =
        ((currentParcelType === INVITE ||
            currentParcelType === NOTE ||
            currentParcelType === SURVEY ||
            currentParcelType === HOTEL_CONF) &&
            parcel.sent) ||
        (currentParcelType === REGISTRATION_PAGE && isPublished) ||
        false;

    return (
        <MatchboxThumb className={className}>
            <LinkOrFragment to={`/event/${eventId}/communication/edit/${currentParcelType}/${parcel.id}`}>
                <MatchboxTop thumb alignItems="center" submitted={isSubmitted}>
                    {(currentParcelType === INVITE || currentParcelType === HOTEL_CONF) && <EnvelopeIcon />}
                    {currentParcelType === REGISTRATION_PAGE && <EventRegistrationIcon />}
                    {(currentParcelType === NOTE || currentParcelType === SURVEY) && <NoteIcon />}
                    <ThumbType>
                        {currentParcelType === INVITE && i18n.communication.invite}
                        {currentParcelType === REGISTRATION_PAGE && i18n.communication.event}
                        {(currentParcelType === NOTE ||
                            currentParcelType === SURVEY ||
                            currentParcelType === HOTEL_CONF) &&
                            i18n.communication.note}
                    </ThumbType>
                </MatchboxTop>
                <MatchboxBottom thumb>
                    <ThumbStatus sent={!!parcel.sent} center>
                        {(currentParcelType === INVITE ||
                            currentParcelType === NOTE ||
                            currentParcelType === SURVEY ||
                            currentParcelType === HOTEL_CONF) &&
                        parcel.sent
                            ? i18n.communication.sent
                            : currentParcelType === REGISTRATION_PAGE && isPublished
                              ? i18n.communication.launched
                              : i18n.communication.draft}
                    </ThumbStatus>
                </MatchboxBottom>
            </LinkOrFragment>
        </MatchboxThumb>
    );
};
