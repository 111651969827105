import { APIProvider, AdvancedMarker, Map, useMap } from '@vis.gl/react-google-maps';
import { venueTypesAPIDict } from 'api/venueTypesApi';
import useThemedColor from 'hooks/useThemedColor';
import { useCallback, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { Column, Copy, InlineRow } from 'ui';
import MapPin from '../images/icons/venue_map_pin_nofill.svg?react';

const MapContainer = styled.div`
    border-radius: 8px;
    overflow: hidden;
    height: 400px;
    position: relative;

    .zIndex2 {
        z-index: 2;
    }

    .zIndex1 {
        z-index: 1;
    }
`;

const LegendColumn = styled(Column)`
    margin-top: 20px;
`;

const ColorDot = styled.div<{ color: Themed.Color }>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => props.color};
`;

const bounce = keyframes`
  0%   { transform: scale(1,1)      translateX(-50%)  translateY(calc(-100% + 5px)); }
  10%  { transform: scale(1.1,.9)   translateX(-50%)  translateY(calc(-100% + 5px)); }
  30%  { transform: scale(.9,1.1)   translateX(-50%)  translateY(calc(-100% - 5px)); }
  50%  { transform: scale(1.05,.95) translateX(-50%)  translateY(calc(-100% + 5px)); }
  75% { transform: scale(1,1)      translateX(-50%)  translateY(calc(-100% + 5px)); }
  100% { transform: scale(1,1)      translateX(-50%)  translateY(calc(-100% + 5px)); }
`;

const yourSearchLocation = {
    0: {
        numId: 0,
        id: 'search location',
        label: i18n.venue.types.yourSearchLocation,
        pinColor: EColors.venuePinSearchLocation as EColors,
    },
};
const venueTypes = {
    ...yourSearchLocation,
    ...venueTypesAPIDict(),
};

const venueTypeMap = {} as Record<EColors, Array<string>>;

Object.values(venueTypes).forEach(venueType => {
    if (venueType.pinColor in venueTypeMap) {
        venueTypeMap[venueType.pinColor].push(venueType.label);
    } else {
        venueTypeMap[venueType.pinColor] = [venueType.label];
    }
});

const AnimatedPin = styled(MapPin)<{ highlighted: boolean }>`
    transform: translate(-50%, calc(-100% + 5px));
    animation: ${bounce} 2s cubic-bezier(0.28, 0.84, 0.42, 1) infinite;

    ${props => (props.highlighted ? `` : `animation: unset;`)}
    ${props => (props.color ? `color: ${props.color};` : ``)}
`;

type TProps = {
    center?: { lat: number; lng: number };
    venues?: Bizly.Venue[];
    highlightedVenueId?: number;
    pinHighlightedVenueId?: number;
    onPinClick?: (venueId: number) => void;
    className?: string;
    defaultZoom?: number;
    blackPins?: boolean;
    showLegend?: boolean;
};

const MAP_KEY = import.meta.env.VITE_APP_GMAPS_KEY;

const VenueMap = ({
    center,
    venues = [],
    highlightedVenueId,
    pinHighlightedVenueId,
    className = '',
    onPinClick,
    defaultZoom = 12,
    blackPins = false,
    showLegend = false,
}: TProps) => {
    const venuePinColors = useThemedColor();
    const pureBlack = venuePinColors.pureBlack;
    const map = useMap('venues-map');

    const [venuePins, setVenuePins] = useState<Array<Bizly.VenuePin>>([]);

    useEffect(() => {
        if (!map || venuePins.length <= 1) return;

        const bounds = new google.maps.LatLngBounds();
        for (const latLng of venuePins) {
            bounds.extend(latLng);
        }
        map.fitBounds(bounds);
    }, [venuePins, map]);

    useEffect(() => {
        setVenuePins(
            venues.map(venue => ({
                id: venue.id,
                lat: venue.lat,
                lng: venue.lng,
                type: venue.type,
                isCenter: !!venue.isCenter,
            }))
        );
    }, [venues]);

    useEffect(() => {
        if (!center) return;
        const centerPinExist = venuePins.some(pin => pin?.isCenter);
        if (centerPinExist) {
            return;
        }

        const centerPin = {
            id: 99999,
            lat: center.lat,
            lng: center.lng,
            type: { id: 99999, name: 'Center' },
            isCenter: true,
        };

        setVenuePins(prev => [...prev, centerPin]);
    }, [venuePins, center]);

    useEffect(() => {
        if (!map) return;
        const highlightedVenue = venues.find(p => p.id === highlightedVenueId);
        if (highlightedVenue) {
            const bounds = map.getBounds();
            const { lat, lng } = highlightedVenue;
            if (!bounds?.contains({ lat, lng })) {
                map.moveCamera({
                    center: { lat, lng },
                });
            }
        }
    }, [map, highlightedVenueId, venues]);

    const renderLegend = useCallback(
        () =>
            Object.entries(venueTypeMap).map(([colorKey, venueTypes]) => (
                <InlineRow key={colorKey} alignItems="center" itemSpacing="xsmall">
                    <ColorDot color={venuePinColors[colorKey as EColors]} />
                    <Copy>{venueTypes.join(' / ')}</Copy>
                </InlineRow>
            )),
        [venuePinColors]
    );

    return (
        <Column className={className}>
            <MapContainer>
                <Map id="venues-map" mapId="venues-map" defaultZoom={defaultZoom} defaultCenter={center}>
                    {venuePins.map(({ id, lat, lng, type, isCenter }) => {
                        const color =
                            blackPins || isCenter
                                ? pureBlack
                                : id === pinHighlightedVenueId
                                  ? venuePinColors.venuePinHighlight
                                  : venuePinColors[venueTypesAPIDict()[type?.id].pinColor];
                        return (
                            <AdvancedMarker key={id} position={{ lat, lng }} onClick={() => onPinClick?.(id)}>
                                <AnimatedPin
                                    key={id}
                                    color={color}
                                    highlighted={false}
                                    onClick={() => onPinClick?.(1)}
                                />
                            </AdvancedMarker>
                        );
                    })}
                </Map>
            </MapContainer>
            {showLegend && <LegendColumn itemSpacing="xsmall">{renderLegend()}</LegendColumn>}
        </Column>
    );
};

const MapProvider = (props: TProps) => {
    return (
        <APIProvider apiKey={MAP_KEY}>
            <VenueMap {...props} />
        </APIProvider>
    );
};

export default MapProvider;
