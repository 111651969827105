import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const GridContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
}));
