import Grid from '@material-ui/core/Grid';
import { getVenueInquiries } from 'api';
import { SpinnerOverlay } from 'components/Spinner';
import { sortGalleryInquiries } from 'components/VenueInquiry/utils';
import DashboardVenueTile from 'components/VenueTile/DashboardVenueTile';
import TextButton from 'components/ui/Button/TextButton';
import { Headline, IconHeadline } from 'components/ui/Headline';
import { useAsync } from 'hooks/useAsync';
import VenuePinIconSVG from 'images/icons/venue_pin.svg?react';
import React from 'react';
import { withIconStyles } from 'shared';
import styled from 'styled-components';
import { EColors, getColor } from 'theme';
import { i18n } from 'translation';
import { AlignedRow, Column } from 'ui';
import { VenueTiles } from './VenueTiles';
import { DEFAULT, HAS_BOOKED, HAS_INQUIRIES, NO_CITY, TBookedStatus } from './utils';

const VenuePinIcon = withIconStyles(VenuePinIconSVG);

const CardColumn = styled(Column)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
`;

const SectionTitle = styled(Headline)`
    margin-top: 16px;
    margin-bottom: 16px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.totalAttendee)};
`;

const GridContainer = styled(Grid)`
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    &.MuiGrid-container {
        display: grid;
    }
`;

const VenueSection = ({
    suggestedVenues,
    eventId,
    location,
    viewVenueListing,
    viewProposal,
    onSearch,
    onEditInquiries,
}: Partial<Bizly.Event> & {
    eventId: number;
    eventName: string;
    suggestedVenues: Bizly.Venue[];
    viewVenueListing: (venueId: number) => void;
    viewProposal: (venue?: Bizly.Venue) => void;
    onSearch: () => void;
    onEditInquiries: () => void;
}) => {
    const { data: inquiries, loading: loadingInquiries } = useAsync(
        React.useCallback(() => getVenueInquiries(eventId), [eventId])
    );

    const bookedInquiry = inquiries?.find(inquiry => inquiry.booked);

    let bookedState: TBookedStatus;

    const hasInquiryVenues = inquiries && inquiries.some(inquiry => inquiry.venue && inquiry.status !== 'Added');

    if (hasInquiryVenues) {
        bookedState = bookedInquiry ? HAS_BOOKED : HAS_INQUIRIES;
    } else {
        bookedState = location ? DEFAULT : NO_CITY;
    }

    const venues = hasInquiryVenues
        ? inquiries?.map(inquiry => ({ status: inquiry.status, ...inquiry.venue }))
        : location
          ? suggestedVenues
          : [];

    const renderVenueCards = () =>
        bookedInquiry ? (
            <DashboardVenueTile
                key={bookedInquiry.venue.id}
                venue={{ ...bookedInquiry.venue, status: bookedInquiry.status }}
                format="proposalThumb"
                pillType="inquiryStatus"
                fixedRatio="56%"
                width="100%"
                onClick={viewVenueListing}
            />
        ) : (
            venues && (
                <VenueTiles
                    venues={sortGalleryInquiries(venues) || []}
                    bookedState={bookedState}
                    viewVenueListing={viewVenueListing}
                    placeholderBackground={getColor(EColors.imagePlaceholderBackground)}
                />
            )
        );

    if (loadingInquiries) {
        return (
            <Column>
                <IconHeadline icon={<VenuePinIcon />} headline="Venue" description={''} />
                <SpinnerOverlay transparent />
            </Column>
        );
    }

    return (
        <CardColumn>
            <AlignedRow justifyContent="space-between">
                <SectionTitle thin>{i18n.meetingDashboard.venueSection.venue}</SectionTitle>
                {bookedState === HAS_BOOKED ? (
                    <TextButton onClick={() => viewProposal(bookedInquiry)}>
                        {i18n.venue.inquiry.viewProposalDetails}
                    </TextButton>
                ) : bookedState === HAS_INQUIRIES ? (
                    <TextButton onClick={() => onEditInquiries()}>{i18n.venue.inquiry.viewInquiryDetails}</TextButton>
                ) : (
                    <TextButton onClick={() => onSearch()}>{i18n.meetingDashboard.venueSection.findVenue}</TextButton>
                )}
            </AlignedRow>

            <GridContainer container justify="center">
                {renderVenueCards()}
            </GridContainer>
        </CardColumn>
    );
};

export default VenueSection;
