import Grid from '@material-ui/core/Grid';
import VirtualMeetingField from 'components/Schedule/VirtualMeetingField';
import { Spinner } from 'components/Spinner';
import VendorTile from 'pages/Vendors/components/VendorTile';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAddonsByCategory, useAddons } from 'stores/addons';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Spacer } from 'ui';
import { sortVendors } from '../utils';

const Main = styled(Grid)`
    flex: 1;
`;

const FillTile = styled(VendorTile)`
    flex: 1;
`;

const GridItem = styled(Grid)`
    display: flex;
    flex-direction: column;
    min-height: 135px;
`;

export default function VendorsSummary() {
    const { user } = useUser();
    const { team } = user;
    const { event } = useEvent();
    const { loadingCatalog, loadingSelected, selectedAddonsById } = useAddons();
    const navigate = useNavigate();
    const location = useLocation();

    const vendors = useAddons(getAddonsByCategory('virtual_meeting_service_provider'));
    const preferredVendorIds =
        team?.virtualMeetingServiceProviders.filter(provider => provider.preferred).map(provider => provider.id) ?? [];

    const goToVendor = (vendorId: number) => {
        navigate(
            {
                pathname: `/event/${event.id}/virtual/${vendorId}`,
            },
            {
                state: location.state,
            }
        );
    };

    const isPreferred = (vendor: BizlyAPI.Addon) => {
        return preferredVendorIds.some(id => id === vendor.virtualMeetingServiceProvider?.id);
    };
    const showPreferredAddOnsOnly = user.team?.showPreferredAddOnsOnly || false;

    const isSelected = (vendor: BizlyAPI.Addon) => {
        return !!selectedAddonsById[vendor.id];
    };

    const sortedVendors = sortVendors(vendors, isPreferred, isSelected, showPreferredAddOnsOnly);

    const topFour = sortedVendors.slice(0, 4);

    const selectedVendor = Object.values(selectedAddonsById)[0];

    return (
        <Main container spacing={2}>
            {loadingCatalog || loadingSelected ? (
                <Column fillWidth>
                    <Spacer large />
                    <Spinner />
                </Column>
            ) : selectedVendor ? (
                <Column fillWidth itemSpacing="small">
                    <FillTile
                        vendor={selectedVendor}
                        team={team}
                        selected
                        onClick={() => goToVendor(selectedVendor.id)}
                        width="100%"
                    />
                    <VirtualMeetingField
                        value={event.virtualMeeting ?? null}
                        integratedVmServices={[]}
                        onAdd={async () => {}}
                        onDelete={() => {}}
                        disabled
                        cta={i18n.meetingDashboard.vendorsSection.addVirtualMeeting}
                        regularButtonStyle
                        noIcon
                        noPadding
                    />
                </Column>
            ) : (
                topFour.map((vendor, itx) => (
                    <GridItem item xs={6} key={`${vendor.id}-${itx}`}>
                        <VendorTile
                            vendor={vendor}
                            team={user.team}
                            onClick={() => goToVendor(vendor.id)}
                            selected={loadingSelected ? false : vendor.id in selectedAddonsById}
                        />
                    </GridItem>
                ))
            )}
        </Main>
    );
}
