import * as Sentry from '@sentry/react';
import { getVenueInquiries } from 'api';
import { getBudgetApprovalRequests, getCurrentInquiry } from 'api/inquiry';
import { APISort, SearchMeetingsResponse, getMeeting, searchMeetings } from 'api/meetings';
import { GET_CURRENT_INQUIRY, GET_EVENT, GET_VENUE_INQUIRIES, SEARCH_MEETINGS } from 'constants/queryKeys';
import { useQuery } from 'react-query';
import { meetingsActions, selectActiveMeetings, useMeetings } from 'stores/meetings';

export const useActiveMeetings = ({ limit, orderBy }: { limit?: number; orderBy?: BizlyAPI.MeetingSort }) => {
    const meetingsState = useMeetings();

    const result = useQuery({
        queryKey: ['activeMeetings', limit, orderBy],
        queryFn: () => meetingsActions.loadActive({ limit, orderBy }),
        select: data => {
            if (!data) {
                console.warn('No data returned from meetingsActions.loadActive');
                return [];
            }

            const stateData = selectActiveMeetings()(meetingsState);

            return data.map(meeting => ({
                ...meeting,
                ...stateData.find(m => m.id === meeting.id),
            }));
        },
        onError: (error: Error) => {
            console.error('Error fetching active meetings:', error);

            Sentry.captureException(error, {
                tags: {
                    component: 'useActiveMeetings',
                },
                extra: {
                    limit,
                    orderBy,
                },
            });
        },
    });

    return {
        ...result,
        activeMeetings: result?.data,
    };
};

export const useBudgetApprovalRequests = (all: boolean) => {
    const result = useQuery({
        queryKey: ['budgetApprovalRequests', all],
        queryFn: () => getBudgetApprovalRequests(all),
        select: data => ({
            budgetApprovalRequests: data?.budgetApprovalRequests ?? [],
            success: data?.success ?? false,
        }),
        onError: (error: Error) => {
            console.error('Error fetching budget approval requests:', error);

            Sentry.captureException(error, {
                tags: { component: 'useBudgetApprovalRequests' },
                extra: { all },
            });
        },
    });

    return {
        ...result,
        budgetApprovalRequests: result.data?.budgetApprovalRequests ?? [],
        success: result.data?.success ?? false,
    };
};

type MeetingFilters = {
    query: string;
    page?: number;
    limit?: number;
    orderBy?: APISort;
};
export const useSearchMeetingsQuery = ({ query, page = 1, limit = 100, orderBy }: MeetingFilters) => {
    return useQuery<SearchMeetingsResponse, Error>(
        [SEARCH_MEETINGS, query, page, limit, orderBy],
        () => searchMeetings(query, page, limit, orderBy),
        {
            refetchOnWindowFocus: false,
        }
    );
};

export const useGetCurrentInquiry = (eventId?: number) => {
    return useQuery([GET_CURRENT_INQUIRY, eventId], () => getCurrentInquiry(eventId as number), {
        enabled: !!eventId,
        refetchOnWindowFocus: false,
    });
};

export const useGetEvent = (eventId?: number) => {
    return useQuery([GET_EVENT, eventId], () => getMeeting(eventId as number), {
        enabled: !!eventId,
        refetchOnWindowFocus: false,
    });
};

export const useGetVenueInquiries = (eventId?: number) => {
    return useQuery([GET_VENUE_INQUIRIES, eventId], () => getVenueInquiries(eventId as number), {
        enabled: !!eventId,
        refetchOnWindowFocus: false,
    });
};
