import { Autocomplete, Box, Divider, FormControl, Paper, styled, TextField, Tooltip, Typography } from '@mui/material';
import { MAX_INQUIRY_VENUE_COUNT } from 'constants/inquiry';
import { useEventsQuery } from 'hooks/queries/useEventsQuery';
import { useGetEvent, useGetVenueInquiries } from 'hooks/queries/useQueries';
import debounce from 'lodash/debounce';
import { EventFormFields, EventFormValues } from 'pages/Venues/Venues';
import { HTMLAttributes, ReactNode, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import useEventsFiltersStore from 'stores/events/eventsStore';
import { i18n } from 'translation';
import { isUrlFromSubNav } from 'utils/venue';
import LabeledContent from './LabeledContent';
import { Button } from './Ui-V2/Button/Button';
import { Spacer } from './Ui-V2/Spacer/Spacer';

const ButtonContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
`;

const ParentBox = styled(Box)({
    borderRadius: '0.5rem',
    border: '0.0625rem solid #e0e0e0',
    overflow: 'hidden',
});

const BorderedBox = styled(Box)({
    padding: '0.5rem',
});

const FormHeading = styled(Box)`
    display: flex;
    gap: 0.8rem;
    flex-direction: column;
    margin-bottom: 0.7rem;

    .MuiTypography-root {
        font-weight: 600;
    }
`;

const StyledPaper = styled(Paper)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 18.75rem;
    margin: auto;
    padding: 1.5rem;
    border: 0.0625rem solid #ddd;
    border-radius: 0.25rem;
`;
type EventInquiryFormProps = {
    showCreateEventModal?: () => void;
    venueAction?: ReactNode;
};
const EventInquiryForm = (props: EventInquiryFormProps) => {
    const { showCreateEventModal, venueAction } = props;
    const {
        control,
        watch,
        formState: { isDirty, isValid },
    } = useFormContext<EventFormValues>();

    const location = useLocation();
    const { eventId } = useParams();
    const isSubNav = isUrlFromSubNav(location.pathname);

    const eventIdRHF = watch(EventFormFields.EVENT)?.id;

    const [selectedEvent, setSelectedEvent] = useState<Bizly.Event | null>(null);
    const disableAddToInquryButton = !isDirty || !isValid;
    const hasChosenEvent = !!watch(EventFormFields.EVENT);

    const { filters, setFilters } = useEventsFiltersStore();

    const { data: eventsData, isLoading: isLoadingEvents } = useEventsQuery({ ...filters, editable: true });

    const filteredEvents = useMemo(
        () =>
            eventsData?.filter(
                event => event.status?.code === 'inquiry_draft' || event.status?.code === 'new' //TODO: should there be a way to be filtered in the backend
            ) || [],
        [eventsData]
    );

    const { data: eventData, isLoading: isLoadingEventData } = useGetEvent(Number(eventId));
    const { data: venueInquiries, isLoading: isLoadingVenueInquiries } = useGetVenueInquiries(
        Number(eventId) || Number(eventIdRHF)
    );

    const hasMaxInquiries = (venueInquiries?.length ?? 0) >= MAX_INQUIRY_VENUE_COUNT;

    const handleEventInputChange = debounce((queryValue: string) => {
        setFilters({ query: queryValue });
    }, 300);

    const handleEventSelect = (event: Bizly.Event | null) => {
        setSelectedEvent(event);
    };

    const renderButtonOrVenueAction = () => {
        if (isLoadingVenueInquiries || isLoadingEventData) {
            return (
                <Button type="button" variant="contained" color="primary" fullWidth disabled>
                    Loading
                </Button>
            );
        }

        if (hasMaxInquiries) {
            return (
                <Tooltip title={`${MAX_INQUIRY_VENUE_COUNT} venues already selected`}>
                    <Box>
                        <Button variant="contained" color="primary" fullWidth disabled>
                            {i18n.venue.addToInquiry}
                        </Button>
                    </Box>
                </Tooltip>
            );
        }

        if (disableAddToInquryButton && !isSubNav) {
            return (
                <Button type="submit" variant="contained" color="primary" fullWidth disabled>
                    {i18n.venue.addToInquiry}
                </Button>
            );
        }
        return <div>{venueAction}</div>;
    };

    const formatDate = (dateString: string): string => {
        if (!dateString) return 'No dates added';

        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        });
    };

    const renderMeetingOption = (props: HTMLAttributes<HTMLLIElement>, option: Bizly.Event) => (
        <li {...props} key={option.id}>
            <div>
                <Typography fontWeight={600}>{option.name}</Typography>
                <Typography fontSize="small" color="textSecondary">
                    {formatDate(option.startsAt || '')} - {formatDate(option.endsAt || '')}
                </Typography>
            </div>
        </li>
    );

    return (
        <StyledPaper elevation={0}>
            <FormHeading>
                <Typography>Add to Event Inquiry</Typography>
                <Divider />
            </FormHeading>

            <form style={{ display: 'flex', flexDirection: 'column' }}>
                {eventData && <TextField label="Event" value={eventData?.meeting?.name} />}
                {!eventData && (
                    <FormControl fullWidth>
                        <Controller
                            name={EventFormFields.EVENT}
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    value={
                                        eventsData?.find(
                                            event => event.name === (field.value as unknown as Bizly.Event)?.name
                                        ) ?? undefined
                                    }
                                    loading={isLoadingEvents || isLoadingEventData}
                                    loadingText="Loading..."
                                    options={filteredEvents || []}
                                    getOptionLabel={option => option.name || ''}
                                    renderOption={renderMeetingOption}
                                    renderInput={params => <TextField {...params} label="Event" />}
                                    onChange={(_, value) => {
                                        field.onChange(value);
                                        handleEventSelect(value);
                                    }}
                                    onInputChange={(_, queryValue) => handleEventInputChange(queryValue)}
                                />
                            )}
                        />
                    </FormControl>
                )}

                {!isDirty ||
                    (hasChosenEvent && selectedEvent && (
                        <>
                            <Spacer small />
                            <ParentBox>
                                <BorderedBox>
                                    <LabeledContent label="Location" value1={selectedEvent.location || ''} />
                                </BorderedBox>
                                <Divider />
                                <BorderedBox>
                                    <LabeledContent
                                        label="Dates"
                                        value1={formatDate(selectedEvent?.startsAt || '')}
                                        value2={formatDate(selectedEvent?.endsAt || '')}
                                    />
                                </BorderedBox>
                            </ParentBox>
                        </>
                    ))}

                <ButtonContainer>
                    <Spacer small />
                    {renderButtonOrVenueAction()}
                    {!isSubNav && (
                        <Button onClick={showCreateEventModal} variant="outlined" color="primary" fullWidth>
                            {i18n.venue.createNewEvent}
                        </Button>
                    )}
                </ButtonContainer>
            </form>
        </StyledPaper>
    );
};

export default EventInquiryForm;
