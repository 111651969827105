import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const StepContainer = styled(Box)({
    overflowX: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
});

export const Title = styled(Typography)(({ theme }) => ({
    fontWeight: '500',
    fontSize: '1.75em',
    marginBottom: theme.spacing(1.5),
}));

export const Description = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
}));

export const VerbiageAlert = styled(Alert)(({ theme: { getColor, EColors } }) => ({
    '&.MuiPaper-root': {
        width: 'auto',
        height: '2.25rem',
        borderRadius: '0.313rem',
        margin: '1.25rem 0',
        justifyContent: 'left',
        backgroundColor: '#E1ECF8',

        '& .MuiAlert-icon': {
            color: `${getColor(EColors.primaryAction)}`,
        },
    },
}));
