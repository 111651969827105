import { isBlankTemplateId } from 'api/eventTemplates';
import colorFns from 'colorFns';
import { EventSettingsFields } from 'components/Events/EventSettingsHeaderSection';
import Form from 'components/Form';
import { getPassthroughProps } from 'components/Form/utils';
import { INQUIRY_BUILDER_PATH } from 'components/MeetingsNav';
import PlaceInput from 'components/PlaceInput';
import { Spinner } from 'components/Spinner';
import Button from 'components/ui/Button';
import { useEvent } from 'providers/event';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { NEW_INQUIRY_ID, useCurrentInquiry } from 'stores/current-inquiry';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Copy, RichTextCopy } from 'ui';
import { timeOptions } from 'utils/date_util';
import { tzMoment } from 'utils/moment';

export type City = {
    location: Bizly.Event['location'];
    googlePlaceId: Bizly.Event['googlePlaceId'];
};

type TCityField = {
    field: string;
    defaultValue: City;
    error: object | null;
    onChange: ({ value, field, error }: { value: City; field: string; error: object | null }) => void;
};

const CityField = ({ field, onChange: onChangeProp, defaultValue }: TCityField) => (
    <PlaceInput
        asFormField
        inModalOrPopover
        onChange={(location: string, googlePlaceId: string) =>
            onChangeProp({
                field,
                error: {},
                value: {
                    location,
                    googlePlaceId,
                },
            })
        }
        defaultValue={defaultValue?.location}
        defaultValueId={defaultValue?.googlePlaceId}
    />
);

const DisabledDateNoteLink = styled(Link)`
    color: ${colorFns.primaryAction};
    hover: ${colorFns.primaryActionHover};
`;

const DisabledDateNote = () => {
    const { event } = useEvent();
    const { inquiry, loaded } = useCurrentInquiry();
    const inquiryId = useMemo(() => {
        if (loaded) {
            return String(inquiry?.id || NEW_INQUIRY_ID);
        }
        return '';
    }, [inquiry, loaded]);

    return (
        <Copy small>
            {i18n.meetingsPage.dateDisabled.leadingText}
            <DisabledDateNoteLink
                to={
                    inquiryId
                        ? INQUIRY_BUILDER_PATH.replace(':eventId', String(event.id)).replace(':inquiryId', inquiryId)
                        : '#'
                }
            >
                {i18n.meetingsPage.sideNav.inquiryBuilder}
            </DisabledDateNoteLink>
            {i18n.common.period}
        </Copy>
    );
};

export type EventForm = Partial<
    {
        city: City;
        templateId: string | number;
        cventEventId: string | number;
        startDate: string;
        startTime: string;
        endDate: string;
        endTime: string;
    } & Pick<
        Bizly.Event,
        'name' | 'cventId' | 'type' | 'costCenter' | 'budget' | 'description' | 'format' | 'department'
    >
>;

type TemplateOption = Omit<Bizly.EventTemplate, 'name'> & { name?: JSX.Element | string };
export type CventOption = BizlyAPI.CventEvent & { id: string | number; name?: JSX.Element | string };

const AugmentedTemplatesField = ({
    field,
    value,
    onChange,
    options,
    disabled,
    playbookDescription,
}: {
    field: string;
    value: number;
    onChange: (update: { field: string; value: number }) => void;
    options: object[];
    disabled?: boolean;
    playbookDescription?: string;
}) => {
    const fields = {
        [field]: {
            type: 'select',
            disabled,
            options: {
                options,
            },
        },
    };

    const ViewPlaybookField = {
        type: 'display',
        options: {
            label: (
                <Link to={`/playbooks/${value}`}>
                    <Button onClick={() => ({})} width={130}>
                        {i18n.homepage.createMeetingModal.viewPlaybook}
                    </Button>
                </Link>
            ),
        },
        key: 'viewPlaybook',
    };

    const schema = [
        {
            fields: [field, ...(value && !isBlankTemplateId(value) ? [ViewPlaybookField] : [])],
            spacing: false,
        },
    ];

    const { value: valueProp, onChange: onChangeProp } = getPassthroughProps({ field, value, onChange });

    return options.length ? (
        <>
            <Form
                key={`template-${value}`}
                fields={fields}
                schema={schema}
                value={{ ...valueProp, playbookDescription }}
                onChange={onChangeProp}
            />
            {playbookDescription && <RichTextCopy dangerouslySetInnerHTML={{ __html: playbookDescription || '' }} />}
        </>
    ) : (
        <Spinner suppressMargin />
    );
};

const CventSelectField = ({
    field,
    value,
    onChange,
    options,
    disabled,
    loadingCventEvents,
}: {
    field: string;
    value: number;
    onChange: (update: { field: string; value: number }) => void;
    options: object[];
    disabled?: boolean;
    loadingCventEvents: boolean;
}) => {
    const fields = {
        [field]: {
            type: 'select',
            disabled,
            options: {
                options,
            },
        },
    };
    const schema = [
        {
            fields: [field],
            spacing: false,
        },
    ];

    const { value: valueProp, onChange: onChangeProp } = getPassthroughProps({ field, value, onChange });

    return !loadingCventEvents ? (
        <>
            <Form key={'cvent'} fields={fields} schema={schema} value={{ ...valueProp }} onChange={onChangeProp} />
        </>
    ) : (
        <Spinner suppressMargin />
    );
};

export const EventCreateFields = (
    meetingInternalReferenceFieldLabel?: string,
    meetingCostCenterFieldLabel?: string,
    disabledFields?: string[],
    requiredFields?: Bizly.EventCreateInternalFields[],
    templates?: TemplateOption[],
    cvents?: CventOption[],
    city?: City | null,
    playbookDescription?: string,
    budgetRequired?: boolean,
    loadingCventEvents = false,
    costCenterOptions: string[] | null = null,
    departmentOptions: string[] | null = null
) => ({
    ...EventSettingsFields({
        internalReferencePrompt: meetingInternalReferenceFieldLabel,
        meetingCostCenterFieldLabel,
        disabledFields,
        requiredFields,
        costCenterOptions,
        departmentOptions,
    }),
    name: {
        prompt: i18n.homepage.createMeetingModal.meetingName,
        type: 'text',
        disabled: disabledFields?.includes('name'),
        options: {
            autoFocus: true,
        },
    },
    city: {
        prompt: i18n.homepage.createMeetingModal.location,
        type: CityField,
        disabled: disabledFields?.includes('city'),
        ...(city && {
            options: {
                defaultValue: city,
            },
        }),
    },
    cityNote: {
        type: () => <Copy small>{i18n.homepage.createMeetingModal.virtualGatherings}</Copy>,
    },
    budget: {
        prompt: i18n.homepage.createMeetingModal.budget,
        type: 'text',
        optional: !budgetRequired,
        disabled: disabledFields?.includes('budget'),
        perRow: 2,
    },
    templateId: {
        prompt: i18n.homepage.createMeetingModal.playbook,
        type: AugmentedTemplatesField,
        disabled: disabledFields?.includes('templateId'),
        options: {
            options: templates,
            playbookDescription: playbookDescription,
        },
        optional: true,
    },
    format: {
        prompt: i18n.meetingsPage.meetingFormat,
        type: 'radioselect',
        options: {
            options: [i18n.meetingsPage.inPerson, i18n.meetingsPage.hybrid, i18n.meetingsPage.virtual],
        },
        optional: true,
    },
    cventEventId: {
        prompt: i18n.homepage.createMeetingModal.cventEvent,
        type: CventSelectField,
        disabled: disabledFields?.includes('cventEventId'),
        options: {
            options: cvents,
            loadingCventEvents: loadingCventEvents,
        },
        optional: true,
    },
    startDate: {
        prompt: i18n.datetime.startDate,
        type: 'date_outlined',
        disabled: disabledFields?.includes('startDate'),
        options: {
            minDate: Date.now(),
            placeholder: i18n.datetime.startDate,
            format: 'MMM do',
        },
        optional: true,
    },
    startTime: {
        prompt: i18n.datetime.startTime,
        type: 'select',
        disabled: disabledFields?.includes('startTime'),
        options: {
            options: timeOptions,
            placeholder: i18n.datetime.startTime,
            autoFocusOptionKey: '12:00:00',
        },
        optional: true,
    },
    endDate: {
        prompt: i18n.datetime.endDate,
        type: 'date_outlined',
        disabled: disabledFields?.includes('endDate'),
        options: {
            minDate: Date.now(),
            placeholder: i18n.datetime.endDate,
            format: 'MMM do',
        },
        optional: true,
    },
    endTime: {
        prompt: i18n.datetime.endTime,
        type: 'select',
        disabled: disabledFields?.includes('endTime'),
        options: {
            options: timeOptions,
            placeholder: i18n.datetime.endTime,
            autoFocusOptionKey: '12:00:00',
        },
        optional: true,
    },
    disabledDateNote: {
        type: DisabledDateNote,
    },
    description: {
        prompt: i18n.meetingsPage.eventDescription,
        type: 'rich_text',
        optional: true,
        disabled: disabledFields?.includes('description'),
    },
    descriptionNote: {
        type: () => <Copy small>{i18n.meetingsPage.descriptionNote}</Copy>,
    },
});

export const EventCreateSchema = (
    hiddenFields: Bizly.EventCreateInternalFields[] = [],
    includeCventEvents = false,
    hasInquiry = false
) => [
    {
        key: 'nameRow',
        fields: ['name'],
        spacing: 'medium',
    },
    ...(includeCventEvents
        ? [
              {
                  key: 'cventEventId',
                  fields: ['cventEventId'],
                  spacing: 'medium',
              },
          ]
        : []),
    {
        key: 'description',
        fields: ['description'],
        spacing: 'xsmall',
    },
    {
        fields: ['descriptionNote'],
        spacing: 'medium',
    },
    {
        key: 'city',
        fields: ['city'],
        spacing: 'medium',
    },
    {
        key: 'startDateAndTime',
        fields: ['startDate', 'startTime'],
        ...(hasInquiry ? { spacing: 'xsmall' } : { spacing: 'medium' }),
    },
    ...(hasInquiry
        ? [
              {
                  key: 'disabledDateNote',
                  fields: ['disabledDateNote'],
                  spacing: 'medium',
              },
          ]
        : []),
    {
        key: 'endDateAndTime',
        fields: ['endDate', 'endTime'],
        ...(hasInquiry ? { spacing: 'xsmall' } : { spacing: 'medium' }),
    },
    ...(hasInquiry
        ? [
              {
                  key: 'disabledDateNote',
                  fields: ['disabledDateNote'],
                  spacing: 'medium',
              },
          ]
        : []),

    {
        key: 'budgetAndCostCenter',
        fields: ['budget', 'costCenter'].filter(field => !(hiddenFields as string[]).includes(field)),
        spacing: 'medium',
    },
    {
        key: 'cventId',
        fields: ['cventId', 'department'],
        spacing: 'medium',
    },
    {
        key: 'type',
        fields: ['type'],
        spacing: 'medium',
    },
];

export const EventCreateSchemaWithTemplate = (
    hiddenFields?: Bizly.EventCreateInternalFields[],
    includeCventEvents = false,
    hasInquiry = false
) => [
    ...EventCreateSchema(hiddenFields, includeCventEvents, hasInquiry),
    {
        key: 'templateId',
        fields: ['templateId'],
        spacing: 'medium',
    },
];

export const toBizlyEvent = ({ city, startDate, startTime, endDate, endTime, format, ...rest }: EventForm) => ({
    ...rest,
    ...city,
    format: format as BizlyAPI.EventFormat,
    startsAt: `${startDate} ${startTime}`,
    endsAt: `${endDate} ${endTime}`,
});

export const validateForm = (
    { name, cventId, type, costCenter, budget, startDate, startTime, endDate, endTime, city }: EventForm,
    budgetRequired = false,
    requiredFields: Bizly.EventCreateInternalFields[] = []
) => {
    if (!name?.trim()) return i18n.homepage.createMeetingModal.completeRequiredFields;

    if (requiredFields.includes('cventId') && !cventId?.toString().trim())
        return i18n.homepage.createMeetingModal.completeRequiredFields;

    if (requiredFields.includes('type') && !type) return i18n.homepage.createMeetingModal.completeRequiredFields;

    if (requiredFields.includes('costCenter') && !costCenter?.trim())
        return i18n.homepage.createMeetingModal.completeRequiredFields;

    if (budgetRequired && !budget?.trim()) return i18n.homepage.createMeetingModal.completeRequiredFields;

    if (!city?.location || !city?.googlePlaceId) return i18n.homepage.createMeetingModal.completeRequiredFields;

    const startDateTime = tzMoment(`${startDate} ${startTime}`);
    const endDateTime = tzMoment(`${endDate} ${endTime}`);
    if (endDateTime.isBefore(startDateTime)) return i18n.homepage.createMeetingModal.invalidDateRange;

    return '';
};
