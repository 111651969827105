import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { darken, lighten } from '@mui/material/styles';
import colorFns from 'colorFns';
import useShowModal from 'hooks/useShowModal';
import CheckMarkSvg from 'images/icons/check-mark.svg?react';
import CloseIconSvg from 'images/icons/close.svg?react';
import { useSnackbar } from 'notistack';
import { useUser } from 'providers/user';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { withInteractibleIconStyles } from 'shared';
import { LoadTeams, teamsActions, useTeams } from 'stores/team-memberships';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy, CopyFaded, InlineRow, Row } from 'ui';
import { Spinner, SpinnerOverlay } from './Spinner';
import Button from './ui/Button';
import { H2Headline } from './ui/Headline';

const TeamSelection = styled(Row)`
    cursor: pointer;
    background-color: ${({ theme: { getColor, EColors, isThemeBright } }) =>
        isThemeBright ? lighten(getColor(EColors.brand), 0.6) : darken(getColor(EColors.brand), 0.5)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.sidenavForeground)};
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
`;

const UserName = styled.div`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.sidenavForeground)};
    font-weight: 500;
`;

const TeamName = styled.div`
    color: ${({ theme: { getColor, EColors } }) => lighten(getColor(EColors.sidenavForeground), 0.4)};
    font-weight: 400;
`;

const TeamSelectIcon = styled(UnfoldMoreIcon)`
    color: ${colorFns.sidenavForeground};
`;

const PaddedDialog = styled(Dialog)`
    .MuiPaper-root > *:not(:last-child) {
        border-bottom: 1px solid ${colorFns.softBorder};
    }
`;

const ModalContent = styled.div`
    overflow: auto;
    padding: 20px 26px;
    box-sizing: border-box;
    min-width: 460px;
    max-height: 600px;
`;

const Header = styled(Row)`
    padding: 20px;
    box-sizing: border-box;
`;

const CloseIcon = styled(CloseIconSvg)`
    cursor: pointer;
`;

const TeamLogo = styled.img`
    height: 29px;
    width: 29px;

    vertical-align: middle; /* remove white space under img and align it with rest of row https://stackoverflow.com/questions/7774814/remove-white-space-below-image */
`;

const Teams = styled(Column)`
    > *:first-child:not(:last-child) {
        border-bottom: 1px solid ${colorFns.softBorder};
        padding-bottom: 12px;
    }
`;

const RightCheckMark = styled(withInteractibleIconStyles(CheckMarkSvg))``;

const TeamMembership = styled.div``;
const TeamInfo = styled(Row)`
    border-radius: 8px;
    padding: 12px;
    box-sizing: border-box;

    ${({ onClick, ...props }) =>
        onClick
            ? `
    cursor: pointer;

    &:hover {
        background-color: ${colorFns.dropdownItemHover(props)};
    }
    `
            : ''}

    ${RightCheckMark} {
        margin-left: auto;
    }
`;

const MembershipsList = ({
    onSelect,
    onAccept,
    onReject,
    processing,
    memberships,
}: {
    onSelect: (teamId: number) => Promise<void>;
    onAccept: (teamId: number, membershipId: number) => Promise<void>;
    onReject: (teamId: number, membershipId: number) => Promise<void>;
    processing?: boolean;
    memberships: BizlyAPI.TeamMembership[];
}) => {
    return (
        <Teams itemSpacing="small">
            {memberships.map(({ id, role, team, status }) => (
                <TeamMembership key={id}>
                    <TeamInfo
                        alignItems="center"
                        justifyContent="space-between"
                        onClick={team.currentlySelected || status.int !== 1 ? undefined : () => onSelect(team.id)}
                    >
                        <InlineRow alignItems="center" itemSpacing="small">
                            <TeamLogo src={team.imageUrl} />
                            <Column>
                                <Copy>{`${team.name} (#${team.id})`}</Copy>
                                <Copy small>{role.name}</Copy>
                            </Column>
                        </InlineRow>
                        <InlineRow alignItems="center" itemSpacing="small">
                            {status.int === 2 && (
                                <>
                                    <Button onClick={() => onAccept(team.id, id)} disabled={processing}>
                                        {i18n.button.accept}
                                    </Button>
                                    <Button onClick={() => onReject(team.id, id)} warning disabled={processing}>
                                        {i18n.button.reject}
                                    </Button>
                                </>
                            )}
                            {status.int === 4 && <CopyFaded>{i18n.teamSelector.rejectedInvite}</CopyFaded>}
                            {team.currentlySelected && <RightCheckMark />}
                        </InlineRow>
                    </TeamInfo>
                </TeamMembership>
            ))}
        </Teams>
    );
};

export default function TeamSelector({ initOpen, minimized }: { initOpen?: boolean; minimized?: boolean }) {
    const { user, setTeam } = useUser();
    const navigate = useNavigate();
    const location = useLocation();

    const { modalShown, showModal, hideModal } = useShowModal(initOpen);

    const teamsStore = useTeams();
    const { enqueueSnackbar } = useSnackbar();
    const [updating, setUpdating] = React.useState(false);
    const changeTeam = async (teamId: number) => {
        if (!user.id || updating) {
            return;
        }

        try {
            setUpdating(true);
            const updatedUser = await teamsActions.changeTeam(user.id, teamId);
            if (updatedUser?.team) {
                setTeam(updatedUser.team);
            }
            hideModal();
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        } finally {
            setUpdating(false);
        }
    };

    const accept = async (teamId: number, membershipId: number) => {
        if (teamsStore.accepting || teamsStore.rejecting) return;

        try {
            await teamsActions.acceptTeam(teamId, membershipId);
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
    };

    const reject = async (teamId: number, membershipId: number) => {
        if (teamsStore.accepting || teamsStore.rejecting) return;

        try {
            await teamsActions.rejectTeam(teamId, membershipId);
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
    };

    const closeModal = () => {
        const currentPath = location.pathname.split('/');
        if (currentPath[currentPath.length - 1] === 'team-picker') {
            currentPath.pop();
        }
        hideModal();
        navigate(currentPath.join('/'), { replace: true });
    };

    return (
        <>
            <TeamSelection
                onClick={!user.isSsoUser ? showModal : () => navigate('/settings/team')}
                justifyContent={minimized ? 'center' : 'space-between'}
            >
                {!minimized && (
                    <Box display="flex" flexDirection="column" gap={0.5}>
                        <UserName>{`${user.firstName} ${user.lastName}`}</UserName>
                        <TeamName>{user.team?.name}</TeamName>
                    </Box>
                )}
                <TeamSelectIcon />
            </TeamSelection>

            {modalShown && (
                <PaddedDialog open maxWidth="lg" onBackdropClick={closeModal}>
                    {user.id && <LoadTeams userId={user.id} />}
                    <Header>
                        <Row justifyContent="space-between" alignItems="center">
                            <H2Headline>{i18n.teamSelector.switchTeam}</H2Headline>
                            <CloseIcon onClick={closeModal} />
                        </Row>
                    </Header>
                    <ModalContent>
                        {teamsStore.loaded ? (
                            <MembershipsList
                                memberships={teamsStore.memberships}
                                onSelect={changeTeam}
                                onAccept={accept}
                                onReject={reject}
                                processing={teamsStore.accepting || teamsStore.rejecting}
                            />
                        ) : (
                            <Spinner />
                        )}

                        {updating && <SpinnerOverlay />}
                    </ModalContent>
                </PaddedDialog>
            )}
        </>
    );
}
