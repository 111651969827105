export type RequirementType = "Event Space" | "Hotel Room Block" | "Private Dining" | "Office / Coworking";

export const venueTypes: Record<RequirementType, string[]> = {
    "Event Space": [
        'hotel',
        'activity',
        'conference center',
        'bar',
        'brewery',
        'winery',
        'museum',
        'theater',
        'event venue',
        'unique venue'
    ],
    "Hotel Room Block": ['hotel'],
    "Private Dining": ['restaurant', 'bar', 'winery', 'brewery'],
    "Office / Coworking": [
        'professional workspace',
        'coworking space',
        'corporate office',
        'bookable office'
    ]
};
