import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BizlyOSSideNav, HEADER_HEIGHT } from 'components/BizlyOS/BizlyOSSideNav';
import CollapseIcon from 'images/bizlyOS/header/collapse-icon.svg?react';
import ExpandIcon from 'images/bizlyOS/header/expand-icon.svg?react';
import BizlyLogo from 'images/logos/bizly.svg?react';
import { Analytics } from 'pages/BizlyOS/Analytics/Analytics';
import { Calendar } from 'pages/BizlyOS/Calendar/Calendar';
import { Messages } from 'pages/BizlyOS/Messages/Messages';
import { ProposalInquiries } from 'pages/BizlyOS/Proposals/ProposalInquries';
import { Proposals } from 'pages/BizlyOS/Proposals/Proposals';
import { OSLayoutProvider, useOSLayout } from 'providers/bizly-os';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';

interface BizlyOSHeaderProps {
    toggleSideNav: () => void;
    isCollapsed: boolean;
}

const StyledHeader = styled('header')(({ theme: { getColor, EColors } }) => ({
    width: '100%',
    backgroundColor: getColor(EColors.pureBlack),
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    padding: '0.625rem 1.25rem',
    height: HEADER_HEIGHT,
    boxSizing: 'border-box',
}));

const BizlyOSHeader = ({ toggleSideNav, isCollapsed }: BizlyOSHeaderProps) => (
    <StyledHeader>
        <IconButton onClick={toggleSideNav} sx={{ marginRight: '1rem' }}>
            {isCollapsed ? <ExpandIcon /> : <CollapseIcon />}
        </IconButton>
        <BizlyLogo />
    </StyledHeader>
);

const MainContent = styled(Box)({
    flexGrow: 1,
    overflow: 'auto',
    overflowX: 'hidden',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
});

export const ProposalRedirect = () => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const venueId = params.get('venueId');
    const { proposalId } = useParams<{ proposalId: string }>();

    if (venueId && proposalId) {
        return <Navigate to={`/os/venues/${venueId}/proposals/${proposalId}`} replace />;
    }

    return <Navigate to="/os/inquiries" replace />;
};

export const BizlyOSLayout = () => {
    const { navCollapsed, setNavCollapsed } = useOSLayout();

    const toggleSideNav = () => {
        setNavCollapsed(!navCollapsed);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <BizlyOSHeader toggleSideNav={toggleSideNav} isCollapsed={navCollapsed} />

            <Box sx={{ display: 'flex', flexGrow: 1, pt: `${HEADER_HEIGHT}px` }}>
                <BizlyOSSideNav isCollapsed={navCollapsed} />

                <MainContent>
                    <Routes>
                        <Route path="os" element={<Analytics />} />
                        <Route path="inquiries" element={<ProposalInquiries />} />
                        <Route path="venues/:venueId/proposals/:proposalId" element={<Proposals />} />
                        {/* TODO: Bookings and Listings pages */}
                        {/*<Route path="bookings" element={<ProposalInquiries />} />*/}
                        {/*<Route path="listings" element={<ProposalInquiries />} />*/}
                        <Route path="messages" element={<Messages />} />
                        <Route path="calendar" element={<Calendar />} />
                        <Route path="analytics" element={<Analytics />} />
                    </Routes>
                </MainContent>
            </Box>
        </Box>
    );
};

export const BizlyOS = () => {
    return (
        <OSLayoutProvider>
            <BizlyOSLayout />
        </OSLayoutProvider>
    );
};
