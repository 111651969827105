import CurrencyField from 'components/ProposalForm/CurrencyField';

export const fields = (currencyCode: string) => ({
    guests: {
        prompt: 'Total Guests',
        type: 'text',
        optional: true,
    },
    guestroomNights: {
        prompt: 'Total Guest Room Nights',
        type: 'text',
        optional: true,
    },
    currencyCode: {
        type: CurrencyField,
        disabled: true,
        perRow: 'auto',
    },
    guestroomCost: {
        type: 'text',
        options: { numberType: 'currency', currency: currencyCode },
    },
    roomCost: {
        type: 'text',
        options: { numberType: 'currency', currency: currencyCode },
    },
    cateringCost: {
        type: 'text',
        options: { numberType: 'currency', currency: currencyCode },
    },
    total: {
        type: 'text',
        options: { numberType: 'currency', currency: currencyCode },
    },
});

const grCost = {
    type: 'nested',
    prompt: 'Estimated Guest Room Costs',
    optional: true,
    perRow: 2,
    schema: [
        {
            key: 'grCostAndCurrency',
            fields: ['guestroomCost', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};
const roomCost = {
    type: 'nested',
    prompt: 'Estimated Room Rental',
    optional: true,
    perRow: 2,
    schema: [
        {
            key: 'roomCostAndCurrency',
            fields: ['roomCost', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};
const fbCost = {
    type: 'nested',
    prompt: 'Estimated Food & Beverage',
    optional: true,
    perRow: 2,
    schema: [
        {
            key: 'fbCostAndCurrency',
            fields: ['cateringCost', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};
const totalCost = {
    type: 'nested',
    prompt: 'Estimated Total',
    perRow: 2,
    schema: [
        {
            key: 'totalAndCurrency',
            fields: ['total', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};

export const schema = [
    {
        key: 'rowOne',
        fields: ['guests', 'guestroomNights'],
        spacing: 'medium',
    },
    {
        key: 'rowTwo',
        fields: [grCost, roomCost],
        spacing: 'medium',
    },
    {
        key: 'rowThree',
        fields: [fbCost, totalCost],
        spacing: 'small',
    },
];

export const schemaWithoutGuestrooms = [
    {
        key: 'rowOne',
        fields: ['guests', roomCost],
        spacing: 'medium',
    },
    {
        key: 'rowTwo',
        fields: [fbCost, totalCost],
        spacing: 'small',
    },
];
