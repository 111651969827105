import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { EventFormValues } from 'pages/Venues/Venues';
import { FormProvider, useForm } from 'react-hook-form';
import VenueDetailModalContent from './VenueDetailModalContent';

const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 0.625rem;
        max-width: 1000px;
    }
`;

type VenueDetailDialogProps = {
    open: boolean;
    handleClose: () => void;
};

export default function VenueDetailDialog(props: VenueDetailDialogProps) {
    const { open, handleClose } = props;
    const methods = useForm<EventFormValues>();

    return (
        <FormProvider {...methods}>
            <StyledDialog
                fullWidth
                onClose={handleClose}
                scroll="body"
                open={open}
                PaperProps={{
                    style: {
                        borderRadius: '0.625rem',
                    },
                }}
            >
                <VenueDetailModalContent handleClose={handleClose} />
            </StyledDialog>
        </FormProvider>
    );
}
