import { Divider } from '@material-ui/core';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, buttonClasses } from '@mui/material';
import RightDrawer from 'components/Drawer/RightDrawer';
import { Button } from 'components/Ui-V2/Button/Button';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notificationActions, useNotifications } from 'stores/notifications';
import LoadNotifications from 'stores/notifications/LoadNotifications';
import styled from 'styled-components';
import { i18n } from 'translation';
import { AlignedRow, Column, Copy, Row, SpacedRow, Spacer } from 'ui';
import { HEADER_HEIGHT, getNotificationLink } from 'utils/header';
import { tzMoment, userTimeZone } from 'utils/moment';
import BellIcon from '../../images/icons/header/bell.svg?react';
import NotificationBellIcon from '../../images/icons/header/notification-bell.svg?react';

const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: -24px -32px;
    padding: 24px 32px;
    box-sizing: border-box;
    height: ${HEADER_HEIGHT}px;

    transition: 0.3s all ease-in-out;

    ${({ theme: { getColor, EColors } }) =>
        `
        background: ${getColor(EColors.pureWhite)};
    `}
`;

const Right = styled(AlignedRow)`
    justify-content: flex-end;
`;

const NotificationDot = styled.div`
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.vividFuchsia)};
`;

const FiltersGroup = styled.div`
    display: flex;
    gap: 1em;
`;

const FilterButton = styled.span<{ selected?: boolean }>`
    font-size: 0.9em;
    cursor: ${({ selected }) => (!selected ? 'pointer' : 'auto')};
    color: ${({ selected, theme: { getColor, EColors } }) =>
        selected ? getColor(EColors.primaryAction) : getColor(EColors.black)};
`;

const CheckIconWrapper = styled.div`
    cursor: pointer;
`;

const CheckIcon = styled(CheckCircleOutlineIcon)`
    fill: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
    margin-top: 0.25rem;
`;

const NotificationImage = styled.div<{ src: string; unread?: boolean }>`
    border-radius: 2px;
    width: 60px;
    height: 45px;
    margin-right: 24px;
    margin-top: 4px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-attachment: scroll;
    background-size: cover;
    background-image: url(${props => props.src || 'none'});
    position: relative;
`;

const VenueDotContainer = styled.div`
    position: absolute;
    left: -3px;
    top: -3px;
`;

const NotificationInfo = styled(Column)`
    flex: 1;
`;

const InfoCopy = styled(Copy)<{ light?: boolean; small?: boolean }>`
    color: ${({ light, theme: { getColor, EColors } }) => getColor(light ? EColors.darkGrey : EColors.darkestGrey)};
    font-size: ${({ small }) => (small ? 13 : 15)}px;
    font-weight: ${({ light }) => (light ? 400 : 500)};
`;

const NotificationLink = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
    font-size: 13px;
    text-decoration: underline;
    margin-left: 24px;
    cursor: pointer;
`;

const Notification = styled.div<{ read?: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    ${({ read, theme: { getColor, EColors } }) =>
        read &&
        `
        ${NotificationImage}, ${NotificationInfo} {
            opacity: 0.55;
        }

        ${CheckIcon} {
            fill: ${getColor(EColors.snackbarSuccess)};
        }
    `}
`;

const BellIconButton = styled(Button)(({ theme: { getColor, EColors } }) => ({
    [`&.${buttonClasses.outlined}.${buttonClasses.colorSecondary}`]: {
        borderColor: getColor(EColors.platinum),
    },

    minWidth: '1rem',
    position: 'relative',
}));

const StyledNotificationBellIcon = styled(NotificationBellIcon)`
    fill: transparent;
`;

const StyledBellIcon = styled(BellIcon)`
    fill: transparent;
`;

type NotificationsFilterType = 'all' | 'read' | 'unread';

export const NotificationHeader = () => {
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [filter, setFilter] = useState<NotificationsFilterType>('all');
    const { notifications, total, unread } = useNotifications();

    const readNotification = (notification: BizlyAPI.Notification) => {
        notificationActions.updateReadStatus(notification.id, !notification.read);
    };

    const onNotificationClick = (notification: BizlyAPI.Notification) => {
        if (!notification.read) {
            readNotification(notification);
        }
        const link = getNotificationLink(notification);
        navigate(link);
    };

    const filteredNotifications = useMemo(() => {
        if (filter === 'all') {
            return notifications;
        } else if (filter === 'read') {
            return notifications.filter(n => n.read);
        } else {
            return notifications.filter(n => !n.read);
        }
    }, [notifications, filter]);

    const renderNotifications = () => (
        <Column>
            <Copy>{i18n.notifications.newMessagesCopy(unread, total)}</Copy>
            <Spacer small />
            <Divider />
            <Spacer small />
            <SpacedRow>
                <Copy>{i18n.notifications.filters}</Copy>
                <FiltersGroup>
                    <FilterButton selected={filter === 'all'} onClick={() => setFilter('all')}>
                        {i18n.notifications.all}
                    </FilterButton>
                    <FilterButton selected={filter === 'unread'} onClick={() => setFilter('unread')}>
                        {i18n.notifications.unread}
                    </FilterButton>
                    <FilterButton selected={filter === 'read'} onClick={() => setFilter('read')}>
                        {i18n.notifications.read}
                    </FilterButton>
                </FiltersGroup>
            </SpacedRow>
            <Column>
                {filteredNotifications.map((notification, index) => (
                    <Notification key={`${notification.id}-${index}`} read={notification.read}>
                        <NotificationImage src={notification.venue.imageUrl}>
                            {!notification.read && (
                                <VenueDotContainer>
                                    <NotificationDot />
                                </VenueDotContainer>
                            )}
                        </NotificationImage>
                        <NotificationInfo>
                            <Row>
                                <InfoCopy>{notification.title}</InfoCopy>
                            </Row>
                            <Spacer xsmall />
                            <Row>
                                <InfoCopy>{notification.venue.name}</InfoCopy>
                            </Row>
                            <Row>
                                <InfoCopy light>{notification.event.name}</InfoCopy>
                            </Row>
                            <Row>
                                <InfoCopy light small>
                                    {tzMoment(notification.timestamp * 1000, userTimeZone).format('lll')}
                                </InfoCopy>
                                <NotificationLink onClick={() => onNotificationClick(notification)}>
                                    {notification.linkLabel}
                                </NotificationLink>
                            </Row>
                        </NotificationInfo>
                        <CheckIconWrapper onClick={() => readNotification(notification)}>
                            <CheckIcon />
                        </CheckIconWrapper>
                    </Notification>
                ))}
            </Column>
        </Column>
    );

    return (
        <Box>
            <LoadNotifications />
            <Header>
                <Right itemSpacing="small">
                    <BellIconButton variant="outlined" color="secondary" onClick={() => setDrawerOpen(true)}>
                        {unread > 0 ? <StyledNotificationBellIcon /> : <StyledBellIcon />}
                    </BellIconButton>
                </Right>
            </Header>
            <RightDrawer drawerOpen={drawerOpen} onClose={() => setDrawerOpen(false)} title={i18n.notifications.title}>
                {renderNotifications()}
            </RightDrawer>
        </Box>
    );
};
