import { useSnackbar } from 'notistack';
import React from 'react';
import { i18n } from 'translation';
import { meetingsActions } from './store';

export default function LoadMeeting({ id }: { id?: string | number }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const load = async () => {
            if (id) {
                try {
                    await meetingsActions.loadSingle(id);
                } catch (e) {
                    enqueueSnackbar(i18n.error.default, { variant: 'error' });
                }
            }
        };
        load();
    }, [id, enqueueSnackbar]);

    return null;
}
