import { NotificationHeader } from 'components/NotificationHeader/NotificationHeader';
import VenueDetailDialog from 'components/VenueDetailDialog';
import { QueryParamEnum } from 'constants/queryParams';
import VenueSearch from 'pages/VenueSearch';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export enum EventFormFields {
    EVENT = 'event',
}

export type EventFormValues = {
    [EventFormFields.EVENT]: Bizly.Event;
};

export default function Venues() {
    screenY;
    const navigate = useNavigate();
    const location = useLocation();
    const [openVenueDialog, setOpenVenueDialog] = useState<boolean>(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const venueId = params.get(QueryParamEnum.VENUE_ID);

        if (venueId) {
            setOpenVenueDialog(true);
        } else {
            setOpenVenueDialog(false);
        }
    }, [location.search]);

    const handleClose = () => {
        const params = new URLSearchParams(location.search);
        params.delete(QueryParamEnum.VENUE_ID);

        navigate({
            pathname: location.pathname,
            search: params.toString(),
        });

        setOpenVenueDialog(false);
    };

    const viewVenueListingInPopup = (venueId: number) => {
        const params = new URLSearchParams(location.search);
        params.set(QueryParamEnum.VENUE_ID, venueId.toString());
        navigate({
            pathname: location.pathname,
            search: params.toString(),
        });
        setOpenVenueDialog(true);
    };

    return (
        <>
            <NotificationHeader />
            <VenueSearch<true> hideActions viewVenueListing={viewVenueListingInPopup} />
            <VenueDetailDialog open={openVenueDialog} handleClose={handleClose} />
        </>
    );
}
