import { darken, lighten } from '@material-ui/core';
import RSVPForm from 'components/RSVP/RSVPForm/RSVPForm';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { isColorBright } from 'utils/colors';
import { tzMoment } from 'utils/moment';
import { Button, Column, PaddedCard, Row } from '../../ui';
import { SpinnerOverlay } from '../Spinner';

const CARD_WIDTH = 680;
const IMAGE_HEIGHT = 250;

const Banner = styled.div`
    position: relative;
`;

const CompanyOffSite = styled.div<{ backgroundColor: string }>`
    display: flex;
    padding: 0.625rem 0.625rem 0.625rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    background: ${({ backgroundColor }) => backgroundColor};

    span {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        text-align: center;
        font-family: IBM Plex Serif;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.125rem;
        text-transform: uppercase;
    }
`;

// vertical-align bottom remove space under image
const FittedImage = styled.img`
    background-image: url(${({ src }) => src});
    max-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
`;

const FittedBackgroundImage = styled.div<{ backgroundColor: string }>`
    display: flex;
    height: 9.375rem;
    padding: 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.125rem;
    align-self: stretch;
    background: ${({ backgroundColor }) => backgroundColor};
`;

const LogoWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-bottom-right-radius: 0.5rem;
`;

const CompanyLogo = styled('div')<{ src: string }>`
    width: 5.25rem;
    height: 5.25rem;
    background-image: url(${props => props.src || 'none'});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
`;

const StyledHTML = styled.div`
    a {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
        text-decoration: underline;
    }
`;

const Container = styled(Column)`
    padding: 2rem;
`;

export type ParcelDates = {
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
};

type EventRegistrationCardPreviewProps = {
    team?: Partial<Bizly.Team>;
    image?: string;
    name?: string;
    description?: string;
    parcelDates?: ParcelDates | null;
    location?: string | null;
    address?: string | null;
    cityState?: string | null;
    isPreview?: boolean;
    onChange?: (
        attendee: Bizly.EventAttendee & {
            value: {
                [key: string]: Bizly.EventAttendee;
            };
        }
    ) => void;
    handleSubmit?: () => void;
    saveButtonDisabled?: boolean;
    formSettings?: Bizly.FormSetting;
    attendee?: Bizly.EventAttendee;
    timezone?: string;
};

const EventRegistrationCard = ({
    team,
    image,
    name,
    description = '',
    parcelDates,
    location,
    address,
    cityState,
    isPreview,
    onChange,
    handleSubmit,
    saveButtonDisabled,
    formSettings,
    attendee,
    timezone,
}: EventRegistrationCardPreviewProps) => {
    const { color, imageUrl } = team as { color: Themed.Color; imageUrl: string };

    const companyOffSiteColor = useMemo(() => {
        return isColorBright(color) ? darken(color, 0.2) : lighten(color, 0.2);
    }, [color]);

    if (!formSettings || !attendee) return <SpinnerOverlay />;

    return (
        <PaddedCard padding={0} style={{ position: 'relative', wordWrap: 'break-word' }}>
            <Banner>
                {image ? (
                    <FittedImage src={image} width={CARD_WIDTH} height={IMAGE_HEIGHT} />
                ) : (
                    <FittedBackgroundImage backgroundColor={color} />
                )}
            </Banner>

            <CompanyOffSite backgroundColor={companyOffSiteColor}>
                <span>{name && name}</span>
            </CompanyOffSite>

            <LogoWrapper>
                <CompanyLogo src={imageUrl} />
            </LogoWrapper>

            <Container itemSpacing="largest" paddingSpacing>
                <Column itemSpacing="medium">
                    {description && (
                        <Column itemSpacing="small">
                            <StyledHTML dangerouslySetInnerHTML={{ __html: description }} />
                        </Column>
                    )}

                    <Column style={{ maxWidth: '65%' }} itemSpacing="small">
                        {parcelDates?.startDate && (
                            <Row justifyContent="space-between">
                                <Column fillWidth>{i18n.communication.when}</Column>
                                <Column fillWidth>{tzMoment(parcelDates?.startDate).format('dddd, ll')}</Column>
                            </Row>
                        )}
                        {parcelDates?.startTime && (
                            <Row justifyContent="space-between">
                                <Column fillWidth>{i18n.communication.time}</Column>
                                <Column fillWidth>
                                    {tzMoment(`2023-01-01T${parcelDates?.startTime}`).format('LT')}
                                </Column>
                            </Row>
                        )}
                        {location && (
                            <Row justifyContent="space-between">
                                <Column fillWidth>{i18n.communication.venue}</Column>
                                <Column fillWidth>{location}</Column>
                            </Row>
                        )}
                        {cityState && (
                            <Row justifyContent="space-between">
                                <Column fillWidth>{i18n.communication.city}</Column>
                                <Column fillWidth>{cityState}</Column>
                            </Row>
                        )}
                        {address && (
                            <Row justifyContent="space-between">
                                <Column fillWidth>{i18n.communication.address}</Column>
                                <Column fillWidth>{address}</Column>
                            </Row>
                        )}
                    </Column>
                </Column>

                <RSVPForm
                    attendee={attendee}
                    formSettings={formSettings}
                    parcelDates={parcelDates}
                    onChange={isPreview ? null : onChange}
                    timezone={timezone}
                    eventRegistration
                />

                <Row style={{ paddingTop: 0 }}>
                    <Button
                        style={{
                            flexGrow: 1,
                        }}
                        onClick={isPreview ? () => {} : handleSubmit}
                        disabled={saveButtonDisabled}
                    >
                        {i18n.communication.registerForEvent}
                    </Button>
                </Row>
            </Container>
        </PaddedCard>
    );
};

export default EventRegistrationCard;
