import Close from '@mui/icons-material/Close';
import { DialogContent, DialogTitle, IconButton, styled, Typography } from '@mui/material';
import { getMeeting } from 'api/meetings';
import { QueryParamEnum } from 'constants/queryParams';
import useVenueInquiry from 'hooks/useVenueInquiry';
import VenueListing from 'pages/VenueListingV2';
import { EventFormFields, EventFormValues } from 'pages/Venues/Venues';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { Spinner, SpinnerWrapper } from './Spinner';

const StyledDialogContent = styled(DialogContent)`
    min-height: 20rem;
`;

const StyledSpinnerWrapper = styled(SpinnerWrapper)`
    position: absolute;
    inset: 0;
`;

type VenueDetailModalContentProps = {
    handleClose?: () => void;
};

const VenueDetailModalContent = ({ handleClose }: VenueDetailModalContentProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [event, setEvent] = useState<BizlyAPI.Meeting | Bizly.Event | null>(null);
    const location = useLocation();
    const { eventId } = useParams();

    const useQuery = useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location.search]);

    const query = useQuery;
    const venueId = query.get(QueryParamEnum.VENUE_ID);

    const handleLoadingStatus = (loading: boolean) => {
        setIsLoading(loading);
    };

    const { watch } = useFormContext<EventFormValues>();
    const rhfEvent = watch(EventFormFields.EVENT);

    useEffect(() => {
        const fetchEvent = async () => {
            if (eventId) {
                try {
                    const data = await getMeeting(eventId);
                    setEvent(data.meeting);
                } catch (error) {
                    console.error('Error fetching meeting:', error);
                }
            } else {
                setEvent(rhfEvent);
            }
        };

        fetchEvent();
    }, [eventId, rhfEvent]);

    const {
        addVenueIfCan,
        removeVenueIfCan,
        selectedVenues,
        loadingInquiry: isLoadingInquiry,
    } = useVenueInquiry(event as Bizly.Event);

    return (
        <>
            {!isLoading && (
                <>
                    <DialogTitle sx={{ m: 0, paddingInline: 3, paddingTop: 3, paddingBottom: 0 }}>
                        <Typography
                            color={EColors.black}
                            fontWeight={400}
                        >{`${i18n.venue.propertyId} #${venueId}`}</Typography>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            '&.MuiButtonBase-root': {
                                color: EColors.black,
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            },
                        }}
                    >
                        <Close />
                    </IconButton>
                </>
            )}
            <StyledDialogContent>
                {isLoading && (
                    <StyledSpinnerWrapper>
                        <Spinner />
                    </StyledSpinnerWrapper>
                )}
                <VenueListing
                    addToInquiry={addVenueIfCan}
                    removeFromInquiry={removeVenueIfCan}
                    selectedVenues={selectedVenues}
                    useSearchParamId
                    onLoadingStatusChange={handleLoadingStatus}
                    isLoadingInquiry={isLoadingInquiry}
                />
            </StyledDialogContent>
        </>
    );
};

export default VenueDetailModalContent;
