import Form from 'components/Form';
import he from 'he';
import PlusIcon from 'images/icons/plus.svg?react';
import flattenDeep from 'lodash/flattenDeep';
import fromPairs from 'lodash/fromPairs';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import omitBy from 'lodash/omitBy';
import { i18n } from 'translation';
import { SPACER_SPACES } from 'ui';
import { tzMoment } from 'utils/moment';
import { COUNTRY_TO_CURRENCY } from '../CurrencyField';
import { submittedProposal } from '../statusUtils';
import { TESBooking, TESRequestKeys, TProposal, TProposalForm } from '../types';
import { isEmptyString, isEmptyStringOrNumber } from '../utils';

export type TOption = { id: number; name: string; iconUrl: string };

export type TOptionsDictionary<T extends TOption> = Record<number, T>;

export const GUEST_ROOMS_REQUEST_WIDTH = 320;

export const replaceObjInArray = <T extends any>(arr: T[], targetIndex: number, newVal: T) =>
    arr.map((item, index) => (index === targetIndex ? newVal : item));

export const optionsToListForm = <Value, Option, FieldDef, SchemaDefFirst, SchemaDef>({
    options,
    getOptionId,
    optionToField,
    optionToSchema,
    headingsSchema = undefined,
    field,
    value,
    getValueId,
    onChange,
    disabled,
}: {
    options: Option[];
    getOptionId: (option: Option) => string;
    optionToField: (option: Option, index: number) => FieldDef;

    headingsSchema?: SchemaDefFirst;
    optionToSchema: (option: Option, index: number) => SchemaDef;

    field: string;
    value: Value[];
    getValueId: (value: Value) => string;
    onChange: (change: { field: string; value: Value[]; errors: any }) => void;
    disabled?: boolean;
}) => {
    const firstSchema = headingsSchema
        ? {
              key: 'headings',
              spacing: false,
              ...headingsSchema,
          }
        : undefined;

    const props = {
        fields: fromPairs(options.map((option, index) => [getOptionId(option), optionToField(option, index)])),

        schema: [
            ...(firstSchema ? [firstSchema] : []),
            ...options.map((option, index) => ({
                ...optionToSchema(option, index),
                fields: [getOptionId(option)],
                ...(index === options.length - 1 ? { spacing: false } : {}),
            })),
        ],

        value: keyBy(value, getValueId),

        onChange: ({ value: newVal, errors }: { value: Record<string, Value>; errors: any }) => {
            onChange({
                field,
                value: Object.values(newVal).filter(value => value !== undefined),
                errors,
            });
        },
        disabled: disabled,
    };

    return <Form {...props} />;
};

export const EVENT_SPACES_REQUEST_ITEM_SPACING = 'large';
export const EVENT_SPACES_REQUEST_WIDTH = 200 + SPACER_SPACES[EVENT_SPACES_REQUEST_ITEM_SPACING];
export const OPTIONS_RADIO_CHECKBOX_WIDTH = 180;

type TAVResponse = {
    id: number;
    response?: boolean;
};

// FB Specific:
export type TFBOption = TOption & { hasDiningStyle: boolean };
export type TFBRequest = {
    fbOptionId: number;
    diningStyleId: number | null;
};
export type TFBResponse = TFBRequest & { response?: boolean };
// an object that combines the FB request and DS request
export type TFBOptionRequest = {
    fbId: number;
    fbName: string;
    fbIconUrl: string;

    hasDiningStyle: boolean;

    dsId?: number;
    dsName?: string;
    dsIconUrl?: string;
};

// revisit, this ranks food options (breakfast, lunch, dinner) above others (afternoon break, reception, etc.)
const FB_RANKING = {
    2: 1,
    4: 2,
    3: 3,

    6: 4,
    5: 5,
    1: 6,
} as { [key: number]: number };

const getFBRanking = (id: number) => FB_RANKING[id] || Infinity;
export const sortFBRequest = (request: TFBRequest[]) =>
    request.sort((a, b) => getFBRanking(a.fbOptionId) - getFBRanking(b.fbOptionId));

/*

VENUE SPACES

*/

type TImage = {
    id: number;
    name: string;
    description: string;
    srcUrl: string;
};

export type TSpace = {
    id: number;
    name: string;
    description: string | null;
    size: null | Area.Sqft | string;
    maxCapacity: number | string | null;
    images: TImage[];
};

export const sanitizedSpaces = (spaces: TSpace[]) =>
    spaces.map(space => ({
        ...space,
        description: space?.description && he.decode(space.description.replace(/(<([^>]+)>)/gi, '')),
    }));

export const SPECIAL_OPTION_IDS = {
    none: -1,
    create: -2,
} as const;

export const selectedASpace = (id?: number | null) =>
    id && id !== SPECIAL_OPTION_IDS.none && id !== SPECIAL_OPTION_IDS.create;

export const NEWLY_CREATED_SPACE = {
    id: SPECIAL_OPTION_IDS.create,
    name: '',
    description: '',
    size: null,
    maxCapacity: null,
    images: [],
};

export const spacesToOptions = (spaces: TSpace[], includeNoSpace?: boolean) => [
    ...(includeNoSpace
        ? [{ id: SPECIAL_OPTION_IDS.none, name: i18n.proposalForm.eventSpaces.noSpaceAvailable, emphasize: true }]
        : []),
    ...spaces.map(({ id, name }) => ({
        id,
        name,
    })),
    {
        id: SPECIAL_OPTION_IDS.create,
        name: i18n.proposalForm.eventSpaces.createNewSpace,
        emphasize: true,
        icon: <PlusIcon />,
    },
];

/*

MAIN FORM UTILS

*/

type TESBookingResponse = Omit<TESBooking, 'proposedAvIds' | 'proposedFb'> & {
    proposedAvIds: TAVResponse[];
    proposedFb: TFBResponse[];
};

type TFees = Pick<TProposal, 'gratuity' | 'salesTax' | 'serviceCharge' | 'useDdr'> & {
    currencyCode?: Exclude<TProposal['currency'], null | undefined>['code'];
};

export type TESFormBooking = TESBookingResponse & TFees;

export type TEventSpacesFormValue = {
    eventSpacesByDay: TESFormBooking[][];
    eventSpacesCommission: TProposal['guestRoomCommissionRate'];
    fbCommission: TProposal['fbCommissionRate'];
};

const spacesByDay = (eventSpaces: TESBookingResponse[]) =>
    Object.values(groupBy(eventSpaces, space => space.requestedDate));

const NO_PRICE = -1;

const proposedEqualRequested = (eventSpaces: TESBooking[], proposalIsSubmitted: boolean) =>
    eventSpaces.map(({ proposedAvIds, proposedFb, proposedRoomRate, proposedFbMinimum, ...space }) => ({
        ...space,
        proposedDate: space.requestedDate,
        proposedStartTime: space.requestedStartTime,
        proposedEndTime: space.requestedEndTime,
        proposedSetupId: space.requestedSetupId,
        proposedRoomRate: proposedRoomRate === NO_PRICE ? null : proposedRoomRate,
        proposedFbMinimum: proposedFbMinimum === NO_PRICE ? null : proposedFbMinimum,

        proposedVenueSpaceId:
            proposalIsSubmitted && space.proposedVenueSpaceId === null
                ? SPECIAL_OPTION_IDS.none
                : space.proposedVenueSpaceId,

        proposedFb: (space.requestedFb || []).map(fb => ({
            ...fb,
            response: space.proposalSpaceId
                ? (proposedFb || []).some(pfb => pfb.fbOptionId === fb.fbOptionId)
                : undefined,
        })),
        proposedAvIds: (space.requestedAvIds || []).map(av => ({
            id: av,
            response: space.proposalSpaceId ? (proposedAvIds || []).some(pav => pav === av) : undefined,
        })),
    }));

export const setFeeTaxOnAllES = (
    esBookingsByDay: (TESBookingResponse & Partial<TFees>)[][],
    { gratuity, salesTax, serviceCharge, currencyCode, useDdr }: Partial<TFees>
) =>
    esBookingsByDay.map(dayBookings =>
        dayBookings.map(booking => ({
            ...booking,
            gratuity: gratuity !== undefined ? gratuity : booking?.gratuity || null,
            salesTax: salesTax !== undefined ? salesTax : booking?.salesTax || null,
            serviceCharge: serviceCharge !== undefined ? serviceCharge : booking?.serviceCharge || null,
            currencyCode: currencyCode !== undefined ? currencyCode : booking?.currencyCode || undefined,
            useDdr: useDdr !== undefined ? useDdr : booking?.useDdr || false,
        }))
    );

export const proposalFormToFormData = ({ eventSpaces = [], proposal = {}, venue = {} }: Partial<TProposalForm>) => {
    const {
        gratuity = null,
        salesTax = null,
        serviceCharge = null,
        currency,
        useDdr,
        eventSpacesCommissionable,
        eventSpaceCommissionRate,
        fbCommissionable,
        fbCommissionRate,
    } = proposal;
    return {
        eventSpacesByDay: setFeeTaxOnAllES(
            spacesByDay(proposedEqualRequested(eventSpaces, submittedProposal(proposal))),
            {
                gratuity,
                salesTax,
                serviceCharge,
                currencyCode:
                    currency?.code ?? (venue.country?.code && COUNTRY_TO_CURRENCY[venue.country.code]) ?? 'USD',
                useDdr: useDdr ?? venue.country?.code === 'GB',
            }
        ),
        eventSpacesCommission: eventSpacesCommissionable ? Number(eventSpaceCommissionRate) : null,
        fbCommission: fbCommissionable ? Number(fbCommissionRate) : null,
    };
};

type TESFormBookingProposal = Omit<TESFormBooking, TESRequestKeys>;

export const formDataToProposalForm = ({
    eventSpacesByDay = [],
    eventSpacesCommission,
    fbCommission,
}: TEventSpacesFormValue) => {
    const eventSpacesBookings = flattenDeep(eventSpacesByDay);
    const eventSpacesProposals = eventSpacesBookings.map(booking => ({
        ...(omitBy<TESFormBookingProposal>(booking, (value, key) => key.includes('request')) as TESFormBookingProposal),

        proposedFb: booking.proposedFb
            .filter(pfb => pfb.response)
            .map(({ fbOptionId, diningStyleId }) => ({ fbOptionId, diningStyleId })),

        proposedAvIds: booking.proposedAvIds.filter(pav => pav.response).map(({ id }) => id),
    }));

    const {
        gratuity = null,
        salesTax = null,
        serviceCharge = null,
        currencyCode,
        useDdr,
    } = eventSpacesProposals[0] || {};

    const formattedBookings = eventSpacesProposals.map(
        ({
            gratuity,
            salesTax,
            serviceCharge,
            proposedRoomRate,
            proposedFbMinimum,
            proposedRatePerPerson,
            proposedMinGuests,
            ...booking
        }) => ({
            ...booking,
            proposedRoomRate: isEmptyStringOrNumber(proposedRoomRate) ? NO_PRICE : proposedRoomRate,
            proposedRatePerPerson: isEmptyStringOrNumber(proposedRatePerPerson) ? NO_PRICE : proposedRatePerPerson,
            proposedMinGuests: isEmptyStringOrNumber(proposedMinGuests) ? NO_PRICE : proposedMinGuests,
            proposedFbMinimum:
                booking.proposedFb.length === 0
                    ? proposedFbMinimum || 0
                    : isEmptyStringOrNumber(proposedFbMinimum)
                      ? NO_PRICE
                      : proposedFbMinimum,
            ...(booking.proposedVenueSpaceId === SPECIAL_OPTION_IDS.none
                ? {
                      proposedVenueSpaceId: null,
                      proposalSpaceId: null,
                      proposedAvIds: [],
                      proposedFb: [],
                      proposedSetupId: null,
                  }
                : {}),
        })
    );

    return {
        eventSpaces: formattedBookings,
        proposal: {
            gratuity,
            salesTax,
            serviceCharge,
            currencyCode,
            useDdr,
            fbCommissionable: fbCommission !== null,
            fbCommissionRate: fbCommission,
            eventSpacesCommissionable: eventSpacesCommission !== null,
            eventSpaceCommissionRate: eventSpacesCommission,
        },
    };
};

const isMissingInfo = (
    {
        proposedStartTime,
        proposedEndTime,
        proposedVenueSpaceId,
        proposedSetupId,
        requestedAvIds,
        proposedAvIds,
        requestedFb,
        proposedFb,
        proposedRoomRate,
        proposedFbMinimum,

        proposedRatePerPerson,
        proposedMinGuests,

        gratuity,
        salesTax,
        serviceCharge,
        useDdr,
    }: TESFormBooking,
    eventSpacesCommission: TProposal['eventSpaceCommissionRate'],
    fbCommission: TProposal['fbCommissionRate'],
    blockPartial?: boolean
) => {
    if (proposedVenueSpaceId === SPECIAL_OPTION_IDS.none) return false;
    if (!proposedVenueSpaceId) {
        return blockPartial ? i18n.proposalForm.eventSpaces.error.noVenueSpaceId : false;
    }

    // has proposedVenueSpaceId
    if (isEmptyString(proposedStartTime) || isEmptyString(proposedEndTime) || !proposedSetupId)
        return i18n.proposalForm.eventSpaces.error.timeAndSetup;

    if (requestedAvIds && requestedAvIds.length > 0) {
        if (proposedAvIds.length === 0 || proposedAvIds.some(pav => typeof pav.response !== 'boolean'))
            return i18n.proposalForm.eventSpaces.error.avIds;
    }

    if (requestedFb && requestedFb.length > 0) {
        if (
            proposedFb.length === 0 ||
            proposedFb.some(
                pfb =>
                    typeof pfb.response !== 'boolean'
                // This doesn't work:
                    // ||
                    // // when selected Yes to the request but didn't select the dining style
                    // (pfb.response === true && (pfb.diningStyleId === null || pfb.diningStyleId === undefined))
            )
        )
            return i18n.proposalForm.eventSpaces.error.fb;
    }

    if (useDdr) {
        if ((blockPartial && isEmptyStringOrNumber(proposedMinGuests)) || proposedMinGuests === NO_PRICE)
            return i18n.proposalForm.eventSpaces.error.minGuest;
    } else {
        if (blockPartial && proposedFb.some(pfb => pfb.response === true) && isEmptyStringOrNumber(proposedFbMinimum))
            return i18n.proposalForm.eventSpaces.error.minFb;
    }

    const emptyPrice = useDdr
        ? isEmptyStringOrNumber(proposedRatePerPerson) || proposedRatePerPerson === NO_PRICE
        : isEmptyStringOrNumber(proposedRoomRate) || proposedRoomRate === NO_PRICE;

    if (
        blockPartial &&
        (emptyPrice ||
            isEmptyStringOrNumber(gratuity) ||
            isEmptyStringOrNumber(salesTax) ||
            isEmptyStringOrNumber(serviceCharge))
    )
        return i18n.proposalForm.eventSpaces.error.pricing;

    if (eventSpacesCommission !== null && (!eventSpacesCommission || Number(eventSpacesCommission) === 0)) {
        return i18n.proposalForm.eventSpaces.error.roomRateCommission;
    }
    if (fbCommission !== null && (!fbCommission || Number(fbCommission) === 0)) {
        return i18n.proposalForm.eventSpaces.error.fbCommission;
    }

    return false;
};

const findFirstMissingInfo = (formValues: TEventSpacesFormValue, blockPartial?: boolean) => {
    let dayIdx = -1;
    let spaceIdx = -1;
    formValues.eventSpacesByDay.some((dayBookings, dayIndex) => {
        const spaceIndex = dayBookings.findIndex(booking =>
            isMissingInfo(booking, formValues.eventSpacesCommission, formValues.fbCommission, blockPartial)
        );
        if (spaceIndex !== -1) {
            dayIdx = dayIndex;
            spaceIdx = spaceIndex;
            return true;
        }
        return false;
    });

    return { dayIdx, spaceIdx };
};

export const getErrorMessage = (formValues: TEventSpacesFormValue, isContinue?: boolean) => {

    const { dayIdx, spaceIdx } = findFirstMissingInfo(formValues, isContinue);

    if (dayIdx !== -1 && spaceIdx !== -1) {
        const { requestedDate } = formValues.eventSpacesByDay[dayIdx][spaceIdx];
        const error = isMissingInfo(
            formValues.eventSpacesByDay[dayIdx][spaceIdx],
            formValues.eventSpacesCommission,
            formValues.fbCommission,
            isContinue
        );

        const formattedDate = tzMoment(requestedDate + 'T00:00:00').format('dddd, ll');
        return (
            <span>
                {/* Need support for JSX */}
                {`Please make sure to `}
                <b>{error}</b>
                {` for:`}
                <br />
                <br />
                {`Day ${dayIdx + 1} - ${formattedDate}`}
                <br />
                {`Space ${spaceIdx + 1}`}
            </span>
        );
    }
};
