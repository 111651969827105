import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';

import { createEvent } from 'api';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'components/Planner/utils';
import { LinearProgressWithLabel } from 'components/ui/LinearProgress';
import EventCreateImage from 'images/event-create.jpg';
import AIGenerateSvg from 'images/icons/ai-generate.svg?react';
import { useUser } from 'providers/user';
import { i18n } from 'translation';
import { tzMoment } from 'utils/moment';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Step6 from './Steps/Step6';
import Step7 from './Steps/Step7';
import { EventCreateForm, EventCreateFormErrors } from './formSchema';
import { ModalImage, PrimaryButton, StyledDialog, TextButton } from './styled';

const AIIcon = styled(AIGenerateSvg)`
    margin-right: 1em;
`;

type EventCreateModalProps = {
    onClose: () => void;
    onEventCreated?: (event: Bizly.Event) => Promise<void>;
};

export default function EventCreateModal(props: EventCreateModalProps) {
    const { onClose, onEventCreated } = props;
    const { user } = useUser();

    const [submitting, setSubmitting] = React.useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [formValues, setFormValues] = useState<EventCreateForm>({});
    const [isStepValid, setIsStepValid] = useState(false);
    const [errors, setErrors] = useState<EventCreateFormErrors>({});

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const onFormValueChange = (key: keyof EventCreateForm) => (value: unknown) => {
        setFormValues(prev => ({
            ...prev,
            [key]: value,
        }));
        // reset error state
        setErrors(prev => ({
            ...prev,
            [key]: false,
        }));
    };

    useEffect(() => {
        let isValid = true;

        switch (currentStep) {
            case 1:
                if (!formValues.eventName) {
                    isValid = false;
                }
                break;
            case 2:
                if (!formValues.location) {
                    isValid = false;
                }
                break;
            case 3:
                if (!formValues.requirements || formValues.requirements.length === 0) {
                    isValid = false;
                }
                break;
            case 4:
                if (!formValues.eventDate?.[0] || !formValues.eventDate[1]) {
                    isValid = false;
                }
                break;
            case 5:
                if (!formValues.guests) {
                    isValid = false;
                }
                break;
            case 6:
                if (!formValues.payment?.cost || formValues.payment.cost <= 0) {
                    isValid = false;
                }
                break;
            case 7:
                if (!formValues.meetingType) {
                    isValid = false;
                }
                break;
        }

        setIsStepValid(isValid);
    }, [currentStep, formValues]);

    const getStepErrors = useCallback(
        (step: number): EventCreateFormErrors => {
            const stepErrors = {} as EventCreateFormErrors;

            switch (step) {
                case 1:
                    if (!formValues.eventName) {
                        stepErrors.eventName = true;
                    }
                    break;
                case 7:
                    if (user.team?.features.requiredInternalFields.includes('costCenter') && !formValues.costCenter) {
                        stepErrors.costCenter = true;
                    }
                    if (user.team?.features.requiredInternalFields.includes('cventId') && !formValues.cventId) {
                        stepErrors.cventId = true;
                    }
                    if (user.team?.features.requiredInternalFields.includes('department') && !formValues.department) {
                        stepErrors.department = true;
                    }
                    if (user.team?.features.requiredInternalFields.includes('type') && !formValues.meetingType) {
                        stepErrors.meetingType = true;
                    }
                    break;
            }
            return stepErrors;
        },
        [formValues, user.team?.features.requiredInternalFields]
    );

    const formSteps = [
        {
            component: <Step1 key="step1" onChange={onFormValueChange} values={formValues} errors={errors} />,
            required: true,
        },
        {
            component: (
                <Step2 key="step2" onChange={onFormValueChange('location')} value={formValues.location ?? null} />
            ),
            required: true,
        },
        {
            component: (
                <Step3 key="step3" onChange={onFormValueChange('requirements')} value={formValues.requirements ?? []} />
            ),
        },
        {
            component: (
                <Step4
                    key="step4"
                    onChange={onFormValueChange('eventDate')}
                    value={formValues.eventDate ?? [tzMoment(), tzMoment()]}
                />
            ),
        },
        {
            component: <Step5 key="step5" onChange={onFormValueChange('guests')} value={formValues.guests ?? 0} />,
        },
        {
            component: <Step6 key="step6" onChange={onFormValueChange('payment')} value={formValues.payment} />,
        },
        {
            component: <Step7 key="step7" onChange={onFormValueChange} values={formValues} errors={errors} />,
            required: true,
        },
    ];

    const onBack = () => {
        if (currentStep <= 1) {
            onClose();
        }
        setCurrentStep(step => step - 1);
    };

    const onContinue = () => {
        if (currentStep >= formSteps.length) {
            return;
        }
        setCurrentStep(step => step + 1);
    };

    const onSubmit = async () => {
        let formErrors = {} as EventCreateFormErrors;
        let errorStep = -1;
        for (let i = formSteps.length; i > 0; i--) {
            const stepErrors = getStepErrors(i);
            const hasError = Object.keys(stepErrors).length > 0;
            if (hasError) {
                errorStep = i;
            }
            formErrors = {
                ...formErrors,
                ...stepErrors,
            };
        }
        setErrors(formErrors);

        if (errorStep >= 0) {
            // send user to the step that has errors
            setCurrentStep(errorStep);
            return;
        }

        setSubmitting(true);
        const eventBody: Partial<Bizly.Event> = {
            name: formValues.eventName,
            description: formValues.eventDescription,
            requirements: formValues.requirements,
            googlePlaceId: formValues.location?.googlePlaceId,
            cventId: formValues.cventId,
            estAttendance: formValues.guests,
            costCenter: formValues.costCenter,
            department: formValues.department,
            type: formValues.meetingType || i18n.common.emptyValue,
            startsAt: formValues.eventDate?.[0]?.format(DATE_FORMAT),
            endsAt: (() => {
                const endDate = formValues.eventDate?.[1];
                if (endDate && endDate.format('HH:mm') === '00:00') {
                    return endDate.clone().set({ hour: 23, minute: 59 }).format(DATE_TIME_FORMAT);
                }
                return endDate?.format(DATE_TIME_FORMAT);
            })(),
        };
        if (formValues.payment?.mode === 'total') {
            eventBody.budget = formValues.payment.cost;
        } else if (formValues.payment?.mode === 'perPerson') {
            eventBody.budgetPerPerson = formValues.payment.cost;
        }
        try {
            const { event } = await createEvent(eventBody);
            await onEventCreated?.(event);
            navigate(`/event/${event.id}/venue`);
            onClose();
        } catch (e) {
            // leaving this direct error for Apple, even though it breaks i18n
            enqueueSnackbar(String(e), { variant: 'error' });
            setSubmitting(false);
        }
    };

    return (
        <StyledDialog open maxWidth="xl" onBackdropClick={submitting ? undefined : onClose}>
            <DialogContent>
                {formSteps[currentStep - 1].component}
                <Box>
                    <LinearProgressWithLabel totalSteps={formSteps.length} value={currentStep} />
                    <Box display="flex" justifyContent="space-between" sx={{ mt: 3 }}>
                        <TextButton variant="text" onClick={onBack}>
                            {currentStep <= 1 ? i18n.button.cancel : i18n.button.back}
                        </TextButton>
                        <Box display="flex" gap={1}>
                            {!formSteps[currentStep - 1].required && (
                                <TextButton variant="text" onClick={onContinue}>
                                    {i18n.button.skip}
                                </TextButton>
                            )}
                            {currentStep < formSteps.length ? (
                                <PrimaryButton variant="contained" onClick={onContinue} disabled={!isStepValid}>
                                    {i18n.button.continue}
                                </PrimaryButton>
                            ) : (
                                <Box sx={{ m: 1, position: 'relative' }}>
                                    <PrimaryButton
                                        variant="contained"
                                        onClick={onSubmit}
                                        disabled={!isStepValid || submitting}
                                    >
                                        <AIIcon /> {i18n.button.createEvent}
                                    </PrimaryButton>
                                    {submitting && (
                                        <CircularProgress
                                            size={24}
                                            color="inherit"
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </DialogContent>

            <Box sx={{ display: { md: 'none', lg: 'contents' } }}>
                <ModalImage src={EventCreateImage} alt="event-create" />
            </Box>
        </StyledDialog>
    );
}
