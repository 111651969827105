import { ProTip } from 'components/ProTip';
import React from 'react';
import styled from 'styled-components';
import { Column as UIColumn } from 'ui';

// TODO: That -17% margin needs to be accounted for in a more responsible way
const ProTipColumn = styled(UIColumn)`
    width: 100%;
    max-width: 382px;
    max-height: 1px;
    overflow: visible;
    margin-left: 16px;
`;

const SectionProTips = ({ protips = [] }: { protips?: Bizly.ProTip[] }) =>
    protips.length ? (
        <ProTipColumn>
            {protips.map((protip: any) => (
                <ProTip key={protip.content} message={protip.content} />
            ))}
        </ProTipColumn>
    ) : null;

export default SectionProTips;
