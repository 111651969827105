import { APISort } from 'constants/apiSort';
import create from 'zustand';

export type GetEventsFilters = {
    query: string;
    page: number;
    limit: number;
    orderBy: APISort;
    editable?: boolean;
    name?: string;
};

type EventsFiltersState = {
    filters: GetEventsFilters;
    setFilters: (filters: Partial<GetEventsFilters>) => void;
};

const useEventsFiltersStore = create<EventsFiltersState>(set => ({
    filters: {
        query: '',
        page: 1,
        limit: 20,
        orderBy: APISort.STARTS_AT_UTC,
        editable: undefined,
        name: '',
    },
    setFilters: newFilters =>
        set(state => ({
            filters: { ...state.filters, ...newFilters },
        })),
}));

export default useEventsFiltersStore;
