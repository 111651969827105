import Communication from 'pages/Communication/Communication';
import React from 'react';

export default function PlaybookParcels({
    playbookId,
    asWizardModule = false,
    showCreateButton,
}: {
    playbookId: number | string;
    asWizardModule?: boolean;
    showCreateButton?: boolean;
}) {
    return (
        <Communication
            playbookId={playbookId}
            readonly
            asWizardModule={asWizardModule}
            showCreateButton={showCreateButton}
        />
    );
}
