import Grid from '@material-ui/core/Grid';
import { Box } from '@mui/material';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';
import AgendaSection from 'components/EventDashboard/AgendaSection';
import CommunicationSection from 'components/EventDashboard/CommunicationSection';
import DashboardCard from 'components/EventDashboard/DashboardCard';
import DeleteModalForm from 'components/EventDashboard/DeleteModalForm';
import EventDetails from 'components/EventDashboard/EventDetails';
import EventForm from 'components/EventDashboard/EventForm';
import GuestlistSection from 'components/EventDashboard/GuestlistSection';
import VendorsSection from 'components/EventDashboard/VendorsSection';
import VenueSection from 'components/EventDashboard/VenueSection';
import { SpinnerOverlay } from 'components/Spinner';
import { Button } from 'components/Ui-V2/Button/Button';
import useShowModal from 'hooks/useShowModal';
import { useSnackbar } from 'notistack';
import { useAppConfigContext } from 'providers/app-config';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { useMemo, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useCvent } from 'stores/cvent';
import { isUpdating, meetingsActions, selectMeeting, useMeetings } from 'stores/meetings';
import { useSchedule } from 'stores/schedule';
import styled from 'styled-components';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { Row, Column as UIColumn } from 'ui';
import { getHeaderHeight } from 'utils/header';

const CardsColumn = styled(UIColumn)`
    width: 100%;
`;
const DashboardCols = styled(Row)`
    > ${CardsColumn} {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
    }
`;

const UNACCOUNTED_FOR_HEIGHT = 2; // TODO: investigate, this causes the page to display scrollbars even though everything is box-sizing: border-box

const Dashboard = styled(Row)<{ headerHeight: number; pagePadding: number }>`
    display: grid;
    max-width: 1600px;

    padding: ${({ pagePadding }) => pagePadding}px;
    padding-bottom: 7rem;
    box-sizing: border-box;

    min-height: calc(900px - ${({ headerHeight }) => headerHeight}px - ${UNACCOUNTED_FOR_HEIGHT}px);
`;

const DashboardFixedBottomActions = styled(Box)<{ pagePadding: number }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-left: ${({ pagePadding }) => -pagePadding}px;
    margin-right: ${({ pagePadding }) => -pagePadding}px;
    padding-inline: ${({ pagePadding }) => pagePadding}px;
    padding-top: 0.625rem;
    padding-bottom: 1.25rem;
    background-color: ${({ theme }) => theme.getColor(EColors.drWhite)};
    border-top: 1px solid ${({ theme }) => theme.getColor(EColors.lightGrey)};
`;

const StyledEditButton = styled(Button)`
    &.MuiButton-root {
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.getColor(EColors.grey)};
        padding-inline: 2.45em;
        color: ${({ theme }) => theme.getColor(EColors.darkerGrey)};
        margin-right: 1rem;
        &:hover {
            background-color: ${({ theme }) => theme.getColor(EColors.darkerGrey)};
            color: ${({ theme }) => theme.getColor(EColors.pureWhite)};
            border: 1px solid transparent;
        }
    }
`;
const StyledCancelButton = styled(Button)<{ isEditing: boolean; disabled: boolean }>`
    &.MuiButton-root {
        padding-inline: 1.88em;
        min-width: 5.875rem;
        background-color: ${({ isEditing, disabled, theme }) => {
            if (disabled) {
                return theme.getColor(EColors.lightGrey);
            }
            return isEditing
                ? theme.getColor(EColors.primaryAction)
                : theme.getColor(EColors.cancelledOrRejectedStatus);
        }};
    }
`;

const StyledGrid = styled(Grid)`
    flex: 2;
`;

type TEventDashboard = {
    viewVenueListing: (venueId: number) => void;
    viewProposal: (venue?: Bizly.Venue) => void;
    goToVenueSearch: () => void;
    viewInquiries: () => void;
    pagePadding?: number;
};

type EventFormRef = {
    onSubmit: () => void;
};

export default function EventDashboard({
    viewVenueListing,
    viewProposal,
    goToVenueSearch,
    viewInquiries,
    pagePadding = 0,
}: TEventDashboard) {
    const { user, toggles } = useUser();
    const { event, suggestedVenues, refreshEvent, reloadProvider } = useEvent();
    const { cventEvents } = useCvent();
    const { transparentHeader } = useAppConfigContext();
    const meetingUpdating = useMeetings(isUpdating(event.id));
    const meeting = useMeetings(selectMeeting(event.id));
    const [isEditing, setIsEditing] = useState(false);
    const [isEventFormSubmitting, setIsEventFormSubmitting] = useState(false);
    const [cancellationNotes, setCancellationNotes] = useState('');

    const eventFormRef = useRef<EventFormRef>(null);

    const { enqueueSnackbar } = useSnackbar();
    const { modalShown, showModal, hideModal } = useShowModal();

    const headerHeight = getHeaderHeight(transparentHeader);

    const schedule = useSchedule();

    const scheduleItems =
        (schedule.loaded &&
            schedule.eventId === event.id &&
            schedule.items.filter(item => item.date && item.startTime && item.endTime)) ||
        [];
    const hasScheduleItems = schedule.loaded && scheduleItems.length > 0;

    const agendasEnabled = user.team?.useAgendas || hasScheduleItems;

    const bookingDashboardCard = (
        <DashboardCard scrollable>
            <VenueSection
                suggestedVenues={suggestedVenues}
                eventId={event.id}
                editable={event.editable && !event.cancelledAt}
                eventName={event.name}
                location={event.location}
                googlePlaceId={event.googlePlaceId}
                lat={event.lat}
                lng={event.lng}
                startsAt={event.startsAt}
                endsAt={event.endsAt}
                viewVenueListing={viewVenueListing}
                viewProposal={viewProposal}
                onSearch={goToVenueSearch}
                onEditInquiries={viewInquiries}
                cancelledAt={event.cancelledAt}
            />
        </DashboardCard>
    );

    const cventName = useMemo(() => {
        const cvent = cventEvents.find(cv => cv.cventId === event.cventId);
        return cvent?.meetingName ?? i18n.meetingsPage.none;
    }, [cventEvents, event.cventId]);

    const onCloseEditing = (success?: boolean) => {
        setIsEditing(false);
        if (success) {
            refreshEvent();
        }
    };

    if (toggles.gate.createMeetingsFlow) {
        return <Navigate to={`/events/${event.id}`} replace />;
    }

    const handleEventFormSubmit = () => {
        eventFormRef.current?.onSubmit();
    };

    const onDefaultCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
        showModal();
        e.stopPropagation();
    };

    const cancelMeeting = async () => {
        if (!cancellationNotes) {
            return enqueueSnackbar(i18n.meetingDashboard.cancelError, { variant: 'error' });
        }
        await meetingsActions.cancel(event.id, cancellationNotes);
        await refreshEvent();
        reloadProvider();
        hideModal();
    };

    const getEditButtonProps = () => {
        if (isEditing) {
            return {
                onClick: () => {
                    onCloseEditing();
                },
                text: i18n.button.cancel,
            };
        } else {
            return {
                onClick: () => {
                    setIsEditing(true);
                },
                text: i18n.button.edit,
            };
        }
    };

    const getCancelButtonProps = () => {
        if (isEditing) {
            return {
                onClick: () => {
                    handleEventFormSubmit();
                },
                text: event?.id ? i18n.button.save : i18n.button.create,
            };
        } else {
            return {
                onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                    onDefaultCancel(e);
                },
                text: i18n.button.cancel,
            };
        }
    };

    const { onClick, text } = getEditButtonProps();
    const { onClick: onClickCancel, text: textCancel } = getCancelButtonProps();

    return (
        <>
            {!meeting ? (
                <SpinnerOverlay />
            ) : (
                <Dashboard headerHeight={headerHeight} pagePadding={pagePadding}>
                    <Box>
                        <DashboardCols itemSpacing="default" justifyContent="center">
                            <CardsColumn itemSpacing="default">
                                {!isEditing ? (
                                    <EventDetails cventName={cventName} />
                                ) : (
                                    <EventForm
                                        setSubmitting={setIsEventFormSubmitting}
                                        submitting={isEventFormSubmitting}
                                        ref={eventFormRef}
                                        event={event}
                                        onClose={onCloseEditing}
                                    />
                                )}
                            </CardsColumn>

                            <StyledGrid container spacing={4}>
                                <Grid item xs={6}>
                                    {agendasEnabled ? (
                                        <DashboardCard>
                                            <AgendaSection />
                                        </DashboardCard>
                                    ) : (
                                        bookingDashboardCard
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {agendasEnabled ? (
                                        bookingDashboardCard
                                    ) : (
                                        <DashboardCard>
                                            <VendorsSection />
                                        </DashboardCard>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <DashboardCard small>
                                        <GuestlistSection
                                            editable={event.editable}
                                            attendeeCount={event.attendeeCounts}
                                        />
                                    </DashboardCard>
                                </Grid>
                                <Grid item xs={6}>
                                    <DashboardCard small>
                                        <CommunicationSection editable={event.editable} />
                                    </DashboardCard>
                                </Grid>
                            </StyledGrid>
                        </DashboardCols>
                    </Box>
                    <DashboardFixedBottomActions pagePadding={pagePadding}>
                        {!event.cancelledAt && (
                            <Box>
                                <StyledEditButton onClick={onClick} variant="outlined">
                                    {text}
                                </StyledEditButton>
                                <StyledCancelButton
                                    isEditing={isEditing}
                                    onClick={onClickCancel}
                                    colorKey={isEditing ? 'primaryAction' : 'cancelledOrRejectedStatus'}
                                    disabled={isEventFormSubmitting}
                                >
                                    {textCancel}
                                </StyledCancelButton>
                            </Box>
                        )}
                    </DashboardFixedBottomActions>
                    <DeleteConfirmationModal
                        isActive={modalShown && !event.cancelledAt}
                        wide
                        onDismiss={hideModal}
                        onProceed={cancelMeeting}
                        ctaLabel={i18n.button.proceed}
                        prompt={
                            <DeleteModalForm
                                cancellationNotes={cancellationNotes}
                                setCancellationNotes={setCancellationNotes}
                                userCancellationMessage={user.team?.cancellationInformationMessage}
                                userTeamName={user.team?.name}
                            />
                        }
                        loading={meetingUpdating}
                    />
                </Dashboard>
            )}
        </>
    );
}
