import { post } from './index';

// Data is hard coded for now. We will remove this later once Demo is complete from Ron.
export const generateInviteSubjectAndBody = () =>
    post(`ai/generate-invite`, {
        meeting_name: 'Welcome new hires',
        meeting_description: 'This is a monthly meeting that HR hosts to welcome new employees to the company.',
        location: 'San Jose',
        start_date: 'Oct 31, 2023',
        start_time: '10:00 AM EDT',
        end_date: 'Nov 1, 2023',
        end_time: '11:00 AM EDT',
        from_name: 'Shama P',
        from_company_name: 'Apple',
    });
