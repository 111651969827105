import AddIcon from '@mui/icons-material/Add';
import { styled as muiStyled } from '@mui/material/styles';
import { isBlankTemplateId } from 'api/eventTemplates';
import ConfirmationModal from 'components/ConfirmationModal';
import EventCreateModal, { EventCreateProtocolModal } from 'components/EventCreateModal';
import { SpinnerOverlay } from 'components/Spinner';
import PagedPlaybooks from 'components/Templates/PagedPlaybooks';
import { ESECTION } from 'components/Templates/utils';
import { ButtonWithIcon } from 'components/Ui-V2/Button/Button';
import Button from 'components/ui/Button';
import { PageHeadline } from 'components/ui/Headline';
import useShowModal from 'hooks/useShowModal';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import moment from 'moment-timezone';
import { useUser } from 'providers/user';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadEventTemplates, eventTemplatesActions, useEventTemplates } from 'stores/eventTemplates';
import { LoadPlaybook, LoadPlaybookOptions, selectPlaybook, usePlaybooks } from 'stores/playbooks';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Row, Spacer } from 'ui';
import PlaybookDetails from './components/PlaybookDetails';
import { Footer } from './components/ui';

const Fixed = styled.div`
    position: fixed;
`;

const Hidden = styled.div`
    visibility: hidden;
`;

const StyledAddIcon = muiStyled(AddIcon)`
    font-size: 1.125rem;
`;

const PlaybookRecommendationsCol = ({
    globalTemplates,
    isLoading,
}: {
    globalTemplates: Bizly.EventTemplate[];
    isLoading?: boolean;
}) => (
    <Column>
        <LoadEventTemplates />
        <Hidden>
            <PagedPlaybooks
                title={i18n.playbooks.playbooks}
                subheading={i18n.playbooks.recommendationSubHeading}
                playbooks={globalTemplates}
                isSummary
                isLoading={isLoading}
            />
        </Hidden>
        <Fixed>
            <PagedPlaybooks
                title={i18n.playbooks.playbooks}
                subheading={i18n.playbooks.recommendationSubHeading}
                playbooks={globalTemplates}
                isSummary
                isLoading={isLoading}
            />
        </Fixed>
    </Column>
);

const FooterButtons = ({
    canShare,
    canEdit,
    canDelete,
    onCreateMeeting,
    sharePlaybook,
    goToEdit,
    deletePlaybook,
}: {
    canShare?: boolean;
    canEdit?: boolean;
    canDelete?: boolean;
    onCreateMeeting: () => void;
    sharePlaybook: () => void;
    goToEdit: () => void;
    deletePlaybook: () => void;
}) => {
    const [shareIntent, setShareIntent] = useState(false);
    const [deleteIntent, setDeleteIntent] = useState(false);

    const onDelete = () => {
        setDeleteIntent(false);
        deletePlaybook();
    };

    const onShare = () => {
        setShareIntent(false);
        sharePlaybook();
    };

    return (
        <Footer>
            <ButtonWithIcon leftIcon={StyledAddIcon} onClick={onCreateMeeting}>
                {i18n.homepage.eventButtons.createAnEvent}
            </ButtonWithIcon>

            {canShare && (
                <>
                    <Button onClick={() => setShareIntent(true)} secondary width="auto">
                        {i18n.playbook.publishToTeam}
                    </Button>
                    <ConfirmationModal
                        headline={i18n.playbook.publishHeadline}
                        onDismiss={() => setShareIntent(false)}
                        onProceed={onShare}
                        prompt={i18n.playbook.publishPrompt}
                        isActive={shareIntent}
                    />
                </>
            )}
            {canEdit && (
                <Button onClick={goToEdit} secondary>
                    {i18n.button.edit}
                </Button>
            )}
            {canDelete && (
                <>
                    <Button onClick={() => setDeleteIntent(true)} warning>
                        {i18n.button.delete}
                    </Button>
                    <ConfirmationModal
                        warning
                        headline={i18n.playbook.deletePlaybook}
                        onDismiss={() => setDeleteIntent(false)}
                        onProceed={onDelete}
                        prompt={i18n.playbook.deletePrompt}
                        isActive={deleteIntent}
                    />
                </>
            )}
        </Footer>
    );
};

const sortByMostRecent = (templates: Bizly.EventTemplate[]) =>
    templates.slice().sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1));

const { PERSONAL, SHARED } = ESECTION;

export default function Playbook() {
    const navigate = useNavigate();
    const { playbookId: paramPlaybookId } = useParams<{ playbookId: string }>();
    const playbookId = useMemo(() => paramPlaybookId ?? '', [paramPlaybookId]);
    const { templates, loading: loadingTemplates } = useEventTemplates();
    const { isTeamAdmin, user } = useUser();
    const { modalShown: createModalShown, showModal: showCreateModal, hideModal: hideCreateModal } = useShowModal();

    const [globalTemplates, setGlobalTemplates] = useState<Bizly.EventTemplate[]>([]);

    const arrangeTemplates = (allTemplates: Bizly.EventTemplate[]) => {
        const { global = [] } = groupBy(sortByMostRecent(allTemplates), 'category');
        const globalTempsWithoutBlank = global.filter(({ id }) => !isBlankTemplateId(id));

        return setGlobalTemplates(globalTempsWithoutBlank.slice(0, 5));
    };

    React.useEffect(() => arrangeTemplates(templates), [templates]);

    const template = find(templates, ['id', Number(playbookId)]);
    const playbookFull = usePlaybooks(selectPlaybook(playbookId));
    const playbook = template
        ? {
              id: template.id,
              name: template.name,
              tags: template.tags,
              purpose: playbookFull?.purpose ?? template.objectiveDescription,
              image: template.briefImageLarge ?? template.briefImageSmall,
              description: template.longDescription ?? template.shortDescription,
          }
        : {};

    const {
        modalShown: protocolModalShown,
        showModal: showProtocolModal,
        hideModal: hideProtocolModal,
    } = useShowModal();

    const onCreateMeeting = async () => {
        if (user.team?.authMeetingCreateRedirect) return showProtocolModal();
        if (!playbook.id) return;
        if (user.featureFlags?.createMeetingsFlow) {
            return navigate(`/events/new`, { state: { playbookId: playbook.id } });
        }

        showCreateModal();
    };

    const isUsersPlaybook = template?.category === PERSONAL && template?.userId === user.id;
    const canShare = isUsersPlaybook && isTeamAdmin;
    const canDelete = isUsersPlaybook || (isTeamAdmin && template?.category === SHARED);

    const sharePlaybook = () => {
        return playbook.id && eventTemplatesActions.share(playbook.id);
    };

    const deletePlaybook = () => {
        return (
            playbook.id &&
            eventTemplatesActions.delete(playbook.id).then(() => {
                navigate('/playbooks');
            })
        );
    };

    const goToEdit = () => {
        navigate(`/playbooks/${playbook.id}/edit`);
    };

    if (!template) return <SpinnerOverlay />;

    return (
        <>
            <LoadPlaybook id={playbookId} />
            <LoadPlaybookOptions />
            <Spacer smallish />
            <PageHeadline>{playbook.name}</PageHeadline>
            <Spacer larger />
            <Row itemSpacing="largest" alignItems="stretch">
                <PlaybookDetails playbookId={playbookId} />
                <PlaybookRecommendationsCol globalTemplates={globalTemplates} isLoading={loadingTemplates} />
            </Row>
            <FooterButtons
                canShare={canShare}
                canEdit={canDelete}
                canDelete={canDelete}
                onCreateMeeting={onCreateMeeting}
                sharePlaybook={sharePlaybook}
                goToEdit={goToEdit}
                deletePlaybook={deletePlaybook}
            />

            {createModalShown && (
                <EventCreateModal {...(template?.id ? { template } : {})} onClose={hideCreateModal} fromPlaybook />
            )}
            {protocolModalShown && <EventCreateProtocolModal onBackdropClick={hideProtocolModal} />}
        </>
    );
}
