import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { EColors, getColor } from 'theme';
import { i18n } from 'translation';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';

const FiltersButtonButton = styled(Button)`
    white-space: nowrap;
    width: fit-content;
    min-width: 5.25rem;
    border-color: ${getColor(EColors.grey)};
    color: ${getColor(EColors.pureBlack)};

    &.MuiButton-root {
        padding: 5px 15px;
        border-radius: 4px;
        border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    }
`;

type TFiltersButton = {
    toggleFiltersMenu: (event: React.MouseEvent<HTMLElement>) => void;
    filterCount: number;
};

const FiltersButton = forwardRef(
    ({ toggleFiltersMenu, filterCount }: TFiltersButton, ref: React.Ref<HTMLButtonElement>) => {
        const hasFilters = filterCount > 0;
        return (
            <FiltersButtonButton
                onClick={toggleFiltersMenu}
                ref={ref}
                variant="outlined"
                startIcon={<FilterListIcon />}
            >
                {hasFilters ? i18n.venue.filtersWithCount(filterCount) : i18n.venue.filters}
            </FiltersButtonButton>
        );
    }
);

export default FiltersButton;
