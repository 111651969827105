import { Dialog, DialogContent } from '@material-ui/core';
import { loadEventRegistrationInfo, submitEventRegistrationInfo } from 'api';
import EventRegistrationCard from 'components/RSVP/EventRegistrationCard';
import RSVPHeader from 'components/RSVP/RSVPHeader';
import {
    getAttendeeBaseError,
    getAttendeeCustomQuestionsErrorMessage,
    getAttendeeDefaultsError,
    getAttendeeRoomBlockErrorMessge,
} from 'components/RSVP/utils';
import ResponsiveMetaTag from 'components/ResponsiveMetaTag';
import { SpinnerOverlay } from 'components/Spinner';
import useShowModal from 'hooks/useShowModal';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { mergeDateTime } from 'utils/date_util';
import { Column, FixedBackground } from '../ui';

const CARD_WIDTH = 600;

const CenteredColumn = styled(Column)`
    padding: 7rem 0;
    margin: auto;
`;

const DialogContentWithTeamBackground = styled(DialogContent)<{ eventTeam: Bizly.Team }>`
    background-color: ${({ eventTeam, theme: { getColor, EColors } }) =>
        eventTeam?.color ? eventTeam.color : getColor(EColors.pureWhite)};
`;

type EventData = {
    event: Bizly.Event & {
        startDate: string;
        startTime: string;
        endDate: string;
        endTime: string;
        imageUrl: string;
        address: string;
        cityState: string;
    };
    attendee: Bizly.EventAttendee;
    team: Bizly.Team & { color: string };
    formSettings: Bizly.FormSetting;
    viewportWidth: number;
};

type MatchParams = {
    eventId: string;
    slugId: string;
};

const EventRegistration = () => {
    const [eventData, setEventData] = useState<EventData>({} as EventData);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const { modalShown, showModal, hideModal } = useShowModal();

    const { eventId, slugId } = useParams<MatchParams>();

    useEffect(() => {
        const load = async () => {
            try {
                const { success, attendee, event, formSettings, team } = await loadEventRegistrationInfo(
                    eventId,
                    slugId
                );

                if (!success) {
                    throw new Error(i18n.communication.failedToLoadEventRegistrationInfo);
                }

                // Set custom questions for attendee
                if (Object.values(formSettings?.customQuestions).length) {
                    attendee.customQuestions = Object.values(formSettings.customQuestions);
                }

                // Set correct logo
                team.imageUrl = event?.logoUrl || team?.imageUrl;

                setEventData({ attendee, event, formSettings, team, viewportWidth: 0 });
            } catch (e) {
                enqueueSnackbar(e, { variant: 'error' });
            }
        };

        load();
    }, [eventId, slugId, setEventData, enqueueSnackbar]);

    const handleFormValidation = () => {
        const error =
            getAttendeeBaseError(eventData.attendee) ||
            getAttendeeDefaultsError(eventData.attendee, eventData.formSettings) ||
            getAttendeeCustomQuestionsErrorMessage(eventData.attendee) ||
            getAttendeeRoomBlockErrorMessge(eventData.attendee);
        if (error) {
            throw new Error(error);
        }
    };

    const handleSubmit = async () => {
        setIsSaving(true);

        try {
            handleFormValidation();

            const result = await submitEventRegistrationInfo(eventId, slugId, eventData.attendee);

            if (!result.success) {
                throw new Error(result.message);
            }

            showModal();
            enqueueSnackbar(i18n.communication.successfullyRegisteredForEvent, { variant: 'success' });
        } catch (error: any) {
            enqueueSnackbar(error?.message ?? error, { variant: 'error' });
        }

        setIsSaving(false);
    };

    const handleChange = (
        attendee: Bizly.EventAttendee & {
            value: {
                [key: string]: Bizly.EventAttendee;
            };
        }
    ) => {
        const { value } = attendee;

        setEventData((prev: any) => ({ ...prev, attendee: { ...prev.attendee, ...value } }));
    };

    const parcelDates = {
        startDate: eventData.event?.startDate,
        startTime: eventData.event?.startTime,
        endDate: eventData.event?.endDate,
        endTime: eventData.event?.endTime,
    };

    return !eventData.event ? (
        <SpinnerOverlay />
    ) : (
        <>
            <ResponsiveMetaTag />
            <Column style={{ position: 'relative', display: 'block' }}>
                <CenteredColumn style={{ alignItems: 'center' }}>
                    <FixedBackground backgroundColor={eventData.team ? eventData.team.color : 'transparent'} />
                    <Column style={{ width: CARD_WIDTH }}>
                        <EventRegistrationCard
                            team={eventData.team}
                            image={eventData.event?.imageUrl}
                            name={eventData.event?.name}
                            description={eventData.event?.description}
                            parcelDates={parcelDates}
                            location={eventData.event?.location}
                            address={eventData.event?.address}
                            cityState={eventData.event?.cityState}
                            isPreview={false}
                            onChange={handleChange}
                            handleSubmit={handleSubmit}
                            saveButtonDisabled={isSaving}
                            formSettings={eventData.formSettings}
                            attendee={eventData.attendee}
                            timezone={eventData.event?.timeZone}
                        />
                    </Column>
                </CenteredColumn>
            </Column>

            <Dialog open={modalShown} maxWidth="md" onBackdropClick={hideModal}>
                <DialogContentWithTeamBackground eventTeam={eventData.team}>
                    <RSVPHeader
                        name={eventData.event?.name}
                        startsAt={
                            eventData.event?.startDate && eventData.event?.startTime
                                ? mergeDateTime(eventData.event?.startDate, eventData.event?.startTime)
                                : ''
                        }
                        endsAt={
                            eventData.event?.endDate &&
                            eventData.event?.endTime &&
                            mergeDateTime(eventData.event?.endDate, eventData.event?.endTime)
                        }
                        cancelledAt={''}
                        description={eventData.event?.description}
                        location={eventData.event?.location}
                        virtualMeeting={eventData.event?.virtualMeeting}
                        timeZone={eventData.event.timeZone || 'UTC'}
                        attending={true}
                        submitted={true}
                        cardWidth={600}
                    />
                </DialogContentWithTeamBackground>
            </Dialog>
        </>
    );
};

export default EventRegistration;
