export const HEADER_HEIGHT = 80;
export const TALL_HEADER_HEIGHT = 108;
export const getHeaderHeight = (transparentHeader?: boolean) =>
    transparentHeader ? TALL_HEADER_HEIGHT : HEADER_HEIGHT;

export const getNotificationLink = (notification: BizlyAPI.Notification) => {
    switch (notification.actionType) {
        case 'go_to_contracting':
            return `/event/${notification.event.id}/venue/contracting`;
        case 'go_to_proposal_details':
            return `/event/${notification.event.id}/venue/proposal/${notification.proposal.id}`;
        case 'go_to_all_proposals':
            return `/event/${notification.event.id}/venue/inquiries`;
        case 'open_message':
            const venueRoute =
                notification.actionSubType === 'via_contracting'
                    ? 'contracting'
                    : `proposal/${notification.proposal.id}`;
            return `/event/${notification.event.id}/venue/${venueRoute}/messages`;
        case 'open_spend_tracker':
            return `/event/${notification.event.id}?trackspending=true`;
        case 'open_inquiry':
            return `/event/${notification.event.id}/venue/inquiries?open_inquiry=${notification.venue.id}`;
    }
    return '/';
};
