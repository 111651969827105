import Popover, { PopoverProps } from '@material-ui/core/Popover';
import { getProposalMessages, sendProposalMessage } from 'api/venues';
import { uploadFile } from 'cloudinary';
import { SpinnerOverlay } from 'components/Spinner';
import { useAsync } from 'hooks/useAsync';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column } from 'ui';
import InputBoxBase from './InputBox';
import MessageHeadBase from './MessageHead';
import MessageWindowBase from './MessageWindow';

const MessageHead = styled(MessageHeadBase)``;
const MessageWindow = styled(MessageWindowBase)``;
const InputBox = styled(InputBoxBase)``;

const Container = styled(Column)`
    justify-content: flex-end;
    position: relative;
    height: 501px;
    width: 382px;
    border-radius: 8px;
    box-shadow: 0 2px 44px 0 ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.1)};
    overflow: hidden;

    > ${MessageHead}, > ${InputBox} {
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
    }

    > ${MessageWindow} {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
    }
`;

type MessageCardProps = { proposalId?: number | string };

export const MessageCard = ({ proposalId }: MessageCardProps) => {
    const [pending, setPending] = useState(false);
    const [failed, setFailed] = useState(false);
    // const [uploadError, setUploadError] = useState(false) // TODO: Use this to display an error if an upload attempt fails; need designs for that
    const [messages, setMessages] = useState<BizlyAPI.VenueMessage[]>();
    const [venue, setVenue] = useState<BizlyAPI.ProposalNotesVenue>();
    const [message, setMessage] = useState('');

    useAsync(
        useCallback(
            () =>
                getProposalMessages(proposalId).then(({ venue, notes }) => {
                    setMessages(notes);
                    setVenue(venue);
                }),
            [proposalId]
        )
    );

    const handleUpload = async (files: File[]) => {
        setFailed(false);
        // setUploadError(false) // TODO: Enable once upload error message designs are ready to be added
        const file = files[0] || {};

        // TODO: We should define what file types we want to white/black list, and what filesize cap we are willing to setVenue
        // TODO: We should add a spin wheel to the upload process to display in while an upload is taking place
        try {
            const attachment = await uploadFile(file);
            const fileType = (file.type.split('/')[1] || i18n.common.unknown).toUpperCase();
            const payload = {
                contentType: 'attachment',
                url: attachment.url,
                publicId: attachment.cloudinaryId,
                size: file.size,
                fileName: file.name,
                fileType,
                mimeType: file.type,
            } as const;

            const sentAttachment = await sendProposalMessage(proposalId, payload);

            setMessages(prevMessages => [sentAttachment.note, ...(prevMessages ?? [])]);
            setPending(false);
        } catch (error) {
            setFailed(true);
            // setUploadError(true) // TODO: Enable once upload error message designs are ready to be added
            console.error(`Error sending attachment ${error}`);
        }
    };

    // TODO: Move these functions inside the InputBox.tsx file
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    };

    // TODO: Move these functions inside the InputBox.tsx file
    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        setPending(true);
        setFailed(false);

        try {
            const sentMessage = await sendProposalMessage(proposalId, {
                contentType: 'text',
                text: message,
            });

            setMessages(prevMessages => [sentMessage.note, ...(prevMessages ?? [])]);
            setMessage('');

            setPending(false);
        } catch (error) {
            setPending(false);
            setFailed(true);
            console.error(`Error sending message: ${error}`);
        }
    };

    return (
        <Container>
            {venue && messages ? (
                <>
                    <MessageHead venue={venue} pending={pending} />
                    <MessageWindow messages={messages} failed={failed} />
                    <InputBox
                        message={message}
                        handleUpload={handleUpload}
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                    />
                </>
            ) : (
                <SpinnerOverlay />
            )}
        </Container>
    );
};

type FlyoutProps = {
    anchor: PopoverProps['anchorEl'];
    onClose: () => void;
    proposalId: string;
};

export const MessageCardFlyout = ({ anchor, onClose, proposalId }: FlyoutProps) => {
    return (
        <Popover
            anchorEl={anchor}
            open={!!anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={onClose}
        >
            <MessageCard proposalId={proposalId || ''} />
        </Popover>
    );
};
