import FiltersButton from 'components/FilterButton';
import { useCallback, useMemo, useState } from 'react';
import { useUser } from '../../providers/user';
import { AlignedRow } from '../../ui';
import VenueSearchFilters, { TFilterValue } from './VenueSearchFilters';

type TFiltersRow = {
    filters: TFilterValue;
    setFilters: (newFilters: TFilterValue) => void;
    filterCount?: number;
    className?: string;
};

export default function FiltersPanel({ filters, setFilters, filterCount = 0, className }: TFiltersRow) {
    const [showFiltersMenu, setShowFiltersMenu] = useState(false);
    const toggleFiltersMenu = useCallback(() => setShowFiltersMenu(prevShowFiltersMenu => !prevShowFiltersMenu), []);
    const { user } = useUser();
    const teamName = useMemo(() => (user.team ? user.team.name : ''), [user.team]);

    return (
        <AlignedRow className={className}>
            <FiltersButton toggleFiltersMenu={toggleFiltersMenu} filterCount={filterCount} />
            <VenueSearchFilters
                filters={filters}
                onFilterChange={setFilters}
                open={showFiltersMenu}
                onClose={toggleFiltersMenu}
                teamName={teamName}
            />
        </AlignedRow>
    );
}
