// Pinched from https://css-tricks.com/image-upload-manipulation-react and migrated to new react-dropzone api
// Requires React >= 16.8.0

import pick from 'lodash/pick';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

import { uploadFile } from '../cloudinary';
import { Button } from '../ui';
import { Spinner } from './Spinner';

export class CloudinaryUploader extends Component {
    state = {
        pending: false,
        uploadedFile: null,
        uploadedFileCloudinaryUrl: '',
    };

    onImageDrop = files => {
        if (files.length === 0) return;
        this.setState({
            uploadedFile: files[0],
        });

        this.handleImageUpload(files[0]);
    };

    async handleImageUpload(file) {
        const { onUploadError, onUploadStart, onUploadSuccess } = this.props;
        this.setState({
            pending: true,
        });

        onUploadStart && onUploadStart();

        await uploadFile(file)
            .then(successObj => {
                this.setState({
                    uploadedFileCloudinaryUrl: successObj.url,
                });
                const cloudinaryResponse = pick(successObj, ['cloudinaryId', 'title', 'url']);
                onUploadSuccess && onUploadSuccess(cloudinaryResponse);
            })
            .catch(message => {
                console.error(message);
                onUploadError && onUploadError(message);
            });

        this.setState({
            pending: false,
        });
    }

    render() {
        const { ctaLabel, children, accept, disabled, formButton } = this.props;
        const { pending } = this.state;
        return (
            <form>
                <div>
                    {!pending ? (
                        <Dropzone onDrop={this.onImageDrop} multiple={false} accept={accept}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        {!disabled && (
                                            <>
                                                <input {...getInputProps()} />
                                                {children ? (
                                                    children
                                                ) : formButton ? (
                                                    formButton
                                                ) : (
                                                    <Button onClick={e => e.preventDefault()}>
                                                        {ctaLabel || 'Upload'}
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    ) : (
                        <Spinner suppressMargin={true} />
                    )}
                </div>
            </form>
        );
    }
}
