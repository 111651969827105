import * as Sentry from '@sentry/react';
import { getEvents } from 'api';
import { useQuery } from 'react-query';
import { GetEventsFilters } from 'stores/events/eventsStore';
export const useEventsQuery = ({ query = '', editable }: Partial<GetEventsFilters>) => {
    const result = useQuery({
        queryKey: ['events', query, editable],
        queryFn: () => getEvents(query, editable),
        select: data => {
            if (!data.success) {
                console.warn('No events returned.');
                return [];
            }

            return data.events;
        },
        onError: (error: Error) => {
            console.error('Error fetching events:', error);

            Sentry.captureException(error, {
                tags: {
                    component: 'useEventsQuery',
                },
            });
        },
    });

    return result;
};
