import { Tooltip } from '@material-ui/core';
import Pill from 'components/ui/Pill';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EColors } from 'theme';

const ClickBlocker = styled.div.attrs(() => ({ onClick: (e: React.MouseEvent) => e.stopPropagation() }))``;

const STATUS_COLORS: { [key in BizlyAPI.MeetingStatusCode]: EColors } = {
    new: EColors.activeEvent,
    inquiry_draft: EColors.contractPendingStatus,
    inquiry_submitted: EColors.submittedInquiryStatus,
    inquiry_sent: EColors.submittedInquiryStatus,
    inquiry_response: EColors.receivedProposalStatus,
    proposal_accepted: EColors.contractPendingStatus,
    booked: EColors.bookedVenueStatus,
    booking_over: EColors.contractPendingStatus,
    booking_tracked: EColors.completedEvent,
    cancelled: EColors.cancelledOrRejectedStatus,
};

export const STATUS_LABELS: { [key in BizlyAPI.MeetingStatusCode]: string } = {
    new: 'Plan Event',
    inquiry_draft: 'Submit Inquiry',
    inquiry_submitted: 'Inquiry Submitted',
    inquiry_sent: 'Inquiry Submitted',
    inquiry_response: 'Review Responses',
    proposal_accepted: 'Upload Contract',
    booked: 'Booked',
    booking_over: 'Update Final Spend',
    booking_tracked: 'Meeting Complete',
    cancelled: 'Cancelled',
};

const STATUS_LINK: { [key in BizlyAPI.MeetingStatusCode]?: (meeting: BizlyAPI.Meeting | Bizly.Event) => string } = {
    inquiry_draft: meeting => `event/${meeting.id}/venue/inquiries/new`,
    inquiry_submitted: meeting => `event/${meeting.id}/venue/inquiries`,
    inquiry_sent: meeting => `event/${meeting.id}/venue/inquiries`,
    inquiry_response: meeting => `event/${meeting.id}/venue/inquiries`,
    proposal_accepted: meeting => `event/${meeting.id}/venue/contracting`,
};

export const LinklessStatusPill = ({
    status,
    className,
    squared,
}: {
    status: BizlyAPI.MeetingStatus;
    className?: string;
    squared?: boolean;
}) => {
    const statusCode = status.code;

    return (
        <Pill
            className={className}
            $color={STATUS_COLORS[statusCode]}
            label={STATUS_LABELS[statusCode]}
            noShadow
            centerText
            squared={squared}
        />
    );
};

export default function StatusPill({
    meeting,
    tooltip,
    className,
    squared,
}: {
    meeting: BizlyAPI.Meeting | Bizly.Event;
    tooltip?: string | null;
    className?: string;
    squared?: boolean;
}) {
    const { status } = meeting;
    if (!status) return null;

    const statusCode = status.code;
    const getLink = STATUS_LINK[statusCode];

    const pill = <LinklessStatusPill status={status} squared={squared} {...(!getLink && { className })} />;

    const withLink = getLink ? (
        <ClickBlocker className={className}>
            <Link to={getLink(meeting)}>{pill}</Link>
        </ClickBlocker>
    ) : (
        pill
    );

    return tooltip ? <Tooltip title={tooltip}>{withLink}</Tooltip> : withLink;
}
