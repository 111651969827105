import { Tooltip } from '@material-ui/core';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { VenueCardPopover } from 'components/VenueCard';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Button } from 'components/Ui-V2/Button/Button';
import { HEADER_HEIGHT } from 'utils/header';
import { tzMoment } from 'utils/moment';
import { BaseVenueTile, VenueTileProps } from './VenueTile';

const TileWrapper = styled.div`
    border-radius: 8px;
    border: 1px solid;
    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    padding: 8px;
    height: 100%;

    position: sticky;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    top: ${HEADER_HEIGHT}px;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const TileButtons = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

type TActions = {
    inquiryVenue?: BizlyAPI.Inquiry.Venue & { submittedAt?: string };
    viewInquiry: () => void;
    onNudge?: () => void;
    inquiryMenuOptions?: { label: string; handler: () => void }[];
};

type InquiryVenueTileProps = VenueTileProps & TActions;

const InquiryVenueTile = (props: InquiryVenueTileProps) => {
    const { inquiryVenue, viewInquiry, onNudge, inquiryMenuOptions = [], ...tileProps } = props;
    const tooltipContent = inquiryVenue?.nudgedAt
        ? i18n.venue.inquiry.lastNudged(tzMoment(inquiryVenue.nudgedAt).fromNow())
        : i18n.venue.inquiry.nudgeDescription;

    let nudgeDisabled = false;
    let showNudge = false;
    if (tileProps.venue?.status === 'Submitted') {
        const submittedAt = tzMoment(inquiryVenue?.submittedAt);
        showNudge = submittedAt.add(24, 'h').isBefore(tzMoment()); // check if the submission time is BEFORE 24 hours ago. If yes, "showNudge" should be false.
        const lastNudgedAt = tzMoment(inquiryVenue?.nudgedAt || inquiryVenue?.submittedAt);
        nudgeDisabled = lastNudgedAt.add(24, 'h').isAfter(tzMoment()); // check if the last nudge is BEFORE 24 hours ago. If yes, "nudgeDisabled" should be true.
    }

    return (
        <TileWrapper className="sticky">
            <BaseVenueTile {...tileProps} hideButtons />
            <TileButtons>
                <Button onClick={viewInquiry}>{i18n.venue.inquiry.viewInquiry}</Button>
                {showNudge ? (
                    <Tooltip title={tooltipContent} placement="bottom">
                        <div>
                            <Button
                                variant="outlined"
                                endIcon={<CallMadeIcon />}
                                disabled={nudgeDisabled}
                                onClick={onNudge}
                            >
                                {i18n.venue.inquiry.nudge}
                            </Button>
                        </div>
                    </Tooltip>
                ) : inquiryMenuOptions.length > 0 ? (
                    <VenueCardPopover options={inquiryMenuOptions} hovered />
                ) : null}
            </TileButtons>
        </TileWrapper>
    );
};

export default InquiryVenueTile;
