import CurrencyField from 'components/ProposalForm/CurrencyField';

export const fields = (currencyCode: string) => ({
    total: {
        type: 'text',
        options: { numberType: 'currency', currency: currencyCode },
    },
    guestrooms: {
        type: 'text',
        options: { numberType: 'currency', currency: currencyCode },
    },
    roomRental: {
        type: 'text',
        options: { numberType: 'currency', currency: currencyCode },
    },
    catering: {
        type: 'text',
        options: { numberType: 'currency', currency: currencyCode },
    },
    av: {
        type: 'text',
        options: { numberType: 'currency', currency: currencyCode },
    },
    misc: {
        type: 'text',
        options: { numberType: 'currency', currency: currencyCode },
    },
    currencyCode: {
        type: CurrencyField,
        disabled: true,
        perRow: 'auto',
    },
});

const totalCost = {
    type: 'nested',
    prompt: 'Final Total (incl. taxes & fees)',
    perRow: 2,
    schema: [
        {
            key: 'totalAndCurrency',
            fields: ['total', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};
const grCost = {
    type: 'nested',
    prompt: 'Final Guest Room Cost',
    perRow: 2,
    schema: [
        {
            key: 'grAndCurrency',
            fields: ['guestrooms', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};
const roomCost = {
    type: 'nested',
    prompt: 'Final Room Rental',
    perRow: 2,
    schema: [
        {
            key: 'roomsAndCurrency',
            fields: ['roomRental', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};
const cateringCost = {
    type: 'nested',
    prompt: 'Final Food & Beverage Cost',
    perRow: 2,
    schema: [
        {
            key: 'cateringAndCurrency',
            fields: ['catering', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};
const avCost = {
    type: 'nested',
    prompt: 'Final A/V Cost',
    perRow: 2,
    schema: [
        {
            key: 'avAndCurrency',
            fields: ['av', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};
const miscCost = {
    type: 'nested',
    prompt: 'Misc. Costs',
    perRow: 2,
    schema: [
        {
            key: 'miscAndCurrency',
            fields: ['misc', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};

export const schema = [
    {
        key: 'rowOne',
        fields: [avCost, miscCost],
        spacing: 'medium',
    },
    {
        key: 'rowTwo',
        fields: [grCost, roomCost],
        spacing: 'medium',
    },
    {
        key: 'rowThree',
        fields: [cateringCost, totalCost],
        spacing: 'small',
    },
];

export const schemaWithoutGuestrooms = [
    {
        key: 'rowOne',
        fields: [avCost, miscCost],
        spacing: 'medium',
    },
    {
        key: 'rowTwo',
        fields: [cateringCost, roomCost],
        spacing: 'medium',
    },
    {
        key: 'rowThree',
        fields: [totalCost],
        spacing: 'small',
    },
];
