import { Spinner } from 'components/Spinner';
import Communication from 'pages/Communication/Communication';
import { useCreatePlaybook } from './store';

export default function CommunicationForm() {
    const { id: playbookId, parcelLoadKey } = useCreatePlaybook();

    if (!playbookId) return <Spinner />;

    return (
        <>
            <Communication playbookId={playbookId} key={parcelLoadKey} />
        </>
    );
}
