import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { Paper, Typography, styled as muiStyled } from '@mui/material';
import { getBudgetApprovalRequests } from 'api/inquiry';
import colorFns from 'colorFns';
import { Spinner } from 'components/Spinner';
import ExpandableText from 'components/ui/ExpandableText';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { numberWithCommas } from 'utils';
import { tzMoment } from 'utils/moment';

type RowData = {
    id: number;
    meetingName: string;
    ownerName: string;
    venueName: string;
    proposalTotal: number;
    proposalLink: string;
    status: string;
    actionDate: string | null;
    notes: string;
};
type OrderBy = keyof RowData;
type Order = 'asc' | 'desc';

const ColoredLink = styled(Link)`
    color: ${colorFns.primaryAction};
    &:hover {
        color: ${colorFns.primaryActionHover};
    }
`;

const PaddedTableCell = styled(TableCell)`
    padding: 1rem;
`;

const StyledTableHead = muiStyled(TableHead)(({ theme: { getColor, EColors } }) => ({
  background: getColor(EColors.drWhite),
}));

const headCells = [
    {
        id: 'meetingName',
        disablePadding: true,
        label: i18n.approvals.meetingName,
        sortable: true,
    },
    {
        id: 'ownerName',
        disablePadding: false,
        label: i18n.approvals.meetingOwner,
        sortable: true,
    },
    {
        id: 'venueName',
        disablePadding: false,
        label: i18n.approvals.venueName,
        sortable: true,
    },
    {
        id: 'proposalTotal',
        disablePadding: false,
        label: i18n.approvals.proposalTotal,
        sortable: true,
    },
    {
        id: 'status',
        disablePadding: false,
        label: i18n.approvals.status,
        sortable: true,
    },
    {
        id: 'actionDate',
        disablePadding: false,
        label: i18n.approvals.actionDate,
        sortable: true,
    },
    {
        id: 'reason',
        disablePadding: false,
        label: i18n.approvals.reason,
        sortable: false,
    },
    {
        id: 'proposalLink',
        disablePadding: true,
        label: i18n.approvals.proposalLink,
        sortable: false,
    },
];

const statusMap = {
    Pending: 0,
    Rejected: 1,
    Approved: 2,
};
type StatusKey = keyof typeof statusMap;

function descendingComparator(a: RowData, b: RowData, orderBy: OrderBy) {
    if (orderBy === 'status') {
        return statusMap[b.status as StatusKey] - statusMap[a.status as StatusKey];
    } else if (orderBy === 'actionDate') {
        if (!a.actionDate) {
            return 1;
        }
        if (!b.actionDate) {
            return -1;
        }
        const momentA = tzMoment(a.actionDate);
        const momentB = tzMoment(b.actionDate);
        if (momentA.isAfter(momentB)) {
            return -1;
        } else {
            return 1;
        }
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
}

function getComparator(order: Order, orderBy: OrderBy) {
    return order === 'desc'
        ? (a: RowData, b: RowData) => descendingComparator(a, b, orderBy)
        : (a: RowData, b: RowData) => -descendingComparator(a, b, orderBy);
}

export function BudgetApprovalsTable() {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<OrderBy>('status');
    const [actionTableItems, setActionTableItems] = useState<BizlyAPI.BudgetApproval[]>();

    useEffect(() => {
        const load = async () => {
            const { success, budgetApprovalRequests } = await getBudgetApprovalRequests();

            if (success) {
                setActionTableItems(budgetApprovalRequests);
            }
        };

        load();
    }, []);

    const handleRequestSort = (property: OrderBy) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const renderHeader = () => (
        <StyledTableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <PaddedTableCell
                        key={headCell.id}
                        {...(headCell.sortable
                            ? {
                                  sortDirection: orderBy === headCell.id ? order : false,
                              }
                            : {})}
                    >
                        {headCell.sortable ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={() => handleRequestSort(headCell.id as OrderBy)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        ) : (
                            <Typography fontSize={14} component='label' fontWeight={500}>{headCell.label}</Typography>
                        )}
                    </PaddedTableCell>
                ))}
            </TableRow>
        </StyledTableHead>
    );

    const rows: RowData[] = useMemo(() => {
        const items =
            actionTableItems?.map((item: BizlyAPI.BudgetApproval) => ({
                id: item.id,
                meetingName: item.event.name,
                ownerName: `${item.requestedBy.firstName} ${item.requestedBy.lastName}`,
                venueName: item.venue.name,
                proposalTotal: item.proposal.estimatedTotal,
                proposalLink: `/event/${item.event.id}/venue/proposal/${item.proposal.id}`,
                status: item.status,
                actionDate:
                    item.status === 'Pending' ? null : item.status === 'Approved' ? item.approvedAt : item.rejectedAt,
                notes:
                    item.status === 'Pending'
                        ? '-'
                        : item.status === 'Approved'
                          ? i18n.approvals.newBudget
                          : item.rejectionReason ?? '',
            })) ?? [];
        items.sort(getComparator(order, orderBy));
        return items;
    }, [actionTableItems, order, orderBy]);

    if (!actionTableItems) {
        return <Spinner />;
    }
    return (
      <Paper>
        <Table className="table" size="medium">
            {renderHeader()}
            <TableBody>
                {rows.map((row: RowData) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <PaddedTableCell>{row.meetingName}</PaddedTableCell>
                            <PaddedTableCell>{row.ownerName}</PaddedTableCell>
                            <PaddedTableCell>{row.venueName}</PaddedTableCell>
                            <PaddedTableCell>${numberWithCommas(row.proposalTotal)}</PaddedTableCell>
                            <PaddedTableCell>{row.status}</PaddedTableCell>
                            <PaddedTableCell>
                                {row.actionDate ? tzMoment(row.actionDate).format('L') : '-'}
                            </PaddedTableCell>
                            <PaddedTableCell style={{ maxWidth: 150 }}>
                                <ExpandableText content={row.notes} />
                            </PaddedTableCell>
                            <PaddedTableCell align="center">
                                <ColoredLink to={row.proposalLink} target="_blank">
                                    {i18n.button.view}
                                </ColoredLink>
                            </PaddedTableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
      </Paper>
    );
}
