import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import { searchVenues } from './venues';

const PROBE_PAGE_SIZE = 10;
const MIN_BATCH_SIZE = 30;
const MAX_BATCH_SIZE = 150;

export default class VenueSearchFetcher {
    private eventId: string | number | undefined;
    private facets: BizlyAPI.VenueFacets;
    private perPage: number;

    private fetchedPreferred = false;
    private fetchedNonPreferred = false;
    private curPage = 1;
    private venues: Bizly.Venue[] = [];

    constructor(
        eventId: string | number | undefined,
        { facets = {}, perPage = 10 }: Partial<{ facets: BizlyAPI.VenueFacets; perPage: number }>
    ) {
        this.eventId = eventId;
        this.facets = facets;
        this.perPage = perPage;

        // ignore non-preferred if search is only for preferred
        if (facets && facets.preferredOnly === true) {
            this.fetchedNonPreferred = true;
        }
    }

    hasMore = () => {
        const { fetchedPreferred, fetchedNonPreferred, venues, curPage, perPage } = this;

        return !fetchedPreferred || !fetchedNonPreferred || !!venues[(curPage - 1) * perPage];
    };

    getNextPage = (() => {
        let currentPromise: Promise<Bizly.Venue[]> | null = null;

        return () => {
            if (currentPromise) {
                return currentPromise;
            }

            currentPromise = this.fetchNextPage().then(venues => {
                currentPromise = null;
                return venues;
            });
            return currentPromise;
        };
    })();

    private fetchNextPage = async () => {
        // try preferred first
        if (!this.fetchedPreferred) {
            await this.fetchPreferred();
        }

        let page = this.venues.slice((this.curPage - 1) * this.perPage, this.curPage * this.perPage);
        // can fulfill?
        if (page.length === this.perPage) {
            this.curPage++;
            return page;
        }

        // need more?
        if (!this.fetchedNonPreferred) {
            await this.fetchNonPreferred();
        }

        page = this.venues.slice((this.curPage - 1) * this.perPage, this.curPage * this.perPage);
        this.curPage++;
        return page;
    };

    private appendNewVenues(newVenues: Bizly.Venue[]) {
        this.venues = this.venues.concat(newVenues);
    }

    // helper to search easily
    private search = async (page: number, { preferredOnly, perPage }: { preferredOnly?: boolean; perPage?: number }) =>
        await searchVenues(
            this.eventId,
            { ...this.facets, preferredOnly },
            {
                page,
                perPage: perPage || this.perPage,
            }
        );

    private fetchPreferred = async () => {
        // probe:
        const { venues, meta } = await this.search(1, {
            preferredOnly: true,
            perPage: PROBE_PAGE_SIZE,
        });

        let newVenues: Bizly.Venue[] = [];

        // if probe is all results:
        if (!meta.hasMorePages) {
            newVenues = venues;
        }

        // otherwise, batch pull:
        if (meta.hasMorePages) {
            const BATCH_SIZE = Math.min(Math.max(MIN_BATCH_SIZE, Math.ceil(meta.total / 10)), MAX_BATCH_SIZE);
            const pages = range(1, meta.total / BATCH_SIZE + 1);

            const allVenues: Bizly.Venue[][] = await Promise.all(
                pages.map(async page => {
                    const { venues } = await this.search(page, {
                        preferredOnly: true,
                        perPage: BATCH_SIZE,
                    });
                    return venues;
                })
            );

            const allVenuesFlat = this.venues.concat(...allVenues);

            // lodash sortBy is stable, the results are already sorted by distance,
            // so we shouldn't disturb distance order when stratifying by rank
            // Infinity will place rankless or regular venues at the bottom
            newVenues = sortBy(allVenuesFlat, v => (v.preferenceCategory && v.preferenceCategory.rank) || Infinity);
        }

        this.fetchedPreferred = true;
        this.appendNewVenues(newVenues);
        return this.venues;
    };

    private fetchNonPreferred = (() => {
        let nonPreferredPage = 1;

        return async () => {
            const { venues, meta } = await this.search(nonPreferredPage, {
                perPage: this.perPage,
            });

            nonPreferredPage++;

            if (!meta.hasMorePages) {
                this.fetchedNonPreferred = true;
            }

            this.appendNewVenues(venues);
            return this.venues;
        };
    })();
}
