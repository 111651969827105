import TextField from '@mui/material/TextField';

import { i18n } from 'translation';
import { EventCreateForm, EventCreateFormErrors } from '../formSchema';
import { Description, StepContainer, Title, VerbiageAlert } from './styled';
import { Link } from 'ui';
import InfoIcon from '@mui/icons-material/Info';

type PropType = {
    values: EventCreateForm;
    onChange: (key: keyof EventCreateForm) => (value: unknown) => void;
    errors: EventCreateFormErrors;
};

const Step1 = ({ values, onChange, errors }: PropType) => {
    const eventNameChange = onChange('eventName');
    const objectiveDescriptionChange = onChange('eventDescription');

    const handleEventName = (event: any) => {
        eventNameChange(event.target.value);
    };

    const handleObjectiveDescription = (event: any) => {
        objectiveDescriptionChange(event.target.value);
    };

    return (
        <StepContainer>
            <Title>{i18n.homepage.createMeetingModal.objetiveTitle}</Title>
            <VerbiageAlert severity="info" icon={<InfoIcon fontSize="inherit" />}>
                {i18n.homepage.createMeetingModal.eventInfoDescription} <Link href='/playbooks'>{i18n.homepage.createMeetingModal.clickHere}</Link>
            </VerbiageAlert>
            <TextField
                multiline
                id="event-name"
                label={`${i18n.homepage.createMeetingModal.eventName} (required)`}
                variant="outlined"
                sx={{ width: '100%' }}
                value={values.eventName}
                onChange={handleEventName}
                error={errors.eventName}
            />
            <Description>{i18n.homepage.createMeetingModal.objectiveDescription}</Description>
            <TextField
                multiline
                id="objective-description"
                label={`${i18n.homepage.createMeetingModal.eventDescription}`}
                variant="outlined"
                sx={{ width: '100%' }}
                rows={4}
                value={values.eventDescription}
                onChange={handleObjectiveDescription}
                error={errors.eventDescription}
            />
        </StepContainer>
    );
};

export default Step1;
