import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import FileIcon from '../../images/icons/file.svg?react';
import { ExternalLink, RichTextCopy, Spacer } from '../../ui';
import pluckFileName from '../../utils/pluckFileName';
import Button from '../ui/Button';
import StepsRow from './StepsRow';

const Copy = styled(RichTextCopy)<any>`
    font-size: 15px;
    line-height: 1.47;

    ul {
        padding-inline-start: 1.2em;
    }
`;

const DocContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 324px;
`;

const DocRow = styled.div<{ hasDoc: boolean }>`
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: space-between;
    margin-bottom: 12px;

    div {
        display: flex;
        align-items: center;
        margin-left: 16px;

        p {
            font-size: 15px;
        }

        svg {
            color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryDisplayIcon)};
        }
    }

    ${({ hasDoc, theme: { getColor, EColors } }) =>
        hasDoc &&
        `
    background-color: ${getColor(EColors.listItemBackground)};

    button {
      margin-right: 16px;
    }
  `}
`;

type UploadSignedDocsProps = {
    number: number;
    openUploadModal: () => void;
    existingDocs: {
        contract1?: string;
        contract2?: string;
    };
    copy: string;
    readonly: boolean;
    documentOptional?: boolean;
};

const defaultCopy = i18n.venue.proposal.sign.defaultUploadCopy;

const UploadSignedDocs = forwardRef(
    (
        {
            number,
            openUploadModal,
            existingDocs,
            copy = defaultCopy,
            readonly,
            documentOptional = false,
        }: UploadSignedDocsProps,
        ref: React.Ref<HTMLDivElement>
    ) => {
        const hasDoc = !!existingDocs.contract1;
        return (
            <StepsRow number={number} headline={i18n.venue.proposal.sign.uploadHeadline} ref={ref}>
                <Copy dangerouslySetInnerHTML={{ __html: copy }} />
                {!documentOptional && (
                    <>
                        <Spacer />
                        <DocContainer>
                            <DocRow hasDoc={hasDoc}>
                                {existingDocs.contract1 && (
                                    <div>
                                        <FileIcon />
                                        <ExternalLink href={existingDocs.contract1} openInNewTab>
                                            {pluckFileName(existingDocs.contract1)}
                                        </ExternalLink>
                                    </div>
                                )}
                                {!readonly && (
                                    <Button width={hasDoc ? 81 : 99} onClick={openUploadModal} secondary={hasDoc}>
                                        {hasDoc ? i18n.button.replace : i18n.button.upload}
                                    </Button>
                                )}
                            </DocRow>
                            {existingDocs.contract2 && (
                                <DocRow hasDoc={true}>
                                    <div>
                                        <FileIcon />
                                        <ExternalLink href={existingDocs.contract2} openInNewTab>
                                            {pluckFileName(existingDocs.contract2)}
                                        </ExternalLink>
                                    </div>
                                    {!readonly && (
                                        <Button width={81} onClick={openUploadModal} secondary>
                                            {i18n.button.replace}
                                        </Button>
                                    )}
                                </DocRow>
                            )}
                        </DocContainer>
                    </>
                )}
            </StepsRow>
        );
    }
);

export default UploadSignedDocs;
