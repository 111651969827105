import { Grid } from '@material-ui/core';
import { copyParcel, createParcel, deleteParcel, loadAttendees, loadParcels } from 'api';
import { createPlaybookParcel, deletePlaybookParcel, getPlaybookParcels } from 'api/playbooks';
import ParcelMatchbox from 'components/Communication/CommunicationParcel';
import ParcelCreateModal from 'components/Communication/ParcelCreateModal';
import { DeleteConfirmationModal } from 'components/DeleteConfirmationModal';
import HowToPopper from 'components/HowToPopper/HowToPopper';
import { ProTip } from 'components/ProTip';
import { Spinner } from 'components/Spinner';
import { PageHeadline, ThinHeadline } from 'components/ui/Headline';
import useShowModal from 'hooks/useShowModal';
import { useSnackbar } from 'notistack';
import { PageDescription } from 'pages/BuildInquiry';
import { ParcelFormState, TEventParcelContext } from 'pages/EditParcel/EditParcel';
import { HOTEL_CONF, INVITE, NOTE, REGISTRATION_PAGE, SURVEY } from 'pages/EditParcel/utils';
import { createPlaybookActions } from 'pages/PlaybookWizard/store';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, MatchboxPlaceholder, Row, SpacedRow, Spacer } from 'ui';
import { padArray, parcelType } from 'utils';
import { Button } from 'components/Ui-V2/Button/Button'

const Main = styled(Column)`
    width: 100%;
`;

const ParcelContainer = styled(Column)`
    flex: 1;
`;

const Tips = styled(Column)`
    width: 27.5rem;

    div:not(:last-child) {
        margin-bottom: 1.5rem;
    }
`;

const Content = styled(Row)`
    justify-content: space-between;
    gap: 1.25rem;
`;

const CATEGORIES = {
    [REGISTRATION_PAGE]: i18n.communication.category.eventRegistration,
    [INVITE]: i18n.communication.category.invites,
    [NOTE]: i18n.communication.category.surveyNotes,
    [HOTEL_CONF]: i18n.communication.category.hotelConf,
} as const;

type CommunicationProps = {
    playbookId?: number | string;
    readonly?: boolean;
    asWizardModule?: boolean;
    showCreateButton?: boolean;
};

export default function Communication({ playbookId, readonly = false, showCreateButton = true }: CommunicationProps) {
    const { user, toggles } = useUser();
    const { event } = useEvent();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [deleteIntent, setDeleteIntent] = useState<Bizly.Parcel | null>(null);
    const [parcels, setParcels] = useState<Bizly.Parcel[]>([]);

    const { showModal, hideModal, modalShown } = useShowModal();

    const removeParcel = playbookId ? deletePlaybookParcel : deleteParcel;
    const id = playbookId ?? event.id;

    const editLinkDestination = (parcel: Bizly.Parcel) => {
        if (playbookId) {
            return `/playbooks/${playbookId}/edit/communication/edit/${parcelType(parcel)}/${parcel.id}`;
        }

        return `/event/${event.id}/communication/edit/${parcelType(parcel)}/${parcel.id}`;
    };

    const load = useCallback(async () => {
        try {
            if (playbookId) {
                const parcelsResponse = await getPlaybookParcels(playbookId);
                setParcels(parcelsResponse.parcels);
            } else {
                const parcelsResponse = await (loadParcels as (id: number | string) => Promise<Bizly.Parcel[]>)(id);
                setParcels(parcelsResponse);
            }
        } catch (e) {
            enqueueSnackbar(
                playbookId ? i18n.communication.error.fetchPlaybook(id) : i18n.communication.error.fetchMeeting(id)
            );
        }
    }, [enqueueSnackbar, id, playbookId]);

    useEffect(() => {
        load();
    }, [load]);

    const categorizedParcels = useMemo(() => {
        const result = {
            [REGISTRATION_PAGE]: [],
            [INVITE]: [],
            [NOTE]: [],
            [HOTEL_CONF]: [],
        } as Record<keyof typeof CATEGORIES, Bizly.Parcel[]>;
        const safeParcelsArray = parcels ? [...parcels] : [];
        safeParcelsArray.reverse();
        safeParcelsArray.forEach(parcel => {
            if (parcel.type) {
                if (parcel.type === SURVEY) {
                    result[NOTE].push(parcel);
                } else if (parcel.type === 'rsvp') {
                    result[INVITE].push(parcel);
                } else {
                    result[parcel.type].push(parcel);
                }
            }
        });
        return result;
    }, [parcels]);

    const handleCopyClick = async (parcel: Bizly.Parcel) => {
        if (!parcel.id) return;
        await copyParcel(parcel.id);
        load();
    };

    const handleDeleteClick = (parcel: Bizly.Parcel) => {
        setDeleteIntent(parcel);
    };

    const handleDeleteConfirm = async () => {
        if (!deleteIntent) return;
        await removeParcel(id, deleteIntent);
        load();
        setDeleteIntent(null);
    };

    const handleDeleteDismiss = () => {
        setDeleteIntent(null);
    };

    const virtualMeetingServiceProvider = useMemo(
        () =>
            user.team?.virtualMeetingServiceProviders?.find(
                serviceProvider => serviceProvider.id === event?.virtualMeeting?.serviceProvider?.id
            ),
        [user.team, event?.virtualMeeting]
    );

    const handleCreate = async (type: Bizly.ParcelType) => {
        let attendees: Bizly.EventAttendee[] = [],
            recipients: number[] = [];
        if (!playbookId) {
            const response = await loadAttendees(event.id);
            attendees = response?.attendees || [];
            recipients = attendees.map(({ id }: { id: number }) => id);
        }
        const parcel: ParcelFormState = initialParcelState(type, recipients, event, virtualMeetingServiceProvider);
        parcel.subject = !playbookId && type === 'registration_page' ? event.name : '';

        try {
            const newParcelRes = playbookId
                ? await createPlaybookParcel(playbookId, parcel)
                : await createParcel(event.id, parcel);
            enqueueSnackbar(`${i18n.communication.draftCreated}.`, { variant: 'success' });
            const newParcelId = newParcelRes.parcel.id;
            if (playbookId) {
                createPlaybookActions.triggerParcelsLoad();
            }
            const redirectUrl = playbookId
                ? `/playbooks/${playbookId}/edit/communication/edit/${type}/${newParcelId}`
                : `/event/${event.id}/communication/edit/${type}/${newParcelId}`;
            navigate(redirectUrl);
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
            const redirectUrl = playbookId ? `/playbooks/${playbookId}/edit` : `/event/${event.id}/communication`;
            navigate(redirectUrl);
        }
    };

    const proTips = user?.team?.proTips.filter(proTip => proTip.section === 'communications') || [];

    if (toggles.gate.createMeetingsFlow) {
        return <Navigate to={`/events/${event.id}`} replace />;
    }

    if (!parcels) {
        return <Spinner />;
    }

    return (
        <Main>
            <DeleteConfirmationModal
                onDismiss={handleDeleteDismiss}
                onProceed={handleDeleteConfirm}
                prompt={i18n.communication.deletePrompt(
                    playbookId ? i18n.communication.playbook : i18n.communication.meeting
                )}
                isActive={!!deleteIntent}
            />

            {!playbookId && !readonly ? (
                <SpacedRow>
                    <Column>
                        <Row alignItems="center">
                            <PageHeadline withDescription>{i18n.communication.communication}</PageHeadline>
                            <HowToPopper sectionId="communications" />
                        </Row>
                        <PageDescription large>{i18n.communication.description}</PageDescription>
                    </Column>

                    <Button fullWidth={false} onClick={showModal}>
                        {i18n.communication.create}
                    </Button>
                </SpacedRow>
            ) : (
                showCreateButton && (
                    <Row justifyContent="flex-end">
                        <Button fullWidth={false} onClick={showModal}>
                            {i18n.communication.create}
                        </Button>
                    </Row>
                )
            )}

            <Spacer large />
            <Content>
                <ParcelContainer itemSpacing="largest">
                    {Object.keys(CATEGORIES).map(category => (
                        <Column key={category}>
                            <ThinHeadline>{CATEGORIES[category]}</ThinHeadline>
                            <Spacer />
                            <Grid container spacing={2}>
                                {padArray(categorizedParcels[category] ?? [], readonly ? 3 : 4).map(
                                    (parcel: Bizly.Parcel | undefined) => (
                                        <Grid item xs={readonly ? 4 : 3} key={parcel?.id || Math.random()}>
                                            {!parcel ? (
                                                <MatchboxPlaceholder style={{ pointerEvents: 'none' }} />
                                            ) : (
                                                <ParcelMatchbox
                                                    editable={playbookId ? true : event.editable}
                                                    onCopy={handleCopyClick}
                                                    onDelete={handleDeleteClick}
                                                    parcel={parcel}
                                                    readonly={readonly}
                                                    editParcelLink={editLinkDestination(parcel)}
                                                    eventId={event.id}
                                                />
                                            )}
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </Column>
                    ))}
                </ParcelContainer>
                {proTips && !readonly && (
                    <Tips>
                        {proTips.map(proTip => (
                            <ProTip key={proTip.id} message={proTip.content} />
                        ))}
                    </Tips>
                )}
                <ParcelCreateModal open={modalShown} onClose={hideModal} onCreate={handleCreate} />
            </Content>
        </Main>
    );
}

const generateParcelTrait = (type: Bizly.ParcelType) => {
    if (type === INVITE) {
        return { rsvp: [] };
    }

    if (type === REGISTRATION_PAGE) {
        return ['rsvp', 'public'];
    }

    if (type === HOTEL_CONF) {
        return ['hotel_conf'];
    }

    return [];
};

const initialParcelState = (
    type: Bizly.ParcelType,
    recipients: number[],
    event: TEventParcelContext,
    vmServiceProvider?: Bizly.VirtualMeetingServiceProvider
) => {
    return {
        name: '',
        recipients,
        subject: '',
        content: '',
        locationName: event.bookedVenue?.name,
        locationAddress: event.bookedVenue?.address,
        locationCityState: event.bookedVenue?.cityState,
        traits: generateParcelTrait(type),
        ...(vmServiceProvider
            ? {
                  vmSharingMethod: 'share' as 'share' | 'dont_share',
              }
            : {}),
    };
};
