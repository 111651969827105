import { Moment } from 'moment';
import Box from '@mui/material/Box';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { DateRangePickerDay } from '@mui/x-date-pickers-pro/DateRangePickerDay';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { i18n } from 'translation';
import { Description, StepContainer, Title } from './styled';

type PropType = {
    value: DateRange<Moment>;
    onChange: (value: DateRange<Moment>) => void;
};

const Step4 = ({ value, onChange }: PropType) => {
    const onRangeChange = (value: DateRange<Moment>) => {
        onChange(value);
    };

    return (
        <StepContainer>
            <Title>{i18n.homepage.createMeetingModal.dateTitle}</Title>
            <Description>{i18n.homepage.createMeetingModal.youCanEditThisLater}</Description>
            <Box display="flex" flexDirection="column" alignItems="center">
                <DateRangeCalendar
                    calendars={1}
                    value={value}
                    onChange={onRangeChange}
                    disablePast
                    slots={{ day: DateRangePickerDay }}
                />
            </Box>
        </StepContainer>
    );
};

export default Step4;
