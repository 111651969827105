import { Headline } from 'components/ui/Headline';
import TallyDisplay from 'components/ui/TallyDisplay';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { AlignedRow, Column, Link } from 'ui';

const Card = styled(Column)`
    flex: 1;
`;

const SectionTitle = styled(Headline)`
    margin-top: 16px;
    margin-bottom: 16px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.totalAttendee)};
`;

const StyledLink = styled(Link)`
    font-size: 15px;
`;

type TGuestlistSectionProps = {
    editable: boolean;
    attendeeCount: Bizly.AttendeeCounts;
};

export default function GuestlistSection({ editable, attendeeCount }: TGuestlistSectionProps) {
    const { eventId } = useParams<{ eventId: string }>();
    const { invited, attending, notAttending, total } = attendeeCount;

    const hasAttendees = total > 0;

    return (
        <Card>
            <AlignedRow justifyContent="space-between">
                <SectionTitle thin>{i18n.meetingDashboard.guestListSection.guestList}</SectionTitle>
                <StyledLink href={`/event/${eventId}/guest-list`}>
                    {editable ? (hasAttendees ? i18n.button.edit : i18n.button.manage) : i18n.button.view}
                </StyledLink>
            </AlignedRow>

            {/* TODO: translate tally labels which are key of status color map */}
            <TallyDisplay
                tallies={[
                    { label: 'total', tally: total },
                    { label: 'invited', tally: invited },
                    { label: 'attending', tally: attending },
                    { label: 'not attending', tally: notAttending },
                ]}
            />
        </Card>
    );
}
