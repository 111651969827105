import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { getEventPlannerData } from 'api';
import { EventHeaderIconsFill } from 'components/EventHeader';
import Tokenizer from 'components/Tokenizer';
import { PageHeadline } from 'components/Ui-V2/Headline/Headline';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import { REJECTED_STATUSES } from 'components/VenueCard';
import VenueMap from 'components/VenueMap';
import { getTokens } from 'components/VenueSearch/TokenUtils';
import VenueSearchField from 'components/VenueSearch/VenueSearchField';
import { TFilterValue } from 'components/VenueSearch/VenueSearchFilters';
import FiltersPanel from 'components/VenueSearch/VenueSearchFiltersRow';
import { InfiniteGrid, NoSearchGrid } from 'components/VenueSearch/VenueSearchInfiniteGrid';
import { ContentWidthButton } from 'components/ui/Button';
import { RequirementType, venueTypes } from 'constants/venueTypes';
import { useManageUrlParams, useVenueSearch } from 'hooks/venueSearchHooks';
import { useUser } from 'providers/user';
import { NEW_INQUIRY_ID, useCurrentInquiry } from 'stores/current-inquiry';
import { units, useMeasurementUnits } from 'stores/measurement-units';
import { LoadPlaybookOptions, usePlaybooks } from 'stores/playbooks';
import { i18n } from 'translation';
import { miToKm, toMiles } from 'utils';
import { Copy, Line, Switch } from '../ui';

const SearchHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 2em;
`;

const FittedVenueMap = styled(VenueMap)`
    max-width: 440px;
    min-width: 440px;
    max-height: calc(100vh - 50px);
    position: sticky;
    /* TODO: this shouldn't be hard-coded */
    top: 149px;
`;

const StyledFiltersPanel = styled(FiltersPanel)`
    flex: 1 0 0;
    min-width: 0;
`;

const StyledVenueSearchField = styled(VenueSearchField)`
    flex: 0 0 auto;
    justify-content: center;
`;

const FiltersContainer = styled(Box)<{ showLeftPartition: boolean; showRightPartition: boolean }>`
    display: flex;
    align-items: center;
    height: 3.13rem;
    ${({ showLeftPartition, theme }) =>
        showLeftPartition
            ? `
                border-left: 1px solid ${theme.getColor(theme.EColors.grey)};
                padding-left: 10px;
            `
            : `border-left: none;`}
    ${({ showRightPartition, theme }) =>
        showRightPartition
            ? `
                border-right: 1px solid ${theme.getColor(theme.EColors.grey)};
                padding-right: 10px;
            `
            : `border-right: none;`}
`;

const ViewMapContainer = styled(Box)`
    display: flex;
    align-items: center;
    height: 3.13rem;
`;

const TokenizerWrapper = styled(Box)`
    position: relative;
    width: 80%;
`;

const TokenizerContainer = styled(Box)`
    display: flex;
    overflow-x: auto;
    box-sizing: content-box;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

const ScrollButton = styled.button<{ show: boolean }>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    cursor: pointer;
    z-index: 10;
    padding: 8px;
    font-size: 24px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
        outline: none;
    }

    &.left {
        left: 0;
        display: ${({ show }) => (show ? 'flex' : 'none')};
    }

    &.right {
        right: 0;
        display: ${({ show }) => (show ? 'flex' : 'none')};
    }
`;

const eventParamsToParams = (venueSearchParameters: Bizly.Event['venueSearchParameters']) => {
    const { radius, brandIds, ...params } = venueSearchParameters ?? {};
    return {
        ...params,
        ...(brandIds ? { brandIds } : { brandIds: undefined }),
        ...(radius ? { radius: toMiles(radius) } : {}),
        dinovaOnly: params.dinovaOnly ?? false,
        preferredOnly: params.preferredOnly ?? false,
    };
};

type TVenueSearchPage<THideActions extends boolean = false> = {
    event?: Bizly.Event;
    viewVenueListing: (venueId: number) => void;
    isCreateMeeting?: boolean;
    hideActions: THideActions;
} & (true extends THideActions
    ? {
          hideActions: true;
      }
    : {
          hideActions: false;
          onSelect?: (venue: Bizly.Venue) => void;
          onDeselect?: (venue: Bizly.Venue) => void;
          selectedVenues: { [venueId: number]: Bizly.Venue };
      });

export default function VenueSearchPage<THideActions extends boolean = false>(props: TVenueSearchPage<THideActions>) {
    const [highlightedId, setHighlightedId] = useState<number>();
    const [pinHighlightedId, setPinHighlightedId] = useState<number>();
    const [hasInquiryData, setHasInquiryData] = React.useState(false);
    const [loadingInquiryData, setLoadingInquiryData] = React.useState(false);
    const [showScrollButtons, setShowScrollButtons] = useState({ left: false, right: false });
    const [showLeftPartition, setShowLeftPartition] = useState(false);

    const isInitParamsLoaded = useRef<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const { event, viewVenueListing, ...restProps } = props;

    const eventSearchParams = useMemo(
        () => eventParamsToParams(event?.venueSearchParameters ? event.venueSearchParameters : {}),
        [event]
    );
    const brandRestrictions = useMemo(
        () =>
            event?.venueSearchParameters &&
            event.venueSearchParameters.isLocked &&
            event.venueSearchParameters.brandIds &&
            event.venueSearchParameters.brandIds.length > 0
                ? new Set(event.venueSearchParameters.brandIds)
                : undefined,
        [event]
    );

    const location = useLocation();
    const navigate = useNavigate();

    const { filters, showMap, latLng, setFilters, setPlaceQuery, setMapVisibility } = useManageUrlParams(event);

    const noFilterParams = useMemo(() => {
        const validEntries = Object.entries(filters).filter(entry => !!entry[1]);
        return validEntries.length === 0 || isEqual(Object.fromEntries(validEntries), { radius: 15, radiusKm: 25 });
    }, [filters]);

    const noEventParams = useMemo(
        () => Object.values(eventSearchParams).filter(val => val).length === 0,
        [eventSearchParams]
    );

    const [initialFilters, setInitialFilters] = React.useState(
        noFilterParams ? (!noEventParams ? eventSearchParams : null) : null
    );
    const searchFilters = useMemo(
        () =>
            initialFilters ?? {
                ...filters,
                ...(brandRestrictions ? { brandIds: eventSearchParams.brandIds } : {}),
                // to be clear, these are the default search params:
                dinovaOnly: filters.dinovaOnly ?? false,
                radius: filters.radius ?? (15 as Distance.Mile),
                radiusKm: filters.radiusKm ?? miToKm(15 as Distance.Mile),
            },
        [filters, initialFilters, brandRestrictions, eventSearchParams.brandIds]
    );

    useEffect(() => {
        async function loadPlanner() {
            if (event) {
                setLoadingInquiryData(true);
                const { planner = {} } = await getEventPlannerData(event.id);
                if (
                    (planner.eventSpaces && planner.eventSpaces.length > 0) ||
                    (planner.accommodations && planner.accommodations.length > 0)
                ) {
                    setHasInquiryData(true);
                } else {
                    setHasInquiryData(false);
                }
                setLoadingInquiryData(false);
            }
        }
        loadPlanner();
    }, [event]);

    const { user } = useUser();
    const teamName = useMemo(() => (user.team ? user.team.name : ''), [user.team]);
    const { hasMore, loadMore, results } = useVenueSearch(searchFilters, event?.id, latLng);
    const { distance: distanceUnit } = useMeasurementUnits();
    const { playbookOptions: { venueBrands = [] } = {} } = usePlaybooks();
    const { venues, inquiry: currentInquiry, loading: loadingCurrent } = useCurrentInquiry();

    const goToInquiry = () => {
        navigate(`/event/${event?.id}/venue/inquiries/${currentInquiry?.id ?? NEW_INQUIRY_ID}`, {
            replace: true,
        });
    };

    const venuesById = useMemo(() => (venues ? keyBy(venues, venue => venue.id) : {}), [venues]);

    const resultsWithStatus = useMemo(() => {
        if (!results) return [];

        return results
            .filter(venue => {
                // Filter out office locations if team setting hide_office_location_tiles is true
                if (venue.type.id === 10) {
                    // office location
                    return !user.team?.hideOfficeLocationTiles;
                }

                return true;
            })
            .map(venue => {
                const inquiryVenue = venuesById[venue.id];
                if (inquiryVenue?.status && REJECTED_STATUSES.has(inquiryVenue.status)) {
                    return { ...venue, status: inquiryVenue.status };
                }
                return venue;
            });
    }, [results, venuesById, user.team?.hideOfficeLocationTiles]);

    const onVenueTypesUpdate = useCallback(
        (newTypes: BizlyAPI.Venue.Types[] | undefined) => {
            setFilters({
                ...filters,
                types: newTypes,
            });
        },
        [setFilters, filters]
    );

    const onFilterUpdate = useCallback(
        (newFilters: TFilterValue) => {
            if (!isEqual(newFilters, filters)) setInitialFilters(null);
            setFilters(newFilters);
        },
        [filters, setFilters, setInitialFilters]
    );

    const onToggleMap = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setMapVisibility(e.target.checked);
        },
        [setMapVisibility]
    );

    useEffect(() => {
        let initialFilters = {} as TFilterValue;
        if (event?.venueSearchParameters) {
            const { isLocked, ...venueSearchParams } = event.venueSearchParameters ?? {};
            if (isLocked) {
                initialFilters = {
                    ...venueSearchParams,
                    radius: venueSearchParams.radius ? toMiles(venueSearchParams.radius) : undefined,
                };
            }
        }
        // set filters based on the event venue type requirements
        if (!isInitParamsLoaded.current && noFilterParams) {
            let requirementType;
            // if (user.team?.sourceSite) {
            //     requirementType = 'Office / Coworking';
            // }
            if (event?.requirements && event.requirements.length > 0) {
                requirementType = event.requirements[0];
            }
            if (requirementType) {
                const types = venueTypes[requirementType as RequirementType] || [];
                initialFilters.types = types as BizlyAPI.Venue.Types[];
            }
        }
        if (Object.keys(initialFilters).length > 0) {
            isInitParamsLoaded.current = true;
            onFilterUpdate(initialFilters);
        }
    }, [event, onFilterUpdate, noFilterParams, location.search, user.team]);

    const tokens = useMemo(
        () =>
            getTokens(searchFilters, onFilterUpdate, {
                teamName,
                unit: distanceUnit === units.meter ? units.kilometer : units.mile,
                brands: venueBrands,
                disabledBrands: !!brandRestrictions,
            }),
        [searchFilters, onFilterUpdate, distanceUnit, venueBrands, teamName, brandRestrictions]
    );

    const onPinClick = useCallback(
        (venueId: number) => {
            setPinHighlightedId(venueId);
        },
        [setPinHighlightedId]
    );

    useEffect(() => {
        const container = containerRef.current;

        const updateScrollButtonsVisibility = () => {
            if (container) {
                const { scrollWidth, clientWidth, scrollLeft } = container;
                setShowScrollButtons({
                    left: scrollLeft > 0,
                    right: scrollWidth > clientWidth + scrollLeft,
                });
                setShowLeftPartition(scrollWidth > clientWidth + scrollLeft);
            }
        };

        const handleScroll = () => {
            if (container) {
                const { scrollWidth, clientWidth, scrollLeft } = container;
                setShowScrollButtons({
                    left: scrollLeft > 0,
                    right: scrollWidth > clientWidth + scrollLeft,
                });
            }
        };

        updateScrollButtonsVisibility();

        window.addEventListener('resize', updateScrollButtonsVisibility);
        container?.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', updateScrollButtonsVisibility);
            container?.removeEventListener('scroll', handleScroll);
        };
    }, [tokens]);

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    return (
        <>
            {!props.hideActions && ( // Hide headers on the main venues page
                <EventHeaderIconsFill>
                    {!loadingInquiryData && (
                        <ContentWidthButton disabled={loadingCurrent || !event?.editable} onClick={goToInquiry}>
                            {hasInquiryData ? i18n.venue.inquiry.reviewInquiry : i18n.venue.inquiry.buildInquiry}
                        </ContentWidthButton>
                    )}
                </EventHeaderIconsFill>
            )}
            <SearchHeader>
                <PageHeadline withDescription>{i18n.venues.title}</PageHeadline>
                {/* Hide for now but will bring back later */}
                {/* <VenueTypeFilter filters={searchFilters.types} onChange={onVenueTypesUpdate} /> */}
            </SearchHeader>
            <Box sx={{ width: '100%' }}>
                <LoadPlaybookOptions />
                <Box>
                    <Box display="flex" sx={{ mb: 2 }}>
                        <StyledVenueSearchField disabled={false} onSearch={setPlaceQuery} />
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <FiltersContainer gap={2} showLeftPartition={false} showRightPartition={tokens.length > 0}>
                            <StyledFiltersPanel
                                filters={searchFilters}
                                setFilters={onFilterUpdate}
                                filterCount={tokens.length}
                            />
                        </FiltersContainer>
                        <TokenizerWrapper>
                            <TokenizerContainer ref={containerRef}>
                                <Copy>
                                    <Tokenizer tokens={tokens} />
                                </Copy>
                                {showScrollButtons.left && (
                                    <ScrollButton className="left" onClick={scrollLeft} show={showScrollButtons.left}>
                                        <KeyboardArrowLeftIcon />
                                    </ScrollButton>
                                )}
                                {showScrollButtons.right && (
                                    <ScrollButton
                                        className="right"
                                        onClick={scrollRight}
                                        show={showScrollButtons.right}
                                    >
                                        <KeyboardArrowRightIcon />
                                    </ScrollButton>
                                )}
                            </TokenizerContainer>
                        </TokenizerWrapper>
                        <FiltersContainer gap={2} showLeftPartition={showLeftPartition} showRightPartition={false}>
                            <ViewMapContainer gap={2}>
                                <Copy style={{ minWidth: 'fit-content' }}>{i18n.venue.viewMap}</Copy>
                                <Switch checked={showMap} onChange={onToggleMap} value="showMap" />
                            </ViewMapContainer>
                        </FiltersContainer>
                    </Box>
                    <Box>
                        <Line />
                    </Box>
                    {event?.venueSearchParameters?.isLocked && (
                        <>
                            <Spacer xsmall />
                            <Copy small faded>
                                {i18n.venue.lockedFiltersCopy}
                            </Copy>
                        </>
                    )}

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} mt={4}>
                        <Box style={{ flex: '1 1 51.75rem' }}>
                            {results && results.length > 0 && latLng ? (
                                <InfiniteGrid
                                    data={resultsWithStatus}
                                    hasMore={hasMore}
                                    loadMore={loadMore}
                                    onVenueHover={id => setHighlightedId(id || undefined)}
                                    highlightedVenue={highlightedId}
                                    pinHighlightedVenue={pinHighlightedId}
                                    onVisit={viewVenueListing}
                                    {...restProps}
                                />
                            ) : (
                                <NoSearchGrid />
                            )}
                        </Box>
                        {showMap && (
                            <FittedVenueMap
                                center={latLng}
                                venues={results}
                                highlightedVenueId={highlightedId}
                                pinHighlightedVenueId={pinHighlightedId}
                                onPinClick={onPinClick}
                                showLegend
                            />
                        )}
                    </Stack>
                </Box>
            </Box>
        </>
    );
}
