import { SingleFieldForm } from 'components/Form';
import { H2Headline } from 'components/ui/Headline';
import React from 'react';
import styled from 'styled-components';

const Headline = styled(H2Headline)`
    margin-bottom: 32px;
`;

export default function BudgetForm({
    value,
    onChange,
    readonly,
    disabled,
    hideHeader,
}: {
    value: string;
    onChange: (value: string) => void;
    readonly?: boolean;
    disabled?: boolean;
    hideHeader?: boolean;
}) {
    return (
        <>
            {!hideHeader && <Headline>Budget</Headline>}
            <SingleFieldForm
                field={{
                    type: 'text',
                    prompt: 'Budget',
                }}
                value={value}
                onChange={onChange}
                readonly={readonly}
                disabled={disabled}
            />
        </>
    );
}
