import { alpha, styled } from '@mui/material';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { useState } from 'react';

/*
    TODOS:
        - Setup the Statuses
*/

type StatusMappingKeys = 'All' | 'New Leads';
// | 'Proposal Sent'
// | 'Confirmed'
// | 'Payment Due'
// | 'Won'
// | 'Lost'
// | 'Rejected';

const statusMappings: Record<StatusMappingKeys, StatusType> = {
    All: 'All',
    'New Leads': 'New Inquiry',
};

interface CustomToolbarProps {
    onFilterChange: (newFilter: StatusType) => void;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => {
    const { getColor, EColors } = theme;
    return {
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(0.5),
            border: 0,
            borderRadius: '.25rem',
            '&.Mui-selected': {
                backgroundColor: alpha(getColor(EColors.bizlyOSPrimary), 0.2),
                color: getColor(EColors.pureBlack),
            },
            '&:not(:first-of-type)': {
                borderRadius: '.25rem',
            },
            '&:first-of-type': {
                borderRadius: '.25rem',
            },
        },
    };
});

const StyledToggleButton = styled(ToggleButton)(({ theme }) => {
    const { getColor, EColors } = theme;
    return {
        textTransform: 'none',
        padding: '.25rem .75rem',
        fontSize: '.875rem',
        fontWeight: 'medium',
        '&:not(.Mui-selected)': {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        '&:hover': {
            backgroundColor: alpha(getColor(EColors.bizlyOSPrimary), 0.1),
        },
    };
});

const StyledGridToolbarButton = styled(Box)(({ theme: { getColor, EColors } }) => ({
    '& .MuiButtonBase-root': {
        color: getColor(EColors.pureBlack),
    },
    '& .MuiSvgIcon-root': {
        color: getColor(EColors.pureBlack),
    },
}));

export const CustomToolbar = ({ onFilterChange }: CustomToolbarProps) => {
    const [filter, setFilter] = useState<StatusMappingKeys>('All');

    const handleFilterChange = (_: React.MouseEvent<HTMLElement>, newFilter: StatusMappingKeys | null) => {
        if (newFilter !== null) {
            setFilter(newFilter);
            onFilterChange(statusMappings[newFilter]);
        }
    };

    return (
        <GridToolbarContainer>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
                <StyledToggleButtonGroup
                    value={filter}
                    exclusive
                    onChange={handleFilterChange}
                    aria-label="status filter"
                    size="small"
                >
                    {(Object.keys(statusMappings) as StatusMappingKeys[]).map(status => (
                        <StyledToggleButton key={status} value={status} aria-label={status}>
                            {status}
                        </StyledToggleButton>
                    ))}
                </StyledToggleButtonGroup>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <StyledGridToolbarButton>
                        <GridToolbarColumnsButton />
                    </StyledGridToolbarButton>
                    <StyledGridToolbarButton>
                        <GridToolbarFilterButton />
                    </StyledGridToolbarButton>
                    <StyledGridToolbarButton>
                        <GridToolbarDensitySelector />
                    </StyledGridToolbarButton>
                    <StyledGridToolbarButton>
                        <GridToolbarExport />
                    </StyledGridToolbarButton>
                    <GridToolbarQuickFilter />
                </Box>
            </Box>
        </GridToolbarContainer>
    );
};
