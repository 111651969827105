import { BLANK_TEMPLATE_ID } from 'api/eventTemplates';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';
import { userTimeZone } from 'utils/moment';
import { fillObject } from '../util';
import { get, patch, post } from './';

const serializeAgenda = (agenda: any) => ({
    // TODO: Revisit this serializer and remove reference to old schedule array/objects
    id: agenda.id,
    addToInquiry: agenda.addToInquiry,
    attendees: agenda.attendees || 1,
    amenities: {
        av: agenda.avNeeds?.map((avo: any) => avo.id) ?? [],
        foodAndBeverage:
            agenda.fbNeeds?.map((fbo: any) => ({
                optionId: fbo.id,
                styleId: fbo.diningStyle ? fbo.diningStyle.id : null,
            })) ?? [],
    },
    end: agenda.end,
    name: agenda.name,
    roomSetup: agenda.setup && agenda.setup.id,
    start: agenda.start,
    startTime: agenda.startTime,
    endTime: agenda.endTime,
});

export const serializeDay = (day: any) => ({
    // TODO: Revisit this serializer and remove reference to old schedule array/objects
    ...day,
    guestrooms: day.guestrooms || [],
    agenda: day.agenda ? day.agenda.map((a: any) => serializeAgenda(a)) : [],
});

const serializeEvent = (event: Bizly.Event) => ({
    // TODO: Revisit this serializer and remove reference to old schedule array/objects
    ...event,
    schedule: event.schedule.map((day: any) => serializeDay(day)),
});

export const getEvents = (query: string = '', editable?: boolean) => {
    const queryParams = new URLSearchParams({
        ...(query ? { name: query } : {}),
        ...(editable !== undefined ? { editable: editable.toString() } : {}),
    });

    const url = `events${queryParams.toString() ? `?${queryParams}` : ''}`;

    return get(url).then(({ success, events }) => ({
        success,
        events: events.map(serializeEvent),
    })) as Promise<{
        success: boolean;
        events: Bizly.Event[];
    }>;
};

export const groupEventsByProgress = (events: Bizly.Event[]) => {
    enum EProgress {
        Dateless = 'dateless',
        Past = 'past',
        Active = 'active',
    }

    const now = moment();
    const getProgress = (event: Bizly.Event) => {
        const sortDate = moment(event.endsAt);

        if (!sortDate.isValid()) return EProgress.Dateless;
        if (sortDate.isBefore(now)) return EProgress.Past;
        return EProgress.Active;
    };

    const base = fillObject(Object.values(EProgress), [] as Bizly.Event[]);

    return {
        ...base,
        ...groupBy(events, getProgress),
    };
};

export const sortEventsByEndsAt = (events: Bizly.Event[]) =>
    sortBy(events, event => (event.endsAt ? moment(event.endsAt).valueOf() : Infinity));

export const createEvent = (event: Partial<Bizly.Event>) =>
    post('events', { templateId: BLANK_TEMPLATE_ID, timeZone: userTimeZone, ...event }) as Promise<{
        event: Bizly.Event;
    }>;

export const setEventTimeZone = (eventId: number | string, timeZone: string) =>
    patch(`events/${eventId}/time-zone`, { timeZone }) as Promise<{ success: boolean; event: Bizly.Event }>;
