import { getPlace } from 'api';
import VenueSearchFetcher from 'api/VenueSearchFetcher';
import {
    queryParamsToFormFilters as parseFilters,
    parseMapVisibility,
    queryParamsToPlace as parsePlace,
    toQueryParams,
    TPlaceQuery,
} from 'components/VenueSearch/SearchUtils';
import { TFilterValue } from 'components/VenueSearch/VenueSearchFilters';
import isEqual from 'lodash/isEqual';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { i18n } from 'translation';

/*
    Hook to managing search params in the URL:
        a. Converting URL google place ids into lat/lng and writing them to the URL
        b. Updating filter params in the URL
*/

// we default to San Francisco Coordinates if non are provided by an event or query
const SF_COORDS = {
    lat: 37.76075,
    lng: -122.447441,
};

export const useManageUrlParams = (event?: Bizly.Event) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();

    const query = useMemo(() => parsePlace(location.search), [location.search]);
    const filters = useMemo(() => parseFilters(location.search), [location.search]);
    const showMap = useMemo(() => parseMapVisibility(location.search), [location.search]);

    const updateUrl = useCallback(
        (filters: TFilterValue, query: TPlaceQuery | undefined, showMap = true) => {
            const stringified = queryString.stringify(
                toQueryParams({
                    ...filters,
                    ...(query ? query : {}),
                    showMap,
                })
            );
            navigate(
                {
                    pathname: location.pathname,
                    search: stringified,
                },
                {
                    state: location.state,
                }
            );
        },
        [navigate, location]
    );

    const latLng = useMemo(() => {
        if (query && (!query.lat || !query.lng) && query.place_id) return undefined;
        if (query && query.lat && query.lng) return { lat: query.lat, lng: query.lng };
        if (event?.lat && event?.lng) return { lat: event.lat, lng: event.lng };
        return SF_COORDS;
    }, [event, query]);

    const setFilters = useCallback((newFilters: object) => updateUrl(newFilters, query), [query, updateUrl]);
    const setMapVisibility = useCallback(
        (visibility: boolean) => updateUrl(filters, query, visibility),
        [filters, query, updateUrl]
    );
    const setPlaceQuery = useCallback(
        async (locationQuery: string, placeId: string) => {
            if (placeId) {
                try {
                    const res = await getPlace(placeId);
                    const loc = res.result?.geometry.location;

                    updateUrl(filters, { q: locationQuery, place_id: placeId, ...loc }, showMap);
                } catch {
                    enqueueSnackbar(i18n.error.default, { variant: 'error' });
                }
            } else {
                updateUrl(filters, undefined, showMap);
            }
        },
        [filters, showMap, updateUrl, enqueueSnackbar]
    );

    return { setPlaceQuery, latLng, filters, setFilters, showMap, setMapVisibility };
};

type TLocation = { lat: number; lng: number };

// Hook to perform venue searches
export const useVenueSearch = (filters: TFilterValue, eventId?: number, latLng?: TLocation) => {
    const { enqueueSnackbar } = useSnackbar();

    const [{ results, hasMore }, setCollection] = useState({} as { results?: Bizly.Venue[]; hasMore?: boolean });
    const [searchPending, setSearchPending] = useState(false);
    const [performing, setPerforming] = useState(false);

    const [fetcher, setFetcher] = useState<VenueSearchFetcher>();
    const prevFilters = useRef<TFilterValue>();
    const prevEventId = useRef<number>();
    const prevLatLng = useRef<TLocation>();

    useEffect(() => {
        if (!latLng) return;

        // Skip creating a new fetcher when filters are not changed
        if (
            isEqual(prevFilters.current, filters) &&
            prevEventId.current === eventId &&
            isEqual(prevLatLng.current, latLng)
        )
            return;

        // Save previous values
        const filtersQuery = toQueryParams(filters);
        prevFilters.current = { ...filters };
        prevEventId.current = eventId;
        prevLatLng.current = latLng;

        const newFetcher = new VenueSearchFetcher(eventId, {
            facets: { ...filtersQuery, ...latLng },
            perPage: 10,
        });
        setFetcher(newFetcher);

        // reset results
        setCollection({});
        loadMore();
    }, [eventId, filters, latLng]);

    const performSearch = useCallback(async () => {
        if (!fetcher) return;

        setPerforming(true);
        try {
            const venues = await fetcher.getNextPage();

            setCollection(({ results: prevRes }) => ({
                results: (prevRes || []).concat(venues),
                hasMore: fetcher.hasMore(),
            }));
        } catch {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        } finally {
            setPerforming(false);
        }
    }, [fetcher, enqueueSnackbar]);

    const loadMore = () => setSearchPending(true);

    useEffect(() => {
        setCollection({});
        loadMore();
    }, []);

    // Perform search only when other search actions are completed
    useEffect(() => {
        if (searchPending && !performing) {
            setSearchPending(false);
            performSearch();
        }
    }, [performSearch, searchPending, performing]);

    return {
        hasMore,
        loadMore,
        results,
        loading: performing || searchPending,
    };
};
