import Dialog from '@material-ui/core/Dialog';
import EstimateForm, { TEstimateForm } from 'components/FinalSpendTracking/EstimateForm';
import TextButton from 'components/ui/Button/TextButton';
import useKey from 'hooks/useKey';
import { useSnackbar } from 'notistack';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import {
    getVenueProposalAccepted,
    sendCancellationEmail,
    setBookingContractUrl,
    setBookingEstimates,
    setBookingFinalSpend,
} from '../api';
import { uploadFile } from '../cloudinary';
import { useEvent } from '../providers/event';
import { useUser } from '../providers/user';
import { Column, InlineRow, Line, Row, SpacedRow, Spacer, Copy as UICopy } from '../ui';
import { Spinner, SpinnerOverlay } from './Spinner';
import CompleteAndSign from './VenueAccepted/CompleteAndSign';
import { LargeHeadline, PageHeadline } from './ui/Headline';
// TODO: Move this entire file into the VenueAccepted folder and rename the folder to be VenueProposalAccepted, and rename this file to index.tsx. Adjust the below imports to match accordingly
import { hasSpecialCancellationEmail } from 'utils';
import { timeElapsed } from 'utils/date_util';
import HowToPopper from './HowToPopper/HowToPopper';
import FinalizeDetails from './VenueAccepted/FinalizeDetails';
import PaymentDetails from './VenueAccepted/PaymentDetails';
import UploadSignedDocs from './VenueAccepted/UploadSignedDocs';
import VenueContact from './VenueAccepted/VenueContact';
import VenueDocs from './VenueAccepted/VenueDocs';
import Button from './ui/Button';
import ScrollTabs from './ui/ScrollTabs';

const ENABLE_VIRTUAL_PAYMENT = import.meta.env.VITE_APP_ENV !== 'prod';

const ProposalStepsToComponent = {
    download_documents: VenueDocs,
    complete_sign_documents: CompleteAndSign,
    upload_signed_documents: UploadSignedDocs,
    payment_details: PaymentDetails,
    finalize_details: FinalizeDetails,
} as const;

const TabNames = {
    download_documents: i18n.venue.proposal.tabs.contracting,
    complete_sign_documents: i18n.venue.proposal.tabs.signDocs,
    upload_signed_documents: i18n.venue.proposal.tabs.uploadDocs,
    payment_details: i18n.venue.proposal.tabs.paymentDetails,
    finalize_details: i18n.venue.proposal.tabs.finalDetails,
} as const;

const TopRow = styled(Row)`
    border-bottom: 2px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    padding-bottom: 2em;
    gap: 4em;
`;

const Container = styled.div`
    width: 640px;
    padding: 32px;
    flex: 1;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
`;

const RowRight = styled(Row)`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
`;

const MessageButton = styled(Button)`
    font-size: 13px;
`;

const ButtonRow = styled(InlineRow)`
    justify-content: flex-end;
`;

const Copy = styled(UICopy)`
    font-size: 0.94em;
    line-height: 1.2em;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

const LeftColumn = styled(Column)`
    width: 40%;
`;

type VenueProposalAcceptedProps = {
    onSendMessage: () => void;
    proposalId?: string;
    summaryRef?: RefObject<HTMLDivElement>;
    onLoading?: (loading: boolean) => void;
};

const VenueProposalAccepted = ({ onSendMessage, proposalId, summaryRef, onLoading }: VenueProposalAcceptedProps) => {
    const refs = {
        download_documents: useRef<HTMLDivElement | null>(null),
        complete_sign_documents: useRef<HTMLDivElement | null>(null),
        upload_signed_documents: useRef<HTMLDivElement | null>(null),
        payment_details: useRef<HTMLDivElement | null>(null),
        finalize_details: useRef<HTMLDivElement | null>(null),
    } as const;
    const { enqueueSnackbar } = useSnackbar();

    const { event, onEventChange } = useEvent();
    const { user, isTeamAdmin } = useUser();

    const proposalSteps = user.team?.proposalSteps ?? [];
    const proposalAcceptedCongratsMessage = user.team?.proposalAcceptedCongratsMessage;
    const paymentCardRules = user.team?.paymentCardRules;
    const teamCompliance = user.team?.compliance ?? {};
    const documentsOptional = user.team?.optionalEventDocumentUploads ?? false;

    const [venueProposal, setVenueProposal] = useState<Bizly.VenueProposal | undefined>();
    const [loading, setLoading] = useState(true);
    const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
    const [estimatesForm, setEstimatesForm] = useState<TEstimateForm>();

    useKey('Escape', () => setUploadModalOpen(false));

    useEffect(() => {
        onLoading && onLoading(loading);
    }, [onLoading, loading]);

    useEffect(() => {
        getVenueProposalAccepted(Number(proposalId))
            .then(proposal => {
                proposal && setVenueProposal(proposal);

                if (proposal?.booking) {
                    setEstimatesForm({
                        ...proposal.booking.estimates,
                        existingDocs: {
                            contract1: proposal.booking.contractUrl,
                            contract2: proposal.booking.contract2Url,
                        },
                    });
                }
            })
            .finally(() => setLoading(false));
    }, [event.id, proposalId]);

    const getExistingDocs = () => ({
        contract1: venueProposal?.booking?.contractUrl,
        contract2: venueProposal?.booking?.contract2Url,
    });

    const updatePaymentCard = (paymentCard: Bizly.PaymentCard) =>
        setVenueProposal(prevVenueProposal =>
            prevVenueProposal && prevVenueProposal.booking
                ? {
                      ...prevVenueProposal,
                      booking: {
                          ...prevVenueProposal.booking,
                          paymentCard,
                      },
                  }
                : prevVenueProposal
        );

    const handleSubmit = async (stagedDocs: { contract1?: File; contract2?: File }) => {
        if (!venueProposal || !venueProposal.booking || !estimatesForm) return;
        const {
            booking: { id: bookingId, contractUrl: existingContract1, contract2Url: existingContract2 },
        } = venueProposal;
        const { contract1, contract2 } = stagedDocs;
        if (stagedDocs?.contract1?.name || stagedDocs?.contract2?.name || existingContract1 || existingContract2) {
            if (!estimatesForm.total) {
                return enqueueSnackbar(i18n.venue.proposal.estimateTotalRequired, {
                    variant: 'error',
                });
            }
        }

        let stagedUpload1, stagedUpload2;
        try {
            setLoading(true);
            // TODO: Setting a contract and setting estimates are two separate endpoints; look into making a unified one for less calls

            if (contract1 && contract1.name) {
                const { url: firstUrl } = await uploadFile(contract1);
                stagedUpload1 = firstUrl;
            }

            if (contract2 && contract2.name) {
                const { url: secondUrl } = await uploadFile(contract2);
                stagedUpload2 = secondUrl;
            }

            if (stagedUpload1 || stagedUpload2) {
                const firstContract = stagedUpload1 || existingContract1;
                const secondContract = stagedUpload2 || null;
                const contractUrls = {
                    contractUrl: firstContract || secondContract,
                    contract2Url: firstContract ? secondContract : null,
                };

                const { booking: bookedContract } = await setBookingContractUrl(bookingId, contractUrls);
                setVenueProposal({ ...(venueProposal as Bizly.VenueProposal), booking: bookedContract });
            }

            const { booking: updatedBooking } = await setBookingEstimates(bookingId, estimatesForm);

            setVenueProposal(prevVenueProposal =>
                prevVenueProposal
                    ? {
                          ...prevVenueProposal,
                          booking: {
                              ...prevVenueProposal.booking,
                              ...updatedBooking,
                          },
                      }
                    : prevVenueProposal
            );

            setUploadModalOpen(false);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            enqueueSnackbar(i18n.error.submitData, {
                variant: 'error',
            });
        }
    };

    const handleCompleteEvent = async () => {
        if (!venueProposal?.booking) return;

        try {
            const { booking: updatedBooking } = await setBookingFinalSpend(venueProposal.booking.id);
            setVenueProposal(prevVenueProposal =>
                prevVenueProposal
                    ? {
                          ...prevVenueProposal,
                          booking: {
                              ...prevVenueProposal.booking,
                              ...updatedBooking,
                          },
                      }
                    : prevVenueProposal
            );

            enqueueSnackbar(i18n.meetingDashboard.meetingCompleted, {
                variant: 'info',
            });
            // check for a budget value so that the debounce on updateEvent doesn't cause it to be overwritten
            onEventChange({ ...event, finalSpendTracked: true });
        } catch (err) {
            enqueueSnackbar(i18n.error.submission, {
                variant: 'error',
            });
        }
    };

    const [emailSent, setEmailSent] = React.useState(false);

    const sendEmail = () => {
        if (!venueProposal?.booking) return;
        setLoading(true);
        sendCancellationEmail(venueProposal.booking.id)
            .then(() => {
                setEmailSent(true);
            })
            .finally(() => setLoading(false));
    };

    return loading ? (
        <SpinnerOverlay />
    ) : venueProposal ? (
        <>
            <SpacedRow>
                <Row alignItems="center">
                    <PageHeadline withDescription>{i18n.meetingsPage.sideNav.contracting}</PageHeadline>
                    <HowToPopper sectionId="contracting" />
                </Row>
                <ButtonRow itemSpacing="smallish">
                    {onSendMessage && (
                        <MessageButton onClick={onSendMessage} width={120} secondary>
                            {i18n.button.messageVenue}
                        </MessageButton>
                    )}
                    {hasSpecialCancellationEmail(user.team?.specialCancellationEmail) &&
                        (loading ? (
                            <Spinner unsetMargin />
                        ) : (
                            venueProposal.booking &&
                            !!event.cancelledAt && (
                                <Button small width="auto" onClick={sendEmail} disabled={emailSent}>
                                    {emailSent
                                        ? `${i18n.button.sent}!`
                                        : user.team?.specialCancellationEmail.invoicingInstructions}
                                </Button>
                            )
                        ))}
                </ButtonRow>
            </SpacedRow>
            <TopRow>
                <LeftColumn>
                    {!event.cancelledAt && (
                        <Copy large>{proposalAcceptedCongratsMessage ?? i18n.message.congratsDescription}</Copy>
                    )}
                </LeftColumn>
                <VenueContact
                    contact={venueProposal.contact}
                    venue={venueProposal.venue}
                    onSendMessage={event.editable ? onSendMessage : undefined}
                    booking={venueProposal.booking}
                    bookingCancelled={!!event.cancelledAt}
                />
            </TopRow>
            <ScrollTabs
                tabs={[
                    ...proposalSteps.map(({ type }) => ({
                        label: TabNames[type],
                        targetRef: refs[type],
                    })),
                    ...(summaryRef ? [{ label: 'Proposal details', targetRef: summaryRef }] : []),
                ]}
            />

            <LargeHeadline thin>{i18n.venue.proposal.nextStepHeadline}:</LargeHeadline>
            <Column>
                {proposalSteps.map(proposalStep => {
                    const Section = ProposalStepsToComponent[proposalStep.type];
                    return Section ? (
                        <Section
                            paymentCardRules={paymentCardRules}
                            key={proposalStep.number}
                            number={proposalStep.number}
                            venue={venueProposal}
                            updatePaymentCard={updatePaymentCard}
                            openUploadModal={() => setUploadModalOpen(true)}
                            onCompleteEvent={handleCompleteEvent}
                            existingDocs={getExistingDocs()}
                            copy={proposalStep.copy}
                            altCopy={proposalStep.altCopy}
                            redText={proposalStep.redText}
                            readonly={!event.editable}
                            enableVirtualPayment={ENABLE_VIRTUAL_PAYMENT && !!paymentCardRules?.enabled && isTeamAdmin}
                            documentOptional={documentsOptional}
                            eventEnded={timeElapsed(event.endsAt, event.timeZone)}
                            ref={refs[proposalStep.type]}
                        />
                    ) : null;
                })}
            </Column>
            <Spacer />
            <Line />
            <Dialog open={uploadModalOpen} maxWidth="md" onClose={() => setUploadModalOpen(false)}>
                <Container>
                    <EstimateForm
                        canUpdate={true}
                        onFormUpdate={setEstimatesForm}
                        formValues={{
                            ...estimatesForm,
                            currency: event.currency?.code,
                            existingDocs: getExistingDocs(),
                        }}
                        teamCompliance={teamCompliance}
                    />
                    <RowRight itemSpacing="smallish">
                        <TextButton onClick={() => setUploadModalOpen(false)} secondary>
                            {i18n.button.cancel}
                        </TextButton>

                        <TextButton
                            onClick={() => handleSubmit(estimatesForm?.stagedDocs || {})}
                            disabled={
                                !estimatesForm?.existingDocs?.contract1 &&
                                !(
                                    estimatesForm?.stagedDocs?.contract1?.name ||
                                    estimatesForm?.stagedDocs?.contract2?.name
                                )
                            }
                        >
                            {i18n.button.upload}
                        </TextButton>
                    </RowRight>
                </Container>
            </Dialog>
        </>
    ) : (
        <Navigate to={`/event/${event.id}/venue/inquiries`} />
    );
};

export default VenueProposalAccepted;
