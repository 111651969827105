import { Headline } from 'components/ui/Headline';
import VendorsSummary from 'pages/Vendors/components/VendorsSummary';
import { useEvent } from 'providers/event';
import { useParams } from 'react-router-dom';
import LoadAddons from 'stores/addons/LoadAddons';
import styled from 'styled-components';
import { i18n } from 'translation';
import { AlignedRow, Column, Link } from 'ui';

const Card = styled(Column)`
    flex: 1;
`;
const SectionTitle = styled(Headline)`
    margin-top: 16px;
    margin-bottom: 16px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.totalAttendee)};
`;

const StyledLink = styled(Link)`
    font-size: 15px;
`;

const VendorsContainer = styled('div')`
    flex: 1;
    display: flex;
`;

export default function VendorsSection() {
    const { event } = useEvent();
    const { eventId } = useParams<{ eventId: string }>();

    return (
        <Card>
            <LoadAddons eventId={event.id} />
            <AlignedRow justifyContent="space-between">
                <SectionTitle thin>{i18n.meetingsPage.sideNav.virtual}</SectionTitle>
                <StyledLink href={`/event/${eventId}/virtual`}>{i18n.button.viewAll}</StyledLink>
            </AlignedRow>
            <VendorsContainer>
                <VendorsSummary />
            </VendorsContainer>
        </Card>
    );
}
