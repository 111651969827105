import { getPlaybookRSVPPreview, getRSVPPreview, loadEventRegistrationInfo, loadPlaybookRegistrationInfo } from 'api';
import EventRegistrationCard from 'components/RSVP/EventRegistrationCard';
import RSVPCard from 'components/RSVP/RSVPCard';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'ui';
import { INVITE, NOTE, REGISTRATION_PAGE, SURVEY } from './EditParcel/utils';

export const PREVIEW_CARD_WIDTH = 600;

export type ParcelPreviewCardProps = {
    image?: string;
    name?: string;
    description?: string;
    startsAt?: string;
    endsAt?: string;

    schedule?: { start: string; end: string }[];
    plannerSchedule?: (BizlyAPI.ScheduleBlock & Partial<{ timezone?: string }>)[];
    plannedBy: Partial<{ firstName?: string | null; lastName?: string | null }>;
    parcelDates?: {
        startDate: string;
        startTime: string;
        endDate: string;
        endTime: string;
    } | null;
    useParcelDates?: boolean;

    location?: string;
    address?: string;
    cityState?: string;
    vmSharingMethod?: 'share' | 'dont_share' | null;
    virtualMeeting?: Partial<Bizly.VirtualMeeting>;

    isNote?: boolean;
    hasQuestions?: boolean;
    timezone?: string;

    team?: { url: string; title: string };
    type?: Bizly.ParcelType;
    parcelName?: string;

    parcel?: Bizly.Parcel;
};

type PreviewQuestions = {
    attendee: Bizly.EventAttendee;
    formSettings: Bizly.FormSetting;
};

export default function ParcelPreviewCard({
    image,
    name,
    description,
    startsAt,
    endsAt,
    schedule = undefined,
    plannerSchedule,
    plannedBy,
    parcelDates = null,
    useParcelDates,
    location,
    address,
    cityState,
    vmSharingMethod,
    virtualMeeting,

    isNote,
    hasQuestions = undefined,
    timezone = undefined,
    team,
    type,
    parcelName,
    parcel,
}: ParcelPreviewCardProps) {
    const [previewQuestions, setPreviewQuestions] = useState<PreviewQuestions>({} as PreviewQuestions);
    const { user } = useUser();
    const { event } = useEvent();
    const { playbookId, id } = useParams<{ playbookId?: string; id?: string }>();
    const templateId = playbookId || id;
    const slug = !Array.isArray(parcel?.traits) && parcel?.traits?.public?.registrationPage.slug;

    const teams = {
        color: user.team?.color,
        imageUrl: team?.url || user.team?.imageUrl,
    };

    useEffect(() => {
        const loadPreviewQuestions = async () => {
            let attendee, formSettings;

            if (type === NOTE || type === SURVEY || type === INVITE) {
                if (templateId) {
                    if (parcel?.id) {
                        ({ attendee, formSettings } = await getPlaybookRSVPPreview(templateId, parcel.id));
                    }
                } else {
                    const parcelIdQuery = '?' + qs.stringify({ parcel_id: parcel?.id });
                    ({ attendee, formSettings } = await getRSVPPreview(event.id, parcelIdQuery));
                }
            }

            if (type === REGISTRATION_PAGE) {
                if (templateId) {
                    ({ attendee, formSettings } = await loadPlaybookRegistrationInfo(templateId, slug));
                } else {
                    ({ attendee, formSettings } = await loadEventRegistrationInfo(event.id, slug));
                }
            }
            if (formSettings?.customQuestions && Object.values(formSettings?.customQuestions).length) {
                attendee.customQuestions = Object.values(formSettings.customQuestions);
            }

            attendee = {
                ...attendee,
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                roomBlockEnabled: parcel?.roomBlockEnabled,
                rsvpDueAt: parcel?.rsvpDueAt,
                postCutoffContact: user.email,
            };

            setPreviewQuestions({ attendee, formSettings });
        };

        loadPreviewQuestions();
    }, [event.id, templateId, slug, parcel, type, user]);

    const PreviewCard = ({ type }: { type?: Bizly.ParcelType }) => {
        switch (type) {
            case REGISTRATION_PAGE: {
                return (
                    <EventRegistrationCard
                        team={teams}
                        image={image}
                        name={parcelName}
                        description={description}
                        parcelDates={parcelDates}
                        location={location}
                        address={address}
                        cityState={cityState}
                        attendee={previewQuestions.attendee}
                        formSettings={previewQuestions.formSettings}
                        timezone={timezone}
                        isPreview
                    />
                );
            }

            case INVITE || NOTE || SURVEY:
                return (
                    <RSVPCard
                        team={teams}
                        image={image}
                        name={name}
                        description={description}
                        startsAt={startsAt}
                        endsAt={endsAt}
                        schedule={schedule}
                        plannerSchedule={plannerSchedule}
                        plannedBy={plannedBy}
                        parcelDates={parcelDates}
                        useParcelDates={useParcelDates}
                        timezone={timezone}
                        location={location}
                        address={address}
                        cityState={cityState}
                        vmSharingMethod={vmSharingMethod}
                        virtualMeeting={virtualMeeting}
                        isNote={isNote}
                        hasQuestions={hasQuestions}
                        type={type}
                        attendee={previewQuestions.attendee}
                        formSettings={previewQuestions.formSettings}
                        isPreview
                    />
                );

            default: {
                return (
                    <RSVPCard
                        team={teams}
                        image={image}
                        name={name}
                        description={description}
                        startsAt={startsAt}
                        endsAt={endsAt}
                        schedule={schedule}
                        plannerSchedule={plannerSchedule}
                        plannedBy={plannedBy}
                        parcelDates={parcelDates}
                        useParcelDates={useParcelDates}
                        timezone={timezone}
                        location={location}
                        address={address}
                        cityState={cityState}
                        vmSharingMethod={vmSharingMethod}
                        virtualMeeting={virtualMeeting}
                        isNote={isNote}
                        hasQuestions={hasQuestions}
                        type={type}
                        attendee={previewQuestions.attendee}
                        formSettings={previewQuestions.formSettings}
                        isPreview
                    />
                );
            }
        }
    };

    return (
        <Column style={{ width: PREVIEW_CARD_WIDTH }}>
            <PreviewCard type={type} />
        </Column>
    );
}
