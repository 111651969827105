import colorFns from 'colorFns';
import Button from 'components/ui/Button';
import { FixedRatio } from 'components/VenueTile/VenueTile';
import fontFns from 'fontFns';
import useThemedColor from 'hooks/useThemedColor';
import { useUser } from 'providers/user';
import React from 'react';
import { AreaUnit, units } from 'stores/measurement-units';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy, LowercaseCopy, Row, SmallerCopy, SpacedRow, Spacer } from 'ui';
import { tzMoment } from 'utils/moment';
import { sqftToSqm } from 'utils/units';
import { formatCurrency, formatDate, formatPercentage } from '../../util';
import { ProposalActionButton } from './ProposalActionButton';
import { ProposalExtensionButton } from './ProposalExtensionButton';
import { useProposalRequest } from './useProposalRequest';

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
`;

const FormHeading = styled.h5`
    font-size: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;

const VenueLabel = styled('label')<{ small?: boolean; aboveBudget?: boolean }>`
    font-size: ${props => (props.small ? '15px' : '18px')};
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel};
`;

const VenueLabelHelper = styled.span`
    margin-top: 0.25rem;
    font-size: 0.7rem;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel, 0.9)};
`;

const VenueBudgetLabel = styled('label')<{ small?: boolean; aboveBudget?: boolean }>`
    font-size: ${props => (props.small ? '15px' : '18px')};
    letter-spacing: -0.1px;
    color: ${({ aboveBudget, theme: { getColor, EColors } }) =>
        aboveBudget ? getColor(EColors.red) : getColor(EColors.green)};
    ${fontFns.formLabel}
    text-align: right;
`;

const NoSpaceImage = styled.div`
    width: 100%;
    padding-top: calc(140 / 242 * 100%);
    border-radius: 8px;

    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackgroundNested)};

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
        content: 'No Image Available';
        font-size: 15px;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
    }
`;

const EventSpaceHeader = styled.div`
    display: flex;
`;

const ProposalButtons = styled.div`
    position: relative;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    bottom: 0;

    padding: 0.5rem;
    box-sizing: border-box;

    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
`;

const TopButtonRow = styled(Row)`
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    gap: 0.5rem;
`;

const BottomButtonRow = styled(Row)`
    justify-content: center;
    margin-top: 0.5rem;
`;

const DayContainer = styled.section`
    background-color: ${colorFns.softAccentedBackground};

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    padding-bottom: 24px;
`;

const DayHeader = styled.div`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: ${colorFns.agendaDayPane};
    color: ${colorFns.agendaDayPaneText};

    display: flex;
    justify-content: space-between;

    padding: 12px;
`;

const DayCol = styled(Column)`
    justify-content: center;
    margin: 0 13px;
`;

type RoundedImageProps = {
    url?: string;
    width: string | number;
    height: string | number;
    fixedRatio: string;
};

const RoundedImage = styled(FixedRatio)<Partial<RoundedImageProps>>`
    background-image: url(${props => props.url});
    background-size: cover;
`;

const Price = styled.div`
    font-size: 40px;
    font-weight: 300;
    line-height: 0.95;
    letter-spacing: -1.22px;
    color: ${colorFns.displayPricing};
`;

const Smaller = styled.span`
    font-size: 26px;
`;

const DaySubtotals = styled(Column)<{ expired?: boolean }>`
    position: relative;
    bottom: ${({ expired }) => (expired ? 11 : 0)}rem;

    margin: 0.75rem 0;
    padding: 0.8125rem;

    background: ${colorFns.softAccentedBackground};
    border: 1px solid ${colorFns.softBorder};
    border-radius: 8px;
`;

const ProposalTotals = styled(Column)<{ expired?: boolean }>`
    position: relative;
    bottom: ${({ expired }) => (expired ? 6 : 0)}rem;

    margin: 12px 0;
    padding: 13px;

    background: ${colorFns.softAccentedBackground};
    border: 1px solid ${colorFns.softBorder};
    border-radius: 8px;
`;

const SubtotalHeading = styled.label`
    font-size: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    text-align: center;
`;

const SubtotalAmount = styled(Copy)<{ aboveBudget?: boolean }>`
    text-align: right;
    color: ${({ aboveBudget, theme: { getColor, EColors } }) =>
        aboveBudget ? getColor(EColors.red) : getColor(EColors.darkestGrey)};
`;

const SubtotalAmountWithBudget = styled(Copy)<{ aboveBudget?: boolean }>`
    text-align: right;
    color: ${({ aboveBudget, theme: { getColor, EColors } }) =>
        aboveBudget ? getColor(EColors.red) : getColor(EColors.green)};
`;

const BigPrice = ({ label, price }: { label: React.ReactNode; price: React.ReactNode }) => (
    <Column fillWidth>
        <FormHeading as="label">{label}</FormHeading>
        <Price>{price}</Price>
    </Column>
);

const DataRow = ({ label, data }: { label: React.ReactNode; data?: React.ReactNode }) => (
    <SpacedRow>
        <VenueLabel small>{label}</VenueLabel>
        {data && <TruncatedText>{data}</TruncatedText>}
    </SpacedRow>
);

const guestRoomsSubTotal = (guestroom: Bizly.VenueDayGR) =>
    guestroom?.quantity ? guestroom.rate * guestroom.quantity : null;
const eventSpacesSubTotal = (eses: Bizly.EventSpace[], useDdr?: boolean) =>
    eses.length > 0
        ? eses.reduce((agg, cur) => {
              return agg + (useDdr ? (cur.ratePerPerson ?? 0) * (cur.minGuests ?? 0) : cur.fbMinimum + cur.rate);
          }, 0)
        : null;

const TruncatedText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 6.25rem;
`;

export function ProposalDay({
    proposal,
    day,
    idx,
    sizeUnit,
    expired,
}: {
    proposal: Bizly.VenueProposal;
    day: Bizly.VenueDay;
    idx: number;
    sizeUnit: AreaUnit;
    expired?: boolean;
}) {
    const useDdr = !!proposal.useDdr;
    const currencyCode = proposal.currency?.code ?? 'USD';

    const guestRooms = day.guestRooms[0] && (
        <Column itemSpacing="default">
            <Column itemSpacing="default">
                <LowercaseCopy>{i18n.common.guestNumberFormatter(day.guestRooms[0].quantity)}</LowercaseCopy>

                {day.guestRooms[0].imageUrl ? (
                    <RoundedImage url={day.guestRooms[0].imageUrl} fixedRatio="calc(140 / 242 * 100%)" />
                ) : (
                    <NoSpaceImage />
                )}

                <BigPrice
                    label={i18n.venue.inquiry.guestRoomRental}
                    price={
                        day.guestRooms[0].quantity === 0
                            ? '-'
                            : formatCurrency(day.guestRooms[0].subTotal, currencyCode)
                    }
                />

                <Column>
                    <SmallerCopy>
                        {proposal.commissionable ? i18n.venue.inquiry.guestRoomsCommissionable : <>&nbsp;</>}
                    </SmallerCopy>
                    <SmallerCopy>{i18n.venue.inquiry.amountDescription}</SmallerCopy>
                </Column>
            </Column>
            <DataRow label={i18n.venue.inquiry.roomName} data={day.guestRooms[0].name} />
            <Column itemSpacing="xsmall">
                <DataRow
                    label={i18n.venue.inquiry.roomRate}
                    data={formatCurrency(day.guestRooms[0].rate, currencyCode)}
                />

                <Line />
                <DataRow label={i18n.venue.inquiry.occupancyTax} data={Number(proposal.occupancyTax) + '%'} />

                <Line />
                <DataRow label={i18n.venue.inquiry.resortFee} data={formatCurrency(proposal.resortFee, currencyCode)} />

                <Line />
            </Column>
        </Column>
    );

    const eventSpaces = day.eventSpaces.map(
        ({
            fbMinimum,
            rate,
            minGuests,
            ratePerPerson,
            requestedSpaceName,
            imageUrl: eventSpaceImageUrl,
            venueSpace,
            setup,
            guests,
            startsAt,
            endsAt,
        }) => (
            <Column itemSpacing="default" key={requestedSpaceName}>
                <Column itemSpacing="default">
                    <EventSpaceHeader>
                        <img src={setup.iconUrl} alt="meetingspace-icon" width={50} />
                        <Spacer xsmall />
                        <Copy>
                            {requestedSpaceName || setup.name}
                            <Spacer xsmall />
                            {formatDate(startsAt)} - {formatDate(endsAt)}
                            <Spacer xsmall />
                            {i18n.venue.inquiry.guestsNumber(guests)}
                        </Copy>
                    </EventSpaceHeader>
                    {eventSpaceImageUrl ? (
                        <RoundedImage url={eventSpaceImageUrl} fixedRatio="calc(140 / 242 * 100%)" />
                    ) : (
                        <NoSpaceImage />
                    )}
                    {useDdr ? (
                        <>
                            <BigPrice
                                label={i18n.venue.inquiry.minGuests}
                                price={
                                    <>
                                        {minGuests ?? 0}
                                        <Smaller>{` ${i18n.venue.inquiry.guests}`}</Smaller>
                                    </>
                                }
                            />
                            <BigPrice
                                label={i18n.venue.inquiry.perPerson}
                                price={formatCurrency(ratePerPerson ?? 0, currencyCode)}
                            />
                        </>
                    ) : (
                        <>
                            <BigPrice
                                label={i18n.venue.inquiry.fbMin}
                                price={formatCurrency(fbMinimum, currencyCode)}
                            />
                            <BigPrice
                                label={i18n.venue.inquiry.roomRental}
                                price={formatCurrency(rate, currencyCode)}
                            />
                        </>
                    )}
                    <SmallerCopy>{i18n.venue.inquiry.amountDescription}</SmallerCopy>
                </Column>

                <Column itemSpacing="small">
                    <DataRow label={i18n.venue.inquiry.roomName} data={venueSpace.name} />
                    <DataRow
                        label={i18n.venue.inquiry.roomSizeWithUnit(sizeUnit)}
                        data={sizeUnit === units.sqm ? sqftToSqm(venueSpace.size) : venueSpace.size}
                    />
                    <DataRow
                        label={i18n.venue.inquiry.roomMaxCapacity}
                        data={venueSpace.maxCapacity?.toLocaleString()}
                    />
                </Column>

                <Column itemSpacing="xsmall">
                    <DataRow label={i18n.venue.inquiry.serviceCharge} data={formatPercentage(proposal.serviceCharge)} />
                    <Line />
                    <DataRow label={i18n.venue.inquiry.salesTax} data={formatPercentage(proposal.salesTax)} />
                    <Line />
                    <DataRow label={i18n.venue.inquiry.gratuity} data={formatPercentage(proposal.gratuity)} />
                </Column>
            </Column>
        )
    );

    const guestRoomsSubTotalVal = guestRoomsSubTotal(day.guestRooms[0]);
    const guestRoomsSubTotalDisplay =
        guestRoomsSubTotalVal === null ? '-' : formatCurrency(guestRoomsSubTotalVal, currencyCode);
    const eventSpacesSubTotalVal = eventSpacesSubTotal(day.eventSpaces, useDdr);
    const eventSpacesSubTotalDisplay =
        eventSpacesSubTotalVal === null ? '-' : '+ ' + formatCurrency(eventSpacesSubTotalVal, currencyCode);
    const totalDisplay =
        '= ' + formatCurrency((guestRoomsSubTotalVal ?? 0) + (eventSpacesSubTotalVal ?? 0), currencyCode);

    const daySubtotals = (
        <DaySubtotals itemSpacing="xsmall" expired={expired}>
            <SubtotalHeading>{i18n.venue.inquiry.minDayNumber(idx + 1)}</SubtotalHeading>
            <Row justifyContent="space-between">
                <VenueLabel small>{i18n.proposalForm.sections.guestRooms}</VenueLabel>
                <SubtotalAmount>{guestRoomsSubTotalDisplay}</SubtotalAmount>
            </Row>
            <Row justifyContent="space-between">
                <VenueLabel small>{i18n.venue.inquiry.eventSpaces}</VenueLabel>
                <SubtotalAmount>{eventSpacesSubTotalDisplay}</SubtotalAmount>
            </Row>
            <Line />
            <SubtotalAmount>{totalDisplay}</SubtotalAmount>
        </DaySubtotals>
    );

    return (
        <>
            <Spacer />
            <DayHeader>
                <div>{i18n.common.dayIndex(idx + 1)}</div>
                <div>{tzMoment(day.day).format('ddd, ll')}</div>
            </DayHeader>
            <Column itemSpacing="small">
                <DayContainer>
                    <DayCol itemSpacing="small">
                        {guestRooms}
                        {eventSpaces}
                    </DayCol>
                </DayContainer>
                {daySubtotals}
            </Column>
        </>
    );
}

export function ProposalSummary({
    proposal,
    onAccept,
    onReject,
    readonly,
    sizeUnit,
    expired,
    eventBudget,
}: {
    proposal: Bizly.VenueProposal;
    onAccept: () => void;
    onReject: () => void;
    readonly?: boolean;
    sizeUnit: AreaUnit;
    expired?: boolean;
    eventBudget?: string;
}) {
    const proposalRequest = proposal.approvalRequests[0];
    const { user } = useUser();

    const isApproved = Boolean(proposalRequest?.approvedBy);
    const { hasRequested, ProposalRequestModal, modalShown, showModal } = useProposalRequest(proposal);

    const isEventBudget =
        user.team?.authMeetingCreateFields?.includes('budget') || user?.team?.authMeetingCreateRedirect;
    const currencyCode = proposal.currency?.code;
    const totalProposal = proposal.estimatedTotalCost;
    const totalDisplay = formatCurrency(totalProposal, currencyCode);
    const totalConvertedProposal = totalProposal * Number(proposal.forexRate);

    const { pureBlack } = useThemedColor();

    const hasBudget = isEventBudget || Boolean(user?.team?.maximumBudget);
    const maxBudget = isEventBudget ? Number(eventBudget) : Number(user?.team?.maximumBudget);

    const budgetBufferPercentage = 1.1;
    const budgetWithBuffer = maxBudget ? maxBudget * budgetBufferPercentage : 0;

    const isAboveBudget = totalConvertedProposal > budgetWithBuffer;
    const isAboveMaximumBudgetDisplay = isAboveBudget
        ? i18n.venue.inquiry.aboveMaximumBudget
        : i18n.venue.inquiry.withinMaximumBudget;

    const budgetBasedApprovalFeatureFlag = user.featureFlags?.budgetBasedApprovals;

    return (
        <>
            {proposal.byDay.map((day, idx) => (
                <ProposalDay
                    key={day.day + idx}
                    proposal={proposal}
                    day={day}
                    idx={idx}
                    sizeUnit={sizeUnit}
                    expired={expired}
                />
            ))}

            <ProposalTotals itemSpacing="xsmall" expired={expired}>
                <Row justifyContent="space-between" itemSpacing="xsmall">
                    <Column>
                        <VenueLabel small>{i18n.venue.inquiry.proposalTotal}</VenueLabel>
                        <VenueLabelHelper>{i18n.venue.inquiry.proposalTotalHelper}</VenueLabelHelper>
                    </Column>

                    <Column>
                        {isApproved ? (
                            <SubtotalAmountWithBudget>
                                <span style={{ color: pureBlack }}>= </span>
                                {totalDisplay}
                            </SubtotalAmountWithBudget>
                        ) : hasBudget && budgetBasedApprovalFeatureFlag ? (
                            <SubtotalAmountWithBudget aboveBudget={isAboveBudget}>
                                <span style={{ color: pureBlack }}>= </span>
                                {totalDisplay}
                            </SubtotalAmountWithBudget>
                        ) : (
                            <SubtotalAmount>{totalDisplay}</SubtotalAmount>
                        )}

                        {isApproved ? (
                            <VenueBudgetLabel small>{i18n.venue.inquiry.newBudgetApproved}</VenueBudgetLabel>
                        ) : (
                            hasBudget &&
                            budgetBasedApprovalFeatureFlag && (
                                <VenueBudgetLabel small aboveBudget={isAboveBudget}>
                                    {isAboveMaximumBudgetDisplay}
                                </VenueBudgetLabel>
                            )
                        )}
                    </Column>
                </Row>
            </ProposalTotals>

            {!readonly && (
                <ProposalButtons>
                    <TopButtonRow>
                        <Button onClick={onReject} warning>
                            {i18n.button.reject}
                        </Button>

                        <ProposalActionButton
                            proposal={proposal}
                            expired={expired}
                            onAccept={onAccept}
                            eventBudget={eventBudget}
                            showModal={showModal}
                            hasRequested={hasRequested}
                        />
                    </TopButtonRow>
                    {expired && (
                        <BottomButtonRow>
                            <ProposalExtensionButton proposal={proposal} onAccept={onAccept} />
                        </BottomButtonRow>
                    )}
                </ProposalButtons>
            )}

            {modalShown && ProposalRequestModal}
        </>
    );
}

export function hasProposal(venue: Bizly.Venue) {
    return (
        [
            'Booked',
            'Contract Pending',
            'Proposal Received',
            'Proposal Expired',
            'Extension Pending',
            'Extension Rejected',
            'Proposal Rejected',
            'Proposal Cancelled',
            'Booking Cancelled',
        ].includes(venue.status) || venue.booking
    );
}

export function proposalActive(venue: Bizly.Venue) {
    return ['Proposal Received', 'Proposal Expired', 'Extension Pending', 'Extension Rejected'].includes(venue.status);
}

export function proposalExpired(venue: Bizly.Venue) {
    return ['Proposal Expired', 'Extension Pending', 'Extension Rejected'].includes(venue.status);
}
