import Dialog from '@mui/material/Dialog';
import { styled as muiStyled } from '@mui/material/styles';
import { Button } from 'components/Ui-V2/Button/Button';
import styled from 'styled-components';

export const StyledDialog = muiStyled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: theme.spacing(3),
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '75%',
        height: '80vh',
        overflow: 'hidden',
    },
    '& .MuiDialogContent-root': {
        padding: `${theme.spacing(3.75)} ${theme.spacing(5)}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
}));

export const PrimaryButton = styled(Button)(({ theme: { getColor, EColors } }) => ({
    '&.MuiButton-root': {
        textTransform: 'none',
    },
    '&.MuiButton-containedPrimary:not(.Mui-disabled)': {
        backgroundColor: getColor(EColors.primaryAction),
    },
}));

export const TextButton = styled(Button)(({ theme: { getColor, EColors } }) => ({
    '&.MuiButton-textPrimary': {
        color: getColor(EColors.black),
    },
    '&.MuiButton-root': {
        textTransform: 'none',
    },
}));

export const ModalImage = styled.img`
    height: 100%;
    pointer-events: none;
    user-select: none;
`;
