import DashboardVenueTile from 'components/VenueTile/DashboardVenueTile';
import { VenueTileEmpty } from 'components/VenueTile/VenueTile';
import { uniqueId } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { padArray } from '../../../util';
import { DEFAULT, NO_CITY, TBookedStatus } from './utils';

const VenueTilePlaceholder = styled(VenueTileEmpty).attrs(
    ({ backgroundColor: backgroundColorProp, theme: { getColor, EColors } }) => ({
        'data-testid': 'venue-thumb',
        backgroundColor: backgroundColorProp || getColor(EColors.softAccentedBackgroundNested),
        withMap: true,
    })
)``;

export const VenueTiles = ({
    venues,
    bookedState,
    viewVenueListing,
    placeholderBackground,
}: {
    venues: Bizly.Venue[];
    bookedState: TBookedStatus;
    viewVenueListing?: (venueId: number) => void;
    placeholderBackground?: Themed.Color;
}) => (
    <>
        {padArray(venues, 4)
            .slice(0, 4)
            .map((venue: Bizly.Venue | undefined, idx) =>
                venue ? (
                    <DashboardVenueTile
                        key={venue.id + idx}
                        venue={venue}
                        format="tile"
                        pillType={
                            bookedState === DEFAULT
                                ? 'preferenceCategory'
                                : bookedState !== NO_CITY
                                ? 'inquiryStatus'
                                : undefined
                        }
                        fixedRatio="63%"
                        onClick={viewVenueListing}
                    />
                ) : (
                    <VenueTilePlaceholder key={uniqueId()} backgroundColor={placeholderBackground} fixedRatio="100%" />
                )
            )}
    </>
);
